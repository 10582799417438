import { h } from 'preact'
import linkView from '../view/link'

export default function wanikaniApiKey () {
  return h('div', {},
    h('h1', {}, 'Linking your Wanikani account'),
    h('p', {}, `
      When you create your KameSame account (or at any time from the settings
      screen), you can sync your WaniKani learning progress with KameSame. Once
      synced, you'll unlock an additional lesson mode called "Study WaniKani
      items". This mode will allow you to learn kanji and vocabulary in KameSame
      based on the rank (e.g. Master) you've reached for each item in
      WaniKani. You'll also be able to prioritize which words you study based on
      things like how often you get those items right or wrong in WaniKani.
    `),
    h('h2', {}, 'Obtaining a Personal Access Token'),
    h('p', {}, `
      In order to link your account, we'll need a read-only WaniKani Personal
      Access Token. You can create one in the `,
    linkView('https://www.wanikani.com/settings/personal_access_tokens',
      '"API Tokens" tab of WaniKani\'s account settings'),
      `. Just copy the token and paste it into the sign-up form or WaniKani
      account section of the settings tab.`
    )
  )
}

import { h } from 'preact'
import linkView from './link'

export default function footerView () {
  return h('div', { id: 'footer' },
    linkView('mailto:howdy@kamesame.com', 'Contact'),
    ' • ',
    linkView('/privacy_policy', 'Privacy policy', { native: '' }),
    ' • ',
    `© ${new Date().getFullYear()} `,
    linkView('https://justin.searls.co', 'Justin Searls')
  )
}

import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as study from '../lib/study'

export default function studyHeaderView (cards, mode, props) {
  cards = _.reject(cards, 'skipped')
  const percentCorrect = study.percentCorrect(cards)
  const completeCount = _.filter(cards, 'complete').length
  const incompleteCount = _.reject(cards, 'complete').length

  return [
    h('div', { class: 'progress' },
      h('div', {
        class: 'complete',
        style: `width: ${100 * (completeCount / _.size(cards))}%`
      })
    ),
    h('div', { class: 'header' },
      h(Helmet, {
        title: `👍${percentCorrect} ✅${completeCount} 📬${incompleteCount} | ${_.capitalize(mode)}`
      }),
      h('a', {
        href: `/app/${mode}/summary`,
        class: 'exit',
        native: '',
        onClick (e) { e.preventDefault(); study.finish(mode, props) }
      }, '🏡'),
      h('div', { class: 'stat percent' }, percentCorrect),
      h('div', { class: 'stat complete' }, completeCount),
      h('div', { class: 'stat incomplete' }, incompleteCount)
    )
  ]
}

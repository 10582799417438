import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as study from '../lib/study'
import bailWithoutView from './bail-without'
import lessonsByBucketView from './lessons-by-bucket'

export default function surveySummaryView ({ lessons }) {
  if (bailWithoutView(lessons)) return
  const cards = _.sortBy(lessons, 'item.text')

  const { correct, incorrect, incomplete } = _.groupBy(cards, card => {
    if (study.cardWasAnswered(card)) {
      if (study.cardWasCorrect(card)) {
        return 'correct'
      } else {
        return 'incorrect'
      }
    } else {
      return 'incomplete'
    }
  })

  const multipleLessonTypes = _.uniqBy(cards, (card) => study.questionType(card)).length > 1
  return h('div', { id: 'summary' },
    h('h2', {}, 'Survey Summary'),
    h(Helmet, { title: 'Survey Summary' }),
    correct && [
      h('h3', {}, 'Answered correctly'),
      lessonsByBucketView(correct, multipleLessonTypes)
    ],
    incorrect && [
      h('h3', {}, 'Answered incorrectly'),
      lessonsByBucketView(incorrect, multipleLessonTypes)
    ],
    incomplete && [
      h('h3', {}, 'Not answered definitively'),
      lessonsByBucketView(incomplete, multipleLessonTypes)
    ]
  )
}

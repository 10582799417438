import { h } from 'preact'
import itemList from './item-list'

export default function noteworthyItems (items) {
  if (!items) return

  return h('div', { class: 'columnize-at-scale' },
    items.recentlyLearned.length > 0
      ? h('div', {},
          h('h3', {}, 'Started studying'),
          itemList(items.recentlyLearned, { sortDescription: 'ago' })
        )
      : null,
    items.criticalCondition.length > 0
      ? h('div', {},
          h('h3', {}, 'Frequently failed'),
          itemList(items.criticalCondition, { sortDescription: 'incorrect' })
        )
      : null,
    items.readingReliant.length > 0
      ? h('div', {},
          h('h3', {}, 'Reading reliant'),
          itemList(items.readingReliant, { sortDescription: 'reading correct' })
        )
      : null,
    items.potentialLeeches.length > 0
      ? h('div', {},
          h('h3', {}, 'Likely leeches'),
          itemList(items.potentialLeeches, { sortDescription: 'alternate matches' })
        )
      : null
  )
}

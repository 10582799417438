import * as xp from '../lib/xp'
import dynamicOpacityForView from './dynamic-opacity-for'

export default function navHomeLevelEffectView (node, progress) {
  dynamicOpacityForView(
    node.querySelectorAll('#level-sections path'),
    progress.level,
    xp.MAX_LEVEL,
    progress.level >= xp.MAX_LEVEL
  )
}

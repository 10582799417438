import { h } from 'preact'
import * as lesson from '../lib/lesson'
import * as study from '../lib/study'
import formView from './form'
import optionView from './option'

export default function lessonsFromWaniKaniAssignmentsView ({ user, lessonsStatus }) {
  if (!user.linkedWanikaniAccount || !lessonsStatus) return
  const wkLessonCount = lessonsStatus.wanikaniLessonsAvailable

  return formView({
    onSubmit: lesson.startWanikani,
    onChange: lesson.updateWanikaniSettings
  },
  h('div', { class: 'section', tabindex: 0 },
    h('h2', { id: 'study-wanikani' }, 'Study WaniKani items'),
    h('div', { class: `lesson-count-message ${wkLessonCount > 0 ? 'info' : 'error'}` },
      wkLessonCount > 0
        ? [
            'You have ',
            h('span', { class: 'count' }, wkLessonCount),
            ' lessons available at rank ',
            study.rankForSrsStage(user.minSrsStageForLessons),
            ' or higher'
          ]
        : [
            'You don\'t have any unlearned WaniKani items of rank ',
            study.rankForSrsStage(user.minSrsStageForLessons),
            ' or higher'
          ]
    ),
    h('div', {},
      h('label', { for: 'min_srs_stage_for_lessons' }, 'Minimum WaniKani rank'),
      h('select', {
        id: 'min_srs_stage_for_lessons',
        name: 'min_srs_stage_for_lessons',
        required: true
      },
      optionView('Initiate', 0, lesson.bucketDefault(user, 0)),
      optionView('Apprentice', 1, lesson.bucketDefault(user, 1)),
      optionView('Guru', 5, lesson.bucketDefault(user, 5)),
      optionView('Master', 7, lesson.bucketDefault(user, 7)),
      optionView('Enlightened', 8, lesson.bucketDefault(user, 8)),
      optionView('Burned', 9, lesson.bucketDefault(user, 9))
      )
    ),
    h('div', {},
      h('label', { for: 'lesson_strategy' }, 'Prioritize lessons by'),
      h('select', {
        id: 'lesson_strategy',
        name: 'lesson_strategy',
        required: true
      },
      optionView('Random', 'random', user.lessonStrategy === 'random'),
      optionView('Lowest level first', 'level asc',
        user.lessonStrategy === 'level asc'),
      optionView('Highest level first', 'level desc',
        user.lessonStrategy === 'level desc'),
      optionView('Lowest % correct', 'percentage_correct asc',
        user.lessonStrategy === 'percentage_correct asc'),
      optionView('Highest % correct', 'percentage_correct desc',
        user.lessonStrategy === 'percentage_correct desc')
      )
    ),
    h('div', { class: 'checkbox' },
      h('input', {
        id: 'wanikani_lessons_include_recognition',
        name: 'wanikani_lessons_include_recognition',
        type: 'checkbox',
        value: 'true',
        disabled: user.skipRecognitionLessonsForWanikaniItems,
        checked: user.skipRecognitionLessonsForWanikaniItems ? false : user.wanikaniLessonsIncludeRecognition
      }),
      h('label', {
        for: 'wanikani_lessons_include_recognition',
        class: user.skipRecognitionLessonsForWanikaniItems ? 'strikethrough' : null
      }, 'Study recognition as well as production?')
    ),
    user.skipRecognitionLessonsForWanikaniItems
      ? h('div', { class: 'hint' },
          '(Uncheck "Skip recognition lessons for WaniKani items" in the ',
          h('a', { href: '/app/account' }, 'account tab '),
          'to re-enable this option.)'
        )
      : null,
    h('div', { class: 'buttons' },
      wkLessonCount > 0
        ? h('button', {}, 'Start lesson')
        : h('button', { disabled: true, title: 'No WaniKani lessons available' }, 'Start lesson')
    )
  ))
}

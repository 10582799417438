import { h } from 'preact'
import * as _ from '../vendor/lodash'
import images from '../images'
import formView from './form'
import infoLinkView from './info-link'
import linkView from './link'

export default (props) =>
  h('div', { id: 'signUp' },
    h('img', { src: images['login-header.svg'], alt: 'KameSame title' }),
    h('div', { class: 'wrap' },
      formView(_.extend({ action: '/app/sign_up' }, props),
        h('h2', {}, 'Sign up'),
        h('div', {},
          h('label', { for: 'name' }, 'Name'),
          h('input', { id: 'name', name: 'name', placeholder: 'Name', required: true, autofocus: true })
        ),
        h('div', {},
          h('label', { for: 'email' }, 'E-mail'),
          h('input', { id: 'email', name: 'email', type: 'email', placeholder: 'E-mail', required: true })
        ),
        h('div', {},
          h('label', { for: 'password' }, 'Password'),
          h('input', { id: 'password', name: 'password', type: 'password', minlength: 6, placeholder: 'Password', required: true })
        ),
        h('p', {},
          h('strong', {}, '[Optional]'),
          ' You can use your learning progress in ',
          linkView('https://wanikani.com', 'WaniKani'),
          ` to determine what you learn in KameSame. Instructions for obtaining a
            personal access token `,
          infoLinkView({ tutorial: 'wanikaniApiKey' }, 'are available here'),
          '.'
        ),
        h('div', {},
          h('label', { for: 'wanikani_v2_api_key' }, 'WaniKani Personal Access Token'),
          h('input', {
            id: 'wanikani_v2_api_key',
            name: 'wanikani_v2_api_key',
            placeholder: 'WaniKani Access Token'
          })
        ),
        h('div', { class: 'buttons' },
          h('button', { type: 'submit' }, 'Sign up')
        )
      ),
      h('p', { class: 'center' }, 'Already have an account? ',
        h('a', { href: '/app/login' }, 'Log in'), '!'
      )
    )
  )

import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as account from '../lib/account'
import bailWithoutView from './bail-without'
import japanFriendlyNumberInputView from './japan-friendly-number-input'
import pauseAndUnpauseReviewsView from './pause-and-unpause-reviews'
import spreadOutReviewsView from './spread-out-reviews'
import optionView from './option'

export default function accountView ({ user }) {
  if (bailWithoutView(user)) return
  return h('div', { id: 'account', class: 'faux-form' },
    h(Helmet, { title: 'Account' }),

    (user.patreonPledgeCents > 0
      ? h('div', { class: 'plead-with-people' },
          'Thank you for contributing to our ',
          h('a', { href: 'https://patreon.com/kamesame' }, 'Patreon'),
          '!', h('br'), 'Enjoy your ', h('a', { href: '#premium' }, 'patron-exclusive features'), '!')
      : h('div', { class: 'plead-with-people' },
        'Like KameSame? Join our ',
        h('a', { href: 'https://patreon.com/kamesame' }, 'Patreon'),
        ' to help us', h('br'), 'cover costs and access ',
        h('a', { href: '#premium' }, 'premium features'), '!')
    ),
    h('form', { onSubmit: account.saveChanges, tabindex: 0 },
      h('h2', {}, 'Your info'),
      h('div', {},
        h('label', { for: 'name' }, 'Name'),
        h('input', {
          id: 'name',
          name: 'name',
          value: user.name,
          type: 'text'
        })
      ),
      h('div', {},
        h('label', { for: 'email' }, 'E-mail'),
        h('input', {
          id: 'email',
          name: 'email',
          value: user.email,
          type: 'email'
        })
      ),
      h('h2', {}, 'General settings'),
      h('div', { class: 'checkbox' },
        h('input', {
          id: 'hide_superfluous_english',
          name: 'hide_superfluous_english',
          type: 'checkbox',
          checked: user.hideSuperfluousEnglish
        }),
        h('label', { for: 'hide_superfluous_english' }, 'Hide non-essential English translations')
      ),
      h('h2', {}, 'Study settings'),
      h('div', {},
        h('label', { for: 'batch_size_for_lessons' }, 'Max items per lesson'),
        japanFriendlyNumberInputView('batch_size_for_lessons',
          user.batchSizeForLessons)
      ),
      h('div', { class: 'sub-form' },
        h('label', { class: 'title' }, 'Active skills (uncheck to hide from lessons & reviews)'),
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'study_included_lesson_types_kanji_production',
            name: 'study_included_lesson_types_kanji_production',
            type: 'checkbox',
            value: 'kanji_production',
            checked: _.includes(user.studyIncludedLessonTypes, 'kanji_production')
          }),
          h('label', { for: 'study_included_lesson_types_kanji_production' }, 'Kanji production')
        ),
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'study_included_lesson_types_kanji_recognition',
            name: 'study_included_lesson_types_kanji_recognition',
            type: 'checkbox',
            value: 'kanji_recognition',
            checked: _.includes(user.studyIncludedLessonTypes, 'kanji_recognition')
          }),
          h('label', { for: 'study_included_lesson_types_kanji_recognition' }, 'Kanji recognition')
        ),
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'study_included_lesson_types_vocabulary_production',
            name: 'study_included_lesson_types_vocabulary_production',
            type: 'checkbox',
            value: 'vocabulary_production',
            checked: _.includes(user.studyIncludedLessonTypes, 'vocabulary_production')
          }),
          h('label', { for: 'study_included_lesson_types_vocabulary_production' }, 'Vocabulary production')
        ),
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'study_included_lesson_types_vocabulary_recognition',
            name: 'study_included_lesson_types_vocabulary_recognition',
            type: 'checkbox',
            value: 'vocabulary_recognition',
            checked: _.includes(user.studyIncludedLessonTypes, 'vocabulary_recognition')
          }),
          h('label', { for: 'study_included_lesson_types_vocabulary_recognition' }, 'Vocabulary recognition')
        )
      ),
      h('div', { class: 'checkbox' },
        h('input', {
          id: 'study_one_lesson_type_at_a_time',
          name: 'study_one_lesson_type_at_a_time',
          type: 'checkbox',
          checked: user.studyOneLessonTypeAtATime
        }),
        h('label', { for: 'study_one_lesson_type_at_a_time' },
          'Batch studies by type (e.g. finish kanji before starting vocab)')
      ),
      h('div', { class: 'checkbox' },
        h('input', {
          id: 'hide_reward_notifications',
          name: 'hide_reward_notifications',
          type: 'checkbox',
          checked: user.hideRewardNotifications
        }),
        h('label', { for: 'hide_reward_notifications' }, 'Hide XP notifications in reviews')
      ),
      h('div', { class: 'checkbox' },
        h('input', {
          id: 'skip_correct_answers',
          name: 'skip_correct_answers',
          type: 'checkbox',
          checked: user.skipCorrectAnswers
        }),
        h('label', { for: 'skip_correct_answers' }, 'Skip results page for correct answers')
      ),
      h('div', { class: 'checkbox' },
        h('input', {
          id: 'skip_recognition_lessons_for_wanikani_items',
          name: 'skip_recognition_lessons_for_wanikani_items',
          type: 'checkbox',
          checked: user.skipRecognitionLessonsForWanikaniItems
        }),
        h('label', { for: 'skip_recognition_lessons_for_wanikani_items' }, 'Skip recognition lessons for WaniKani items')
      ),
      h('div', { id: 'premium', class: 'premium-settings' },
        h('h2', {}, 'Premium settings'),
        h('p', {},
          'These options are only available to active patrons of the ',
          h('a', { href: 'https://www.patreon.com/kamesame' }, 'KameSame Patreon'),
          '. Patron status is synced daily. ',
          h('a', { href: 'mailto:howdy@kamesame.com' }, 'E-mail us'),
          ' if you have any issues.'
        ),
        h('div', {},
          h('label', { for: 'undo_credit_limit' }, 'Max number of undos'),
          h('select',
            {
              id: 'undo_credit_limit',
              name: 'undo_credit_limit',
              disabled: user.patreonPledgeCents <= 0
            },
            optionView('Select a number', 3, null),
            optionView('Unlimited undos!', -1, user.undoCreditLimit === -1),
            optionView('No undos!', 0, user.undoCreditLimit === 0),
            optionView('Up to 3 credits', 3, user.undoCreditLimit === 3),
            optionView('Up to 5 credits', 5, user.undoCreditLimit === 5),
            optionView('Up to 10 credits', 10, user.undoCreditLimit === 10),
            optionView('Up to 50 credits', 50, user.undoCreditLimit === 50)
          )
        ),
        h('div', {},
          h('div', { class: 'checkbox' },
            h('input', {
              id: 'autoplay_audio',
              name: 'autoplay_audio',
              type: 'checkbox',
              checked: user.autoplayAudio,
              disabled: user.patreonPledgeCents <= 0
            }),
            h('label', { for: 'autoplay_audio' }, 'Autoplay audio after each answer')
          )
        ),
        h('div', {},
          h('div', { class: 'checkbox', title: 'When checked, whenever reviews of multiple skills are ready simultaneously (e.g. recognition and production), KameSame will only present one review during the review session and delay the other reviews by 24 hours' },
            h('input', {
              id: 'defer_duplicate_reviews',
              name: 'defer_duplicate_reviews',
              type: 'checkbox',
              checked: user.deferDuplicateReviews,
              disabled: user.patreonPledgeCents <= 0
            }),
            h('label', { for: 'defer_duplicate_reviews' }, 'Spread out reviews of multiple skills for the same item')
          )
        )
      ),

      h('div', { class: 'buttons' },
        h('button', { onClick: account.saveChanges },
          h('span', { class: 'emoji-inline tilty-hover' }, '💾'),
          ' Save changes'
        )
      )
    ),

    h('form', { onSubmit: account.changePassword, tabindex: 0 },
      h('h2', {}, 'Change password'),
      h('div', {},
        h('label', { for: 'current_password' }, 'Current password'),
        h('input', {
          id: 'current_password',
          name: 'current_password',
          type: 'password'
        })
      ),
      h('div', {},
        h('label', { for: 'new_password' }, 'New password'),
        h('input', {
          id: 'new_password',
          name: 'new_password',
          type: 'password',
          minlength: 6
        })
      ),
      h('div', { class: 'buttons' },
        h('button', { onClick: account.changePassword },
          h('span', { class: 'emoji-inline tilty-hover' }, '🔑'),
          ' Change password'
        )
      )
    ),

    h('form', { onSubmit: account.updateWanikaniApiKey(user), tabindex: 0 },
      h('h2', {}, 'WaniKani account'),
      h('p', {}, `
        By linking your WaniKani account, you'll be able to do lessons based on
        your progress for items from WaniKani's curriculum.
      `),
      h('div', {},
        h('label', { for: 'wanikani_username' }, 'WaniKani username'),
        h('input', {
          id: 'wanikani_username',
          name: 'wanikani_username',
          value: user.wanikani.username || 'N/A',
          type: 'text',
          disabled: true
        })
      ),
      h('div', {},
        h('label', { for: 'wanikani_level' }, 'WaniKani level'),
        h('input', {
          id: 'wanikani_level',
          name: 'wanikani_level',
          value: user.wanikani.level || 'N/A',
          type: 'text',
          disabled: true
        })
      ),
      h('div', {},
        h('label', { for: 'wanikani_v2_api_key' }, 'WaniKani Personal Access Token'),
        h('input', {
          id: 'wanikani_v2_api_key',
          name: 'wanikani_v2_api_key',
          value: user.wanikani.apiKey,
          type: 'text'
        })
      ),
      h('div', { class: 'buttons' },
        h('button', { onClick: account.updateWanikaniApiKey(user) },
          h('span', { class: 'emoji-inline tilty-hover' }, '🐊🦀'),
          ' Update API key'
        )
      )
    ),

    pauseAndUnpauseReviewsView({ user, unpauseOnly: false }),

    spreadOutReviewsView({ user }),

    h('div', { class: 'fun-stuff', tabindex: 0 },
      h('h2', {}, 'Other fun stuff'),
      h('button', {
        class: 'l-margin-top',
        onClick () { window.location.reload() }
      },
      h('span', { class: 'emoji-inline tilty-hover' }, '♻️'),
      ' Reload KameSame'
      ),
      h('a', { href: '/app/logout', class: 'undecorated', native: '' },
        h('button', {},
          h('span', { class: 'emoji-inline tilty-hover' }, '👋'),
          ' Log out'
        )
      )
    ),

    h('div', { class: 'danger', tabindex: 0 },
      h('h2', {}, '☢️  Danger zone ☢️'),
      user.linkedWanikaniAccount && h('button', { class: 'l-margin-top', onClick: account.resyncWanikaniData },
        h('span', { class: 'emoji-inline tilty-hover' }, '📡'),
        ' Re-sync WaniKani'
      ),
      h('button', { class: 'l-margin-top', onClick: account.clearLocalData },
        h('span', { class: 'emoji-inline tilty-hover' }, '🗑'),
        ' Clear local data'
      ),
      h('button', { class: 'l-margin-top', onClick: account.resetExcludedItems },
        h('span', { class: 'emoji-inline tilty-hover' }, '🧹'),
        ' Reset blocked items'
      ),
      h('button', { class: 'l-margin-top', onClick: account.deleteAllKameSameProgress },
        h('span', { class: 'emoji-inline tilty-hover' }, '🤯'),
        ' Reset KameSame'
      )
    ),

    h('div', { class: 'plead-with-people' },
      'Like KameSame? Help with our ',
      h('a', { href: 'https://patreon.com/kamesame' }, 'server costs'),
      '!'
    ),

    h('div', { class: 'acknowledgements' },
      'KameSame also uses the ',
      h('a', { href: 'http://www.edrdg.org/wiki/index.php/JMdict-EDICT_Dictionary_Project' }, 'JMdict'),
      ' and ',
      h('a', { href: 'http://www.edrdg.org/wiki/index.php/KANJIDIC_Project' }, 'KANJIDIC'),
      ' dictionary files, copyright the ',
      h('a', { href: 'http://www.edrdg.org' }, 'Electronic Dictionary Research and Development Group'),
      ' and made available to for use under ',
      h('a', { href: 'http://www.edrdg.org/edrdg/licence.html' }, 'this license'),
      '.'
    )
  )
}

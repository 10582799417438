import * as _ from '../vendor/lodash'
import * as auth from './auth'
import * as net from './net'
import * as propUtil from './prop-util'
import render from './render'
import * as store from './store'
import * as study from './study'

export function start (e) {
  e.preventDefault()

  study.start(e.target, 'reviews', {
    lessonTypes: _.map(document.querySelectorAll('.lesson-type-selection :checked'), node => node.value)
  })
}

export function unlearn (item, progress) {
  if (!window.confirm(`Are you sure you want to reset all ${progress.lessonTypeDescription} progress on 「${item.text}」 and remove it from your review queue?`)) return

  const unfinishedCard = _.find(propUtil.get('reviews'), card =>
    card.learningId === progress.learningId && !card.complete
  )
  if (unfinishedCard) {
    propUtil.mergeAndSave({
      reviews: {
        [unfinishedCard.randomUrlPath]: {
          complete: true,
          skipped: true
        }
      }
    })
  }

  net.set(`/api/reviews/${progress.learningId}`, {}, 'DELETE').then(res => {
    res.item.loadedAt = new Date()
    render(propUtil.merge(res))
  })
}

export function load () {
  net.update('/api/reviews/status')
}

export const dueToBeReloaded = (reviewsStatus) =>
  !reviewsStatus || _.get(reviewsStatus, 'isStale')

export function pauseReviews (e) {
  e.preventDefault()
  e.target.disabled = true
  net.patch(`/api/users/${auth.getUserId()}/pause_reviews`).then((res) => {
    study.reset('reviews')
    const newProps = propUtil.merge(res)
    store.save(newProps)
    e.target.disabled = false
    render(newProps)
  })
}

export function unpauseReviews (e) {
  e.preventDefault()
  e.target.disabled = true
  net.patch(`/api/users/${auth.getUserId()}/unpause_reviews`).then((res) => {
    e.target.disabled = false
    render(propUtil.merge(res))
  })
}

export function spreadOutReviews (reviewsPerDay) {
  net.patch(`/api/users/${auth.getUserId()}/spread_out_reviews`, { reviewsPerDay }).then((res) => {
    study.reset('reviews')
    const newProps = propUtil.merge(res)
    store.save(newProps)
    render(newProps)
  })
}

export function reloadUnlessAlreadyDueToBeReloaded (reviewsStatus) {
  if (!dueToBeReloaded(reviewsStatus)) {
    _.defer(() => load())
  }
}

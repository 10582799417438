import * as _ from '../vendor/lodash'

export function as (thing) {
  return _.isArray(thing) ? thing : [thing]
}

export function order (array, order = 'asc') {
  return order === 'desc' ? _.reverse(array) : array
}

export function insertBetwixt (array, interstitial) {
  return _.flatMap(array, (thing, i) =>
    i === 0 ? [thing] : [interstitial, thing]
  )
}

export function pick (array, value, attr = 'name') {
  return _.find(array, thing =>
    thing[attr] === value
  )
}

export function where (array, values, attr = 'name') {
  return _.filter(array, thing => {
    return _.includes(values, thing[attr])
  })
}

export function whereNot (array, values, attr = 'name') {
  if (_.isEmpty(values)) return array

  return _.reject(array, thing => {
    return _.includes(values, thing[attr])
  })
}

import { h } from 'preact'
import icons from '../icons'

import navReviewsEffectView from './nav-reviews-effect'

export default ({ progress }) =>
  h('div', {
    class: 'svg',
    ref (c) { if (c) navReviewsEffectView(c, progress) }
  }, icons.reviews)

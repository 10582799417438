import { h } from 'preact'
import japanFriendlyNumberInputView from './japan-friendly-number-input'
import * as windowUtil from '../lib/window-util'
import * as review from '../lib/review'

export default function spreadOutReviewsView ({ user }) {
  return h('div', { class: 'spread-out-reviews', tabindex: 0 },
    h('h2', {}, 'Schedule out your reviews'),
    h('p', {}, `
      Overwhelmed by your review backlog? Unclog your reviews by scheduling out
      their due dates so you won't see more per day than
      you can handle. Any reviews that get pushed back beyond today
      will be marked as failed.
    `),
    h('div', {},
      h('label', { for: 'reviews_per_day' }, 'Reviews per day'),
      japanFriendlyNumberInputView('reviews_per_day', 50)
    ),
    h('p', {},
      h('strong', {}, 'WARNING:'),
      ` This is an irreversible action! Once you schedule out your current reviews,
        you won't be able to undo it!`
    ),
    h('button',
      {
        class: 'l-margin-top',
        onClick (e) {
          e.preventDefault()
          const reviewsPerDay = e.target.closest('.spread-out-reviews').querySelector('#reviews_per_day').value
          if (window.confirm(`Are you sure you want to schedule out your currently due reviews so you'll see about ${reviewsPerDay} of them per day? This action can't be undone!`)) {
            review.spreadOutReviews(reviewsPerDay)
            windowUtil.scrollToTop()
          }
        }
      },
      h('span', { class: 'emoji-inline tilty-hover' }, '💨'),
      ' Schedule out'
    )
  )
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'

export default function lessonsForExcludedItemsView ({ lessonsStatus }) {
  const exclusions = _.get(lessonsStatus, 'exclusions')
  if (!exclusions || exclusions.lessonsCount === 0) return

  return h('div', { class: 'section', tabindex: 0 },
    h('h2', {}, 'Items blocked from lessons'),
    h('p', {}, `
      You can block items from appearing in your lessons from the study and
      item pages.
      ${exclusions && exclusions.lessonsCount > 0 ? `You are currently blocking ${exclusions.lessonsCount} lessons across ${exclusions.itemsCount} items. ` : ''}
      You can review your blocked items here:
    `),
    h('div', { class: 'buttons' },
      h('a', { href: '/app/blocked_items', class: 'undecorated' },
        h('button', {},
          h('span', { class: 'emoji-inline tilty-hover' }, '🙅‍♀️'),
          ' Review blocked items'
        )
      )
    )
  )
}

import * as _ from '../vendor/lodash'
import * as net from './net'
import * as propUtil from './prop-util'
import render from './render'
import * as route from './route'
import * as study from './study'
import * as tokenize from './tokenize'
import * as util from './util'

export function load () {
  net.update('/api/lessons/status')
}

export const dueToBeReloaded = (lessonsStatus) =>
  !lessonsStatus || _.get(lessonsStatus, 'isStale')

export function reloadUnlessAlreadyDueToBeReloaded (lessonsStatus) {
  if (!dueToBeReloaded(lessonsStatus)) {
    _.defer(() => load())
  }
}

export function startWanikani (e) {
  e.preventDefault()
  study.start(e.target, 'lessons')
}

export function startLessonFromList (e) {
  e.preventDefault()
  const lessonListMostRecentCode = e.target.querySelector('[name=lesson_list_most_recent_code]').value
  const lessonListShuffle = e.target.querySelector('[name=lesson_list_shuffle]').checked
  const lessonListMode = e.target.querySelector('[name=lesson_list_mode]').value

  route.redirect('/app/loading')
  net.post('/api/lessons/from_list', {
    lessonListMostRecentCode,
    lessonListShuffle,
    lessonListMode
  }).then(res => {
    study.begin('lessons', lessonListMode, res)
  })
}

export function startLessonFromWordDump (e) {
  e.preventDefault()
  route.redirect('/app/loading')
  const content = e.target.querySelector('.word-dump').value
  tokenize.instance((err, tokenizer) => {
    if (err) {
      return route.fallback('/app/lessons',
        'Failed to parse content 🥺', 'error')
    }

    const tokens = tokenizer.tokenize(content)
    const filteredTokens = _.uniqWith(_.reject(tokens, token => {
      if (!token.basic_form ||
        token.basic_form.trim() === '' ||
        token.basic_form === '*'
      ) {
        return true
      } else if (_.includes(['記号', '助詞', '助動詞'], token.pos)) {
        return true
      } else if (token.basic_form.length === 1 && util.isHiragana(token.basic_form)) {
        return true
      }
    }), (t1, t2) => t1.basic_form === t2.basic_form && t1.pos === t2.pos)
    const words = _.map(filteredTokens, token => {
      return {
        text: token.basic_form,
        part_of_speech: token.pos,
        reading: token.reading,
        character_index: token.word_position - 1
      }
    })
    net.post('/api/items/tokenize', { words, content }).then((res) => {
      route.andRerender('/app/lessons/from-words', propUtil.replaceAndSave('wordsForLessons',
        util.splay(_.map(res.items, (item, indexInContent) =>
          _.extend({}, item, { isSelected: !item.isExcluded, indexInContent })
        ))
      ))
    })
  })
}

export function bucketDefault (user, srsStage) {
  const minStage = user.minSrsStageForLessons
  switch (srsStage) {
    case 0:
      return minStage === 0
    case 1:
      return minStage === 1
    case 5:
      return minStage > 1 && minStage <= 5
    case 7:
      return minStage > 5 && minStage <= 7
    case 8:
      return minStage === 8
    case 9:
      return minStage >= 9
  }
}

export function updateWanikaniSettings (e) {
  const form = e.currentTarget

  net.patch('/api/lessons/update_wanikani_settings',
    study.paramsFor(form, 'lessons')
  ).then(res =>
    render(propUtil.merge(res))
  )
}

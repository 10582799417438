import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as route from '../lib/route'
import * as home from '../lib/home'
import journeyView from './journey'
import levelBarView from './level-bar'
import homeStatsView from './home-stats'
import loadingView from './loading'
import noteworthyItemsView from './noteworthy-items'
import progressBarView from './progress-bar'

export default function ({ user, progress }) {
  if (!user || !progress) return route.redirect('/app/login')
  if (!home.dueToBeReloaded(progress)) {
    return h('div', { id: 'home' },
      h(Helmet, { title: 'Home' }),
      h('div', { class: 'section' },
        h('h2', {}, 'Your journey'),
        journeyView({ progress })
      ),
      h('div', { class: 'section' },
        h('h2', {}, 'Your statistics'),
        levelBarView(progress),
        homeStatsView(progress)
      ),
      h('div', { class: 'section' },
        h('h2', {}, 'Your learnings'),
        progressBarView({ progress }),
        noteworthyItemsView(progress.items)
      )
    )
  } else {
    home.load()
    return loadingView()
  }
}

import { h } from 'preact'
import Helmet from 'preact-helmet'
import bailWithoutView from './bail-without'
import lessonsFromListsView from './lessons-from-lists'
import lessonsFromWaniKaniAssignmentsView from './lessons-from-wanikani-assignments'
import lessonsFromContentView from './lessons-from-content'
import lessonsForExcludedItemsView from './lessons-for-excluded-items'
import pauseWarningView from './pause-warning'

export default function lessons ({ user, progress, lessonsStatus, showListDetails, ...props }) {
  if (bailWithoutView(user, progress)) return
  return h('div', { id: 'lessons' },
    h(Helmet, { title: 'Lessons' }),
    pauseWarningView({ user, mode: 'lessons' }),
    lessonsFromListsView({ user, lessonsStatus, showListDetails }),
    lessonsFromWaniKaniAssignmentsView({ user, lessonsStatus }),
    lessonsFromContentView(),
    lessonsForExcludedItemsView({ lessonsStatus }),
    h('div', { class: 'protip' },
      h('strong', {}, 'Pro-tip: '),
      'you can change many lesson settings in the ',
      h('a', { href: '/app/account' }, 'account tab'),
      ' 💫'
    )
  )
}

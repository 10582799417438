import { h } from 'preact'

export default function loadingSpinner () {
  return h('div', { class: 'loading-spinner' },
    h('div', {}),
    h('div', {}),
    h('div', {}),
    h('div', {}),
    h('div', {}),
    h('div', {}),
    h('div', {}),
    h('div', {})
  )
}

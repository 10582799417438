import * as _ from '../vendor/lodash'
import * as arrayUtil from '../lib/array-util'
import itemRecordingLinkView from './item-recording-link'

export default function itemReadingsAndRecordingLinksView ({ item }) {
  return arrayUtil.insertBetwixt(_.map(item.readings, (reading) => {
    const recordingUrl = item.recordings[reading]

    if (recordingUrl) {
      return [
        reading,
        itemRecordingLinkView({ recordingUrl })
      ]
    } else {
      return reading
    }
  }), '、')
}

import * as _ from '../vendor/lodash'
import * as jsonUtil from './json-util'
import * as auth from './auth'
import * as pwa from './pwa'

export const instance = _.memoize(() => {
  let store = storeForType(pickStorageType())
  const ownerId = auth.getUserId()
  if (store.userId !== ownerId) {
    reset()
    store = storeForType(pickStorageType())
    store.userId = ownerId
  }
  return store
})

let MEMORY_STORE
export function memoryStorage () {
  const store = {
    clear: () => {
      const replacement = memoryStorage()
      MEMORY_STORE = replacement
      return replacement
    },
    removeItem: (key) => delete store[key]
  }
  return store
}
MEMORY_STORE = memoryStorage()

export function storeForType (type) {
  if (type === 'localStorage') {
    return window.localStorage
  } else if (type === 'sessionStorage') {
    return window.sessionStorage
  } else if (type === 'memoryStorage') {
    return MEMORY_STORE
  }
}

export const pickStorageType = _.memoize(() => {
  const type = pwa.isStandalone() ? 'localStorage' : 'sessionStorage'
  try {
    window[type]['set-some-really-long-property-to-see-if-it-works'] = 'cool'
    return type
  } catch (e) {
    return 'memoryStorage'
  }
})

export function save (props) {
  set('props',
    _.pick(props, [
      'lessons', 'lessonsStatistics', 'lessonsSubMode',
      'reviews', 'reviewsStatistics',
      'wordsForLessons', 'userSessionInactivityIntervals'
    ])
  )
  return props
}

export function set (key, value) {
  instance()[key] = JSON.stringify(value)
  return value
}

export function get (key) {
  const store = instance()
  return jsonUtil.parse(store[key], () => store.removeItem(key))
}

export function pop (key) {
  const val = get(key)
  instance().removeItem(key)
  return val
}

export function load () {
  return get('props') || {}
}

export function reset () {
  if (window.localStorage) {
    window.localStorage.clear()
  }

  if (window.sessionStorage) {
    window.sessionStorage.clear()
  }

  MEMORY_STORE.clear()
}

import { h } from 'preact'

export default h('svg', {
  viewBox: '0 0 500 500',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg'
}, h('g', { id: 'reviews-icon' },
  h('path', {
    id: 'hopper',
    'fill-rule': 'evenodd',
    'clip-rule': 'evenodd',
    d: 'M22 71H12V458C12 463.523 16.4772 468 22 468H478C483.523 468 488 463.523 488 458V71H478V446C478 451.523 473.523 456 468 456H32C26.4771 456 22 451.523 22 446V71Z',
    fill: '#4F4F4F'
  }),
  h('g', { id: 'review-bars' },
    h('rect', {
      id: 'bar-1',
      x: '31',
      y: '389',
      width: '438',
      height: '60',
      rx: '10',
      fill: '#E63462',
      'fill-opacity': '0.01'
    }),
    h('rect', {
      id: 'bar-2',
      x: '31',
      y: '319',
      width: '438',
      height: '60',
      rx: '10',
      fill: '#E63462',
      'fill-opacity': '0.01'
    }),
    h('rect', {
      id: 'bar-3',
      x: '31',
      y: '249',
      width: '438',
      height: '60',
      rx: '10',
      fill: '#E63462',
      'fill-opacity': '0.01'
    }),
    h('rect', {
      id: 'bar-4',
      x: '31',
      y: '179',
      width: '438',
      height: '60',
      rx: '10',
      fill: '#E63462',
      'fill-opacity': '0.01'
    }),
    h('rect', {
      id: 'bar-5',
      x: '31',
      y: '109',
      width: '438',
      height: '60',
      rx: '10',
      fill: '#E63462',
      'fill-opacity': '0.01'
    }),
    h('rect', {
      id: 'bar-6',
      x: '31',
      y: '39',
      width: '438',
      height: '60',
      rx: '10',
      fill: '#E63462',
      'fill-opacity': '0.01'
    })
  )
))

import ListyDoodadView from './listy-doodad'

export default class Spellings extends ListyDoodadView {
  constructor ({ props }) {
    super({
      props,
      thingName: 'spelling',
      listTitle: 'Alternate Japanese spellings'
    })
  }
}

import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as net from '../lib/net'
import itemListFancyView from './item-list-fancy'
import loadingView from './loading'

export default function itemExclusionsView ({ ...props }) {
  const { data, loading } = net.useFetch('/api/item_exclusions')
  if (loading) return loadingView('🙅‍♀️  Blocked items incoming… 🙅‍♀️')

  return h('div', { id: 'excludedItems' },
    h(Helmet, { title: 'Blocked Items' }),
    h('h2', {}, 'Blocked items'),
    itemListFancyView({
      items: data.itemExclusions,
      baseUrl: '/app/blocked_items',
      ...props
    })
  )
}

import { h } from 'preact'
import images from '../images'
import * as study from '../lib/study'

export default function lessonTypeBadgeView (card) {
  const isProduction = study.questionType(card) === 'production'
  return h('img', {
    class: 'lesson-type-badge',
    src: isProduction ? images['production-badge.svg'] : images['recognition-badge.svg'],
    title: isProduction ? 'Production' : 'Recognition'
  })
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import itemListRowView from './item-list-row'

export default function itemListView (items, { sortDescription, englishIsEssential } = {}) {
  return h('div', { class: 'item-list full-width' },
    _.map(items, item =>
      itemListRowView(item, { sortDescription, englishIsEssential })
    )
  )
}

import * as _ from '../vendor/lodash'
import * as wanakana from 'wanakana'

export function plural (list, string, suffix = 's') {
  let count = 0
  if (_.isNumber(list)) {
    count = list
  } else if (!list) {
    count = 0
  } else {
    count = _.size(list)
  }

  if (count === 1) {
    return string
  } else {
    return string + suffix
  }
}

export function quoteList (oneOrMoreThings, conjunction = 'and', mode = 'en') {
  const quoted = oneOrMoreThings.map(thing => {
    if (mode === 'en') {
      return `"${thing}"`
    } else if (mode === 'ja') {
      return `「${thing}」`
    } else if (mode === 'none') {
      return thing
    } else {
      return undefined
    }
  })
  if (quoted.length === 0) {
    return ''
  } else if (quoted.length === 1) {
    return quoted[0]
  } else if (quoted.length === 2) {
    return [quoted[0], conjunction, quoted[1]].join(' ')
  } else {
    return quoted.slice(0, -1).join(', ') + `, ${conjunction} ${_.last(quoted)}`
  }
}

export function seriesPrefixFor (list, index, conjunction = 'and') {
  if (index === 0) {
    return ''
  } else if (index === 1 && list.length === 2) {
    return ` ${conjunction} `
  } else if (index === list.length - 1) {
    return `, ${conjunction} `
  } else {
    return ', '
  }
}

export function joinWithConjunction (array, conjunction = 'and', comma = ', ', commaStartsAt = 2) {
  return _.flatMap(array, (el, i) => {
    if (i === 0) {
      return el
    } else if (i === commaStartsAt - 1 && array.length === commaStartsAt) {
      return [conjunction || comma, el]
    } else if (i === array.length - 1) {
      return [comma, conjunction, el]
    } else {
      return [comma, el]
    }
  })
}

export function expandQueryCharacterSets (query) {
  return [
    query,
    wanakana.toRomaji(query),
    wanakana.toHiragana(query),
    wanakana.toKatakana(query),
    wanakana.stripOkurigana(query)
  ]
}

export function firstMatch (s, pattern) {
  const matches = s.match(pattern)
  return matches ? matches[1] : null
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'

export default function itemConjugationFactView (item, path, label) {
  const val = _.get(item.conjugations, path)
  if (!val) return

  return h('div', { class: 'fact' },
    h('div', { class: 'key' }, label),
    h('div', { class: 'value', lang: 'ja' }, val)
  )
}

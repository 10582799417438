import { h } from 'preact'

export default function timeAndDayView (timestamp) {
  if (!timestamp) return
  const date = new Date(timestamp)

  return [
    h('div', { class: 'day' }, `${date.getMonth() + 1}/${date.getDate()}`),
    h('div', { class: 'at' }, '@'),
    h('div', { class: 'time' }, `${twoPad(date.getHours())}:${twoPad(date.getMinutes())}`)
  ]
}

function twoPad (o) {
  const s = String(o)
  return s.length === 1 ? `0${s}` : s
}

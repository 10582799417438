import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as itemUtil from '../lib/item-util'
import * as study from '../lib/study'
import optionView from './option'

export default function learningConfigurationView ({ item, card }) {
  const questionType = study.questionType(card.lessonType)
  const challengeTexts = questionType === 'production'
    ? _.union(item.meanings, _.map(item.definitions, 'text'))
    : _.union(item.allTexts, _.map(item.spellings, 'text'))

  return challengeTexts.length < 2 || h('div', { class: 'learning-configuration' },
    h('label', { for: 'preferred_challenge_text' }, `
          ${questionType === 'production' ? 'Definition' : 'Spelling'}
          to be shown in reviews
        `),
    h('select',
      {
        id: 'preferred_challenge_text',
        name: 'preferred_challenge_text',
        onChange (e) {
          e.preventDefault()
          itemUtil.updatePreferredChallengeText({
            learningId: card.learningId,
            preferredChallengeText: e.currentTarget.value
          })
        }
      },
      optionView('', '', card.preferredChallengeText === ''),
      _.map(challengeTexts, (challengeText) =>
        optionView(challengeText, challengeText, card.preferredChallengeText === challengeText)
      )
    )
  )
}

import { h } from 'preact'
import { Link } from 'preact-router/match'
import * as stringUtil from '../lib/string-util'
import * as xp from '../lib/xp'
import lessonsIconView from './lessons-icon'
import progressIconView from './progress-icon'
import reviewsIconView from './reviews-icon'

export default function journey ({ progress }) {
  const level = progress.level
  const stars = progress.lessonStarsEarnedCount
  const zeroes = progress.reviewInboxZeroCount

  return h('div', { class: 'journey' },
    h('div', { class: 'milestone' },
      progressIconView({ progress }),
      h('div', { class: 'marker' }, `${level} ${stringUtil.plural(level, 'level')}`),
      h('div', { class: 'summary' }, level >= xp.MAX_LEVEL
        ? 'You made it!'
        : `${xp.MAX_LEVEL - level} ${stringUtil.plural(xp.MAX_LEVEL - level, 'level')} to ${xp.MAX_LEVEL}`)
    ),
    h('div', { class: 'milestone' },
      h(Link, { href: '/app/lessons', tabindex: 0 }, lessonsIconView({ progress })),
      h('div', { class: 'marker' }, `${stars} ${stringUtil.plural(stars, 'star')}`),
      h('div', { class: 'summary' }, progress.lessonsCompletedThisWeek >= xp.LESSONS_PER_STAR
        ? 'Got this week\'s star!'
        : `${xp.LESSONS_PER_STAR - progress.lessonsCompletedThisWeek} more ${stringUtil.plural(xp.LESSONS_PER_STAR - progress.lessonsCompletedThisWeek, 'lesson')}`)
    ),
    h('div', { class: 'milestone' },
      h(Link, { href: '/app/reviews', tabindex: 0 }, reviewsIconView({ progress })),
      h('div', { class: 'marker' }, `${zeroes} inbox ${stringUtil.plural(zeroes, 'zero', 'es')}`),
      h('div', { class: 'summary' }, progress.count.reviews <= 0
        ? 'You\'re at inbox 0!'
        : `${progress.count.reviews} ${stringUtil.plural(progress.count.reviews, 'review')} to go`)
    )
  )
}

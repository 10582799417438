import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as search from '../lib/search'
import formView from './form'
import searchResultsView from './search-results'
import images from '../images'

export default function searchView ({ query, searches, ...props }) {
  return h('div', { id: 'search' },
    h('h2', {}, 'Search'),
    h(Helmet, { title: 'Search' }),
    formView(_.extend({ onSubmit: search.handleSubmit }, props),
      h('div', { class: 'search-bar', ref (node) { if (node) toggleCancelButton(node) } },
        h('input', {
          id: 'searchQuery',
          name: 'search',
          placeholder: '何を探していますか？',
          type: 'text',
          results: 5,
          value: document.querySelector('#searchQuery')?.value || query,
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'none',
          spellcheck: 'false',
          lang: 'ja',
          autofocus: !query,
          onKeydown (e) {
            if (e.keyCode === 27) { // Escape
              clearSearch(e)
            }
          },
          onInput (e) {
            toggleCancelButton(e.target.closest('.search-bar'))
          }
        }),
        h('div', { class: 'buttons' },
          h('img', {
            src: images['search-cancel.svg'],
            alt: 'Clear search field',
            class: `cancel-button ${!query && 'hidden'}`,
            onClick: clearSearch
          })
        )
      )
    ),
    searchResultsView({ searches, query, ...props })
  )
}

function clearSearch (e) {
  e.preventDefault()
  e.stopPropagation()
  const searchBar = e.target.closest('.search-bar')
  const searchInput = searchBar.querySelector('input')

  // If search input has content, clear it but retain foucs. If it's already
  // empty then blur it (allows you to hit escape twice and get out of the field)
  if (searchInput.value) {
    searchInput.value = ''
    toggleCancelButton(searchBar)
    searchInput.focus()
  } else {
    searchInput.blur()
  }
}

function toggleCancelButton (searchBar) {
  searchBar.querySelector('.cancel-button').classList
    .toggle('hidden', !searchBar.querySelector('input').value)
}

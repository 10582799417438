import * as notify from './notify'
import * as store from './store'
import * as route from './route'
import * as _ from '../vendor/lodash'

export function recordAssetVersions () {
  store.set('KAMESAME_JS_VERSION', document.querySelector('script#kamesame_js').getAttribute('src'))
  store.set('KAMESAME_CSS_VERSION', document.querySelector('link#kamesame_css').getAttribute('href'))
}

let PAGE_RELOAD_NEEDED = false
export function schedulePageReloadIfOutdated ({ api, js, css }) {
  let outdated = false
  let bailImmediately = false
  if (api && window.KAMESAME_CLIENT_VERSION && api !== window.KAMESAME_CLIENT_VERSION) {
    outdated = true
    bailImmediately = true
  }
  if (js && store.get('KAMESAME_JS_VERSION') && !_.endsWith(store.get('KAMESAME_JS_VERSION'), js)) {
    outdated = true
  }
  if (css && store.get('KAMESAME_CSS_VERSION') && !_.endsWith(store.get('KAMESAME_CSS_VERSION'), css)) {
    outdated = true
  }

  if (outdated) {
    PAGE_RELOAD_NEEDED = true
  }
  if (bailImmediately) {
    route.redirect('/app', true)
  }
}

export function reloadPageIfOutdated (url) {
  if (PAGE_RELOAD_NEEDED &&
    !route.matches(route.PATHS.clearFromHistory, url) &&
    process.env.NODE_ENV !== 'development') {
    notify.setQueuedMessage('KameSame was reloaded because a new version was released ✨')
    window.location.reload()
  }
}

export function handleVersionDiscrepanciesInXhr (res) {
  schedulePageReloadIfOutdated({
    api: res.headers.get('X-KAMESAME-CLIENT-VERSION'),
    js: res.headers.get('X-KAMESAME-JS-VERSION'),
    css: res.headers.get('X-KAMESAME-CSS-VERSION')
  })
}

export function handleVersionDiscrepanciesInWs (res) {
  schedulePageReloadIfOutdated({
    api: res.KAMESAME_CLIENT_VERSION,
    js: res.KAMESAME_JS_VERSION,
    css: res.KAMESAME_CSS_VERSION
  })
}

export function enforceCompatibleStorage () {
  if (!window.KAMESAME_CLIENT_VERSION) return

  if (!store.get('KAMESAME_CLIENT_VERSION')) {
    store.set('KAMESAME_CLIENT_VERSION', window.KAMESAME_CLIENT_VERSION)
  } else if (store.get('KAMESAME_CLIENT_VERSION') !== window.KAMESAME_CLIENT_VERSION) {
    store.reset()
    store.set('KAMESAME_CLIENT_VERSION', window.KAMESAME_CLIENT_VERSION)
    notify.setQueuedMessage('KameSame just received a large update, you may need to restart your study session (no progress was lost)')
  }
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import images from '../images'
import * as auth from '../lib/auth'
import formView from './form'

export default (props) =>
  h('div', { id: 'resetPassword' },
    h('img', { src: images['login-header.svg'], alt: 'KameSame title' }),
    h('div', { class: 'wrap' },
      formView(_.extend({ action: '/app/set_new_password' }, props),
        h('input', {
          type: 'hidden',
          name: 'token',
          value: auth.getPasswordResetToken()
        }),
        h('h2', {}, 'Choose a new password'),
        h('div', {},
          h('label', { for: 'password' }, 'Password'),
          h('input', { id: 'password', name: 'password', type: 'password', minlength: 6, required: true })
        ),
        h('div', { class: 'buttons' },
          h('button', { type: 'submit' }, 'Change password')
        )
      )
    )
  )

import * as xp from '../lib/xp'

export default function navHomeMaxLevelEffectView (node, progress) {
  const dot = node.querySelector('#max-level')
  if (progress.level >= xp.MAX_LEVEL) {
    dot.setAttribute('fill-opacity', 1)
  } else {
    dot.setAttribute('fill-opacity', 0)
  }
}

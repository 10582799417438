import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as pwa from '../lib/pwa'
import * as study from '../lib/study'

export default function reviewWipView (cards, user) {
  const wip = _.filter(cards, c => !c.complete)
  if (wip.length === 0) return
  return [h('div', { class: `wip section-wide undo-top-padding ${pwa.isStandalone() ? 'overrides-background-color' : ''}` },
    h('h2', {}, 'Review in Progress'),
    h('p', {}, `${wip.length} reviews left`),
    h('div', { class: 'buttons' },
      h('button', {
        onClick (e) { study.abandon(e, 'reviews', cards) },
        tabindex: 0
      }, 'Abandon'),

      h('a', { href: study.nextCardPath('reviews', wip, user), tabindex: 0 },
        h('button', {}, 'Continue')
      )
    )
  ), h('div', { class: 'spacer' })]
}

import { h } from 'preact'

export default function listModes () {
  return h('div', {},
    h('h1', {}, 'Learning from a list'),
    h('p', {}, `
      Lists are a great way to guide your learning by giving you a curriculum
      to work through that prioritizes more commonly used words or which focus
      on a particular topic or subject. However, because everyone's language
      proficiency differs, we offer several modes of study to suit multiple
      learning styles and experience levels.
    `),
    h('h2', {}, 'Regular lessons'),
    h('p', {}, `
      In this mode, the study session will behave just like any other lesson
      mode: you'll repeat each card until you provide a correct answer, at which
      point it will be added to your review queue. This mode works best when
      most of the items in a list are new to you.
    `),
    h('h2', {}, 'Placement test'),
    h('p', {}, `
      In this mode, any correctly-answered items will be marked
      as already-learned (i.e. burned) and any cards you get wrong will be
      automatically added to your review queue. This is a handy way to work
      through a list when you already know a significant number of its items.
    `),
    h('h2', {}, 'Survey content'),
    h('p', {}, `
      In this mode, you'll be quizzed on each item skill just once—and the results
      won't matter! Items can be manually added to your review queue or marked
      as already-learned, but nothing is saved automatically. It can be
      useful to shuffle a list to get a sense for its contents and to learn or
      burn items on a case-by-case basis. (Note: because you may want to mark a
      correctly-answered card as learned, this mode ignores the "Skip results
      page for correct answers" preference, if enabled).
    `)
  )
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as net from '../lib/net'
import loadingView from './loading'
import itemListFancyView from './item-list-fancy'

export default function learnings ({ rank, learnings, itemListType, itemListSort, itemListFilter }) {
  if (learnings && learnings[rank]) {
    return h('div', { id: 'learnings' },
      h('h2', {}, `${_.capitalize(rank)} items`),
      itemListFancyView({
        items: learnings[rank],
        baseUrl: `/app/learnings/${rank}`,
        itemListType,
        itemListSort,
        itemListFilter
      })
    )
  } else {
    net.update(`/api/learnings/${rank}`)
    return loadingView('🗂 Looking up things you\'ve learned 🗂')
  }
}

import { h, Component } from 'preact'
import * as _ from '../vendor/lodash'
import * as itemUtil from '../lib/item-util'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'

export default class ListyDoodad extends Component {
  constructor ({ props, thingName, listTitle }) {
    super(props)
    this.thingName = thingName || 'thing'
    this.pluralName = `${this.thingName}s`
    this.capitalName = _.capitalize(this.thingName)
    this.listTitle = listTitle
    this.state = { props }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.props.item, this.state.props.item)) {
      // Is this unbelievably awful that a component is updating global state? Sure. Does it work? Possibly
      render(propUtil.mergeAndSave(itemUtil.newPropsForItemUpdate(this.state.props.item.id, {
        [this.pluralName]: this.state.props.item[this.pluralName]
      })))
    }
  }

  render () {
    const item = this.state.props.item
    if (!item) return

    return h('div', { class: this.pluralName },
      h('h2', {}, `${this.listTitle}:`),
      h('ul', {},
        _.map(_.filter(item[this.pluralName], 'official'), (thing) =>
          h('li', { class: 'official' },
            h('input', { class: 'text', value: thing.text, disabled: true }),
            h('button', { disabled: true, title: `Official ${this.thingName}` }, '⭐️')
          )
        ),
        _.map(_.reject(item[this.pluralName], 'official'), (thing) =>
          h('li', { class: 'user' },
            h('input', { class: 'text', value: thing.text, disabled: true }),
            h('button', {
              class: 'delete',
              disabled: !thing.id,
              title: `Delete ${this.thingName}`,
              onClick: (e) => {
                this.delete(thing, item)
              }
            }, '💥')
          )
        ),
        h('li', { class: 'new' },
          h('input', {
            type: 'checkbox',
            id: `addNew${this.capitalName}`,
            class: 'add',
            onKeydown: (e) => {
              if (e.keyCode === 13) {
                const node = e.target
                node.checked = !node.checked
                this.handleAddNewCheckbox(e)
              }
            },
            onClick: (e) => {
              this.handleAddNewCheckbox(e)
            },
            checked: this.state.isAdding,
            title: `Add new ${this.thingName}`
          }),
          h('label', { for: `addNew${this.capitalName}` }, `Add new ${this.thingName}`),
          h('input', {
            class: 'text',
            autofocus: true,
            onKeydown: (e) => {
              const node = e.target
              // Put differently: if user hits enter and form is empty then allow global handle-enter event to fire
              if (e.keyCode !== 13 || node.value.trim()) e.stopPropagation()

              if (e.keyCode === 13 && node.value.trim()) {
                this.save(item, node)
              } else if (e.keyCode === 27) {
                node.value = ''
                node.parentNode.querySelector('.add').checked = false
                node.blur()
              }
            },
            onFocus: () => {
              document.body.classList.add('user-is-typing')
            },
            onBlur: () => {
              document.body.classList.remove('user-is-typing')
            }
          }),
          h('button', {
            title: `Save ${this.thingName}`,
            onClick: (e) => {
              this.save(item, e.target.previousSibling)
            }
          }, '💾')
        )
      )
    )
  }

  handleAddNewCheckbox (e) {
    const node = e.target
    const textField = node.parentNode.querySelector('.text')
    this.setState({ isAdding: node.checked })
    if (node.checked) {
      textField.focus()
    } else {
      textField.value = ''
    }
  }

  save (item, input) {
    const text = input.value
    input.value = ''
    if (!text) return
    const newThing = {
      id: null,
      official: false,
      text
    }
    const newItem = _.extend({}, item, {
      [this.pluralName]: item[this.pluralName].concat(newThing)
    })

    this.setState({ props: { item: newItem } })
    itemUtil[`save${this.capitalName}`](newItem, text, (savedThing) => {
      const savedItem = _.extend({}, newItem, {
        [this.pluralName]: _.map(newItem[this.pluralName], thing =>
          thing === newThing ? savedThing : thing
        )
      })
      this.setState({ props: { item: savedItem } })
    })
  }

  delete (thing, item) {
    const newItem = _.extend({}, item, {
      [this.pluralName]: _.reject(item[this.pluralName], other => other.id === thing.id)
    })
    this.setState({ props: { item: newItem } })
    itemUtil[`delete${this.capitalName}`](thing, newItem)
  }
}

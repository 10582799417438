import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as review from '../lib/review'
import * as study from '../lib/study'
import itemProgressBarView from './item-progress-bar'
import learningConfigurationView from './learning-configuration'

export default function itemProgressView ({ item, progress }) {
  return h('div', { class: 'progress' },
    h('h2', {}, `${_.capitalize(progress.lessonTypeDescription)} progress`),
    h('div', { class: 'item-progress' },
      h('p', {}, study.lessonTypeDocumentation(progress.lessonTypeDescription)),
      h('div', { class: `rank ${progress.srsBucket}` },
        _.capitalize(progress.srsBucket)
      ),
      h('div', { class: 'bars' },
        h('h4', {}, 'Exactly correct'),
        itemProgressBarView({ progress, type: 'exactCorrectCount' }),
        item.type === 'vocabulary' && [
          h('h4', {}, 'Reading correct'),
          itemProgressBarView({ progress, type: 'readingCorrectCount' })
        ],
        h('h4', {}, 'Incorrect'),
        itemProgressBarView({ progress, type: 'incorrectCount' })
      ),
      progress.srsBucket !== 'burned' && h('div', { class: 'next-review' },
        `Available for review ${progress.nextReviewAtDesc}.`
      ),
      learningConfigurationView({ item, card: progress }),
      h('div', { class: 'one-off-actions' },
        h('a', {
          class: 'one-off-item-action',
          onClick (e) { review.unlearn(item, progress) }
        },
        h('button', { tabindex: 0 },
          h('span', { class: 'emoji-inline' }, '💥'),
            `Unlearn ${progress.lessonTypeDescription}`
        ))
      )
    )
  )
}

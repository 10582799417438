import { h } from 'preact'
import * as _ from '../vendor/lodash'

export default function svgTextView (text) {
  return h('svg', {
    class: 'big-text',
    ref (svg) {
      if (!svg) return
      _.defer(() => {
        const bbox = svg.getBBox()
        svg.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`)
      })
    }
  }, h('text', {}, text))
}

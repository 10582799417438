import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as study from '../lib/study'
import bailWithoutView from './bail-without'
import lessonsByBucketView from './lessons-by-bucket'

export default function placementSummaryView ({ lessons }) {
  if (bailWithoutView(lessons)) return
  const cards = _.sortBy(lessons, 'item.text')

  const { learned, burned, notAdded } = _.groupBy(cards, card => {
    if (card.learningId && card.srsStage === 1) {
      return 'learned'
    } else if (study.isBurned(card)) {
      return 'burned'
    } else {
      return 'notAdded'
    }
  })
  const multipleLessonTypes = _.uniqBy(cards, (card) => study.questionType(card)).length > 1

  return h('div', { id: 'summary' },
    h('h2', {}, 'Placement Test Results'),
    h(Helmet, { title: 'Placement Test Results' }),
    burned && [
      h('h3', {}, 'Marked as Learned (Burned)'),
      lessonsByBucketView(burned, multipleLessonTypes)
    ],
    learned && [
      h('h3', {}, 'Added to Reviews'),
      lessonsByBucketView(learned, multipleLessonTypes)
    ],
    notAdded && [
      h('h3', {}, 'Not Added to Reviews'),
      lessonsByBucketView(notAdded, multipleLessonTypes)
    ]
  )
}

import * as net from './net'
import * as propUtil from './prop-util'
import render from './render'
import * as route from './route'

export function attempt (e) {
  e.preventDefault()
  net.post('/api/sessions', {
    email: e.target.querySelector('input[name=email]').value,
    password: e.target.querySelector('input[name=password]').value
  }).then(res => {
    if (res.user) {
      document.querySelector('meta[name="user-id"]').setAttribute('content', res.user.id)
      route.andRerender('/app', propUtil.merge(res))
    } else {
      render(propUtil.merge(res))
    }
  })
}

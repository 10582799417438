import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as arrayUtil from '../lib/array-util'
import * as itemUtil from '../lib/item-util'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'
import * as route from '../lib/route'
import excludeOrIncludeButtonView from './exclude-or-include-button'
import instantLessonButton from './instant-lesson-button'
import itemLinkView from './item-link'
import itemListRowView from './item-list-row'

const ITEM_SORT_OPTIONS = [
  {
    name: 'default',
    label: 'Default order',
    sortBy: null
  },
  {
    name: 'type',
    label: 'Item type',
    sortBy: 'type'
  },
  {
    name: 'meaning',
    label: 'Meaning',
    sortBy (item) { return item.meanings[0] }
  },
  {
    name: 'reading',
    label: 'Reading',
    sortBy (item) { return item.readings[0] }
  },
  {
    name: 'wanikaniLevel-asc',
    label: 'WaniKani level ⬆',
    sortBy: (item) => item.wanikaniLevel || 999 // push null items to the end
  },
  {
    name: 'wanikaniLevel-desc',
    label: 'WaniKani level ⬇',
    sortBy: 'wanikaniLevel',
    sortOrder: 'desc'
  },
  {
    name: 'frequency',
    label: 'Word frequency',
    sortBy: 'priority',
    sortOrder: 'desc'
  }
]

const ITEM_LIST_TYPES = [
  {
    name: 'detail',
    label: 'Big',
    render ({ items, sortDescription, englishIsEssential }) {
      return h('ul', { class: 'item-list full-width' },
        _.map(items, item =>
          itemListRowView(item, { sortDescription, englishIsEssential })
        )
      )
    }
  },
  {
    name: 'compact',
    label: 'Small',
    render ({ items, englishIsEssential }) {
      return h('ul', { class: 'item-list' },
        _.map(items, item =>
          h('li', {}, itemLinkView(item))
        )
      )
    }
  },
  {
    name: 'potential-lessons',
    label: 'Lessons',
    render ({ items }) {
      return h('div', {},
        _.map(items, item =>
          h('div', { class: 'lesson-row' },
            excludeOrIncludeButtonView({ items, item }),
            h('div', { class: 'lesson-word item-list full-width' },
              itemListRowView(item)
            ),
            h('div', { class: 'lesson-selection checkbox' },
              h('input', {
                type: 'checkbox',
                'data-item-id': item.id,
                'data-excluded': String(item.isExcluded),
                onClick (e) {
                  render(propUtil.merge({
                    wordsForLessons: {
                      [item.id]: {
                        isSelected: e.target.checked
                      }
                    }
                  }))
                },
                checked: item.isSelected
              })
            )
          )
        )
      )
    }
  },
  {
    name: 'search-results',
    label: 'Search Results',
    render ({ items, query }) {
      return h('div', { class: 'item-list full-width' },
        _.map(items, item =>
          h('div', { class: 'result-row' },
            h('div', { class: 'result-word ' },
              itemListRowView(item)
            ),
            instantLessonButton({ item, query })
          )
        )
      )
    }
  }

]

export default function itemListFancyView ({
  items, baseUrl,
  itemListType, itemListSort, itemListFilter,
  disableListTypes = ['potential-lessons', 'search-results'], enableListTypes,
  disableSortOptions, disableFilter,
  sortDescription, englishIsEssential,
  ...props
}) {
  const enabledSortOptions = arrayUtil.whereNot(ITEM_SORT_OPTIONS, disableSortOptions)
  const currentSortOption = arrayUtil.pick(enabledSortOptions, itemListSort) || enabledSortOptions[0]
  enableListTypes = enableListTypes
    ? arrayUtil.where(ITEM_LIST_TYPES, enableListTypes)
    : arrayUtil.whereNot(ITEM_LIST_TYPES, disableListTypes)
  const currentListType = arrayUtil.pick(enableListTypes, itemListType) || enableListTypes[0]
  const filteredItems = disableFilter ? items : itemUtil.filter(items, itemListFilter)

  return h('div', { class: 'fancy-item-list' },
    h('div', { class: 'actions' },
      enabledSortOptions === 1 || h('select', {
        class: 'sort-order',
        onChange (e) {
          e.preventDefault()
          route.redirect(
            route.join(baseUrl, currentListType.name, e.target.value, itemListFilter)
          )
        }
      }, _.map(enabledSortOptions, (sortOption) =>
        h('option', { value: sortOption.name, selected: currentSortOption === sortOption }, sortOption.label)
      )),
      enableListTypes.length === 1 || h('span', { class: 'mode' },
        arrayUtil.insertBetwixt(_.map(enableListTypes, listType =>
          currentListType === listType
            ? listType.label
            : h('a', {
              href: route.join(baseUrl, listType.name, currentSortOption.name, itemListFilter),
              tabindex: 0
            }, listType.label)
        ), ' • ')
      )
    ),
    disableFilter || h('div', { class: 'filter' },
      h('input', {
        name: 'search',
        placeholder: 'Filter items',
        value: itemListFilter,
        autocomplete: 'off',
        autocorrect: 'off',
        autocapitalize: 'none',
        spellcheck: 'false',
        lang: 'ja',
        onKeyUp (e) {
          e.preventDefault()
          route.redirect(
            route.join(baseUrl, currentListType.name, currentSortOption.name, e.target.value.trim())
          )
        }
      })
    ),
    currentListType.render({
      items: arrayUtil.order(_.sortBy(filteredItems, currentSortOption.sortBy), currentSortOption.sortOrder),
      englishIsEssential,
      sortDescription,
      ...props
    }),
    items.length === filteredItems.length || h('p', { class: 'note' },
      filteredItems.length === 0
        ? ['No items matched: ', h('strong', {}, itemListFilter)]
        : `${items.length - filteredItems.length} items were filtered out.`
    )
  )
}

import * as _ from '../vendor/lodash'
import * as store from './store'
import * as notify from './notify'

let props
export function init (initialProps) {
  props = initialProps
  return props
}

export function merge (moreProps) {
  props = _.mergeWith({}, props, moreProps, customMerge)
  return props
}

export const get = path =>
  _.get(props, path)

export function mergeAndSave (moreProps) {
  return store.save(merge(moreProps))
}

export function customMerge (objValue, srcValue, key, object, source, stack) {
  // 1. Don't munge arrays, just replace them whole cloth on merge
  if (_.isArray(objValue)) {
    return srcValue
  }
}

export function replace (path, cbOrVal) {
  props = _.cloneDeep(props)
  _.set(props, path, _.isFunction(cbOrVal) ? cbOrVal(_.get(props, path)) : cbOrVal)
  return props
}

export function replaceAndSave (path, cbOrVal) {
  return store.save(replace(path, cbOrVal))
}

export function remove (...paths) {
  props = _.cloneDeep(props)
  _.each(paths, path =>
    _.unset(props, path)
  )
}

export function eraseOneTimeProps () {
  props = _.omitBy(props, (v, k) => _.startsWith(k, 'oneTime'))
}

export function withAbsorbedStoredProps (props) {
  return merge({
    errorMessages: (props.errorMessages || []).concat(notify.absorbStoredMessages('error')),
    infoMessages: (props.infoMessages || []).concat(notify.absorbStoredMessages('info'))
  })
}

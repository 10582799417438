import * as _ from '../vendor/lodash'
import * as store from './store'
import * as pwa from './pwa'
import rerender from './rerender'

const PLATFORMS = {
  iOS: [
    'iPhone', 'iPad', 'iPod',
    'iPhone Simulator', 'iPad Simulator', 'iPod Simulator'
  ],
  macOS: ['MacIntel']
}

export const isIos = () =>
  _.includes(PLATFORMS.iOS, navigator.platform) || (
    // iPad OS 13 lol:
    _.includes(PLATFORMS.macOS, navigator.platform) && userHasTouchedTheirScreen()
  )

export const isApple = () =>
  _.includes(
    PLATFORMS.iOS.concat(PLATFORMS.macOS),
    navigator.platform
  )

export function hasNotch () {
  const notch = document.querySelector('#notch')
  if (notch) {
    return notch.clientHeight > 0
  }
}

export const userHasTouchedTheirScreen = () =>
  store.get('userHasTouchedTheirScreen')

export function waitForFirstTouch () {
  const handler = () => {
    store.set('userHasTouchedTheirScreen', true)
    rerender()
    document.removeEventListener('touchstart', handler)
  }
  document.addEventListener('touchstart', handler, { passive: true })
}

export function userMayHaveTouched (userTouched) {
  if (userTouched) {
    store.set('userHasTouchedTheirScreen', true)
  }
}

export function focusKeyboardWithoutPushingPage (el) {
  if (isIos()) {
    el.style.transform = 'TranslateY(-8000px)'
    el.focus()
    window.setTimeout(() => {
      el.style.transform = 'none'
    }, 0)
  } else {
    el.focus()
  }
}

export function isIphoneX () {
  if (!isIos()) return

  const ratio = window.devicePixelRatio || 1
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio
  }

  return (screen.width === 1125 && screen.height === 2436) ||
    (screen.width === 1242 && screen.height === 2688) ||
    (screen.width === 828 && screen.height === 1792)
}

export function needsVerticalLiftBecauseIphoneXEnterButtonIsTooHigh () {
  if (isIos() && pwa.isStandalone() && isIphoneX()) {
    return 'bump-up'
  } else {
    return ''
  }
}

export function shouldChokeUp () {
  return userHasTouchedTheirScreen() && isIos()
}

import { h } from 'preact'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'
import * as tutorial from '../tutorial'
import images from '../images'

export default function info ({ infoTutorial, infoShouldBeDisplayed }) {
  if (!infoShouldBeDisplayed) return

  return h('div', { id: 'info', 'click-on-escape': 'a.close' },
    h('a', {
      class: 'close',
      onClick (e) {
        e.preventDefault()
        render(propUtil.merge({
          infoTutorial: null,
          infoShouldBeDisplayed: false
        }))
      }
    },
    h('img', { src: images['info-close.svg'] })),
    h('div', { class: 'tutorial' }, tutorial[infoTutorial]())
  )
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import itemLinkView from './item-link'
import lessonTypeBadgeView from './lesson-type-badge'

export default function lessonsByBucketView (cards, multipleLessonTypes) {
  return h('ul', { class: 'item-list' },
    _.map(cards, card => h('li', {}, itemLinkView(card.item, {
      children: [
        multipleLessonTypes ? lessonTypeBadgeView(card) : null
      ]
    })))
  )
}

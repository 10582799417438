import { h } from 'preact'
import * as itemUtil from '../lib/item-util'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'

export default function excludeOrIncludeButton ({ wordsForLessons, item }) {
  const mode = item.isExcluded ? 'include' : 'exclude'
  return h('a', {
    class: 'include-exclude-button',
    title: item.isExcluded ? 'Include this item in lessons' : 'Block this item from lessons',
    onClick (e) {
      e.preventDefault()
      itemUtil[mode](item, (er, isExcluded) => {
        render(propUtil.merge({
          wordsForLessons: {
            [item.id]: { isExcluded, isSelected: !isExcluded }
          }
        }))
      })
    }
  }, h('button', {}, item.isExcluded ? '🙆‍♀️' : '🙅‍♂️'))
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as xp from '../lib/xp'
import levelBarView from './level-bar'

export default function levelBarsView ({ cards, progress }) {
  const xpEarned = _.sumBy(Object.values(cards), 'xpEarned') || 0
  const totalXp = progress.totalXp
  const priorXp = progress.totalXp - xpEarned
  const startingLevel = xp.levelFor(totalXp - xpEarned)
  const currentLevel = xp.levelFor(totalXp)

  return h('div', { class: 'level-bars' },
    _.map(_.range(startingLevel, currentLevel + 1), level => // <-- `+1` because ranges are exclusive (╯°□°）╯︵ ┻━┻
      levelBarView({
        level,
        priorXp,
        xpEarned,
        totalXp
      })
    )
  )
}

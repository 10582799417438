import '../vendor/bugsnag-small'
import * as _ from '../vendor/lodash'
import * as net from './net'

export const client = _.once(() => {
  if (window.location.hostname.indexOf('kamesame.com') === -1) {
    const localNotifier = {
      notifyException: (...args) => { log(...args) }
    }
    window.onerror = localNotifier.notifyException
    return localNotifier
  } else {
    return _.tap(window.Bugsnag.noConflict(), client => {
      client.apiKey = 'c7b875a1d6502fe72e6d303590d7a6a1'
      client.disableLog = true
      client.autoNotify = true
      client.notifyHandler = 'xhr'
    })
  }
})

export function start () {
  const bugsnagClient = client()

  return function assignUser (id) {
    bugsnagClient.user = { id }
  }
}

export function log (...args) {
  console.log(...args)
  net.post('/api/puts', { content: args })
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as auth from '../lib/auth'

export default ({ action, oneTimeRerenderParams, ...formOptions }, ...children) =>
  h('form', {
    action,
    ...formOptions,
    method: 'post',
    ref (c) {
      if (c && oneTimeRerenderParams) {
        _.each(oneTimeRerenderParams, (v, k) => {
          const input = c.querySelector(`input[name=${k}]`)
          if (input) input.value = v
        })
      }
    }
  },
  h('input', {
    type: 'hidden',
    name: 'authenticity_token',
    value: auth.getAuthenticityToken()
  }),
  ...children
  )

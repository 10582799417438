import { h } from 'preact'
import icons from '../icons'
import navHomeEffectView from './nav-home-effect'

export default function progressIcon ({ progress }) {
  return h('div', {
    class: 'svg',
    ref (c) { if (c) navHomeEffectView(c, progress) }
  }, icons.progress)
}

import * as _ from '../vendor/lodash'
import * as store from './store'
import * as auth from './auth'
import * as route from './route'

const SCHEMA_VERSION = 4

export function save (props) {
  store.set('pwa', _.extend(
    load(), {
      SCHEMA_VERSION: SCHEMA_VERSION
    }, props
  ))
}

export function load () {
  const pwa = store.get('pwa') || {}
  if (pwa.SCHEMA_VERSION === SCHEMA_VERSION) {
    return pwa
  } else {
    return {}
  }
}

export function initialize () {
  if (!isStandalone()) return
  const pwa = load()

  if (auth.isLoggedIn()) {
    const lastUrl = _.last(pwa.history)
    if (lastUrl) {
      window.history.replaceState(null, null, lastUrl)
    }
  }
}

export const isStandalone = () =>
  window.matchMedia('(display-mode: standalone)').matches

export function appendUrlToPersistedHistory (url) {
  if (!isStandalone() || !auth.isLoggedIn()) return
  const history = load().history || []
  save({
    history: _.takeRight(
      _.last(history) === url ? history : history.concat(url)
      , 100)
  })
}

export function goBack () {
  const history = load().history || []
  const previousUrl = history[history.length - 2]
  if (previousUrl) {
    route.redirect(previousUrl)
  }
}

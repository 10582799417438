import { h } from 'preact'
import * as util from '../lib/util'
import * as itemUtil from '../lib/item-util'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'
import images from '../images'

export default function instantLessonButton ({ item, query }) {
  const mode = item.instantAddable
  return h('button', {
    class: `instant-lesson-button ${item.type}`,
    title: util.switchOn(mode, {
      learnable: 'Add this item to your reviews',
      undoable: 'Remove this item from your reviews',
      existing: 'You are already studying this item'
    }),
    disabled: mode === 'existing',
    tabindex: mode === 'existing' ? undefined : 0,
    onClick (e) {
      e.preventDefault()
      const button = e.currentTarget
      button.disabled = true
      const rerenderSearchCb = (er, res) => {
        button.disabled = false
        render(propUtil.merge({
          searches: {
            [query]: {
              results: util.extendItemInItems(propUtil.get('searches')[query].results, item.id, {
                instantAddable: mode === 'learnable' ? 'undoable' : 'learnable'
              })
            }
          }
        }))
      }

      if (mode === 'learnable') {
        itemUtil.addToReviewsInstantly(item, rerenderSearchCb)
      } else if (mode === 'undoable') {
        itemUtil.undoAddToReviewsInstantly(item, rerenderSearchCb)
      }
    }
  },
  h('img', {
    src: util.switchOn(mode, {
      learnable: images['add-to-reviews.svg'],
      undoable: images['undo-add-to-reviews.svg'],
      existing: images['already-in-reviews.svg']
    })
  })
  )
}

import { h } from 'preact'
import * as auth from '../lib/auth'
import loadingSpinnerView from './loading-spinner'

export default function accountSetupInProgress () {
  return h('div', {
    id: 'accountSetupInProgress',
    ref (c) { if (c) auth.pollAccountStatus() }
  },
  loadingSpinnerView(),
  h('h1', {}, 'Sit tight!'),
  h('p', {}, `
      We're fetching your review activity from WaniKani so that you can get
      started! This should only take a minute!
    `)
  )
}

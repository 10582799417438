import * as _ from '../vendor/lodash'
import { h } from 'preact'
import notificationView from './notification'

export default function notificationBar ({ errorMessages, infoMessages, isNotificationAlternate }) {
  if (_.isEmpty(errorMessages) && _.isEmpty(infoMessages)) return

  return h('div', {
    id: 'notification',
    ref (c) {
      _.defer(() => {
        if (c && !isNotificationAlternate && document.querySelector('#notification_alternative')) {
          c.remove()
        }
      })
    }
  }, _.map(errorMessages, message =>
    notificationView({ message, type: 'error' })
  ).concat(_.map(infoMessages, message =>
    notificationView({ message, type: 'info' })
  ))
  )
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as study from '../lib/study'
import itemLinkView from './item-link'
import lessonTypeBadgeView from './lesson-type-badge'
import rankChangeBadgeView from './rank-change-badge'

export default function reviewsByBucketView (cards, multipleLessonTypes) {
  return _.map(study.byBucketDescending(cards), (bucketCards, bucket) =>
    h('div', { class: `review-bucket ${bucket}` },
      h('div', {},
        h('h4', {}, `${bucketCards.length} ${_.capitalize(bucket)}`),
        h('ul', { class: 'item-list' }, _.map(bucketCards, card =>
          h('li', {},
            itemLinkView(card.item, {
              children: [
                rankChangeBadgeView(card),
                multipleLessonTypes ? lessonTypeBadgeView(card) : null
              ]
            })
          )
        ))
      )
    )
  )
}

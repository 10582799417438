import { h } from 'preact'
import { Link } from 'preact-router/match'
import * as review from '../lib/review'
import reviewsIconView from './reviews-icon'

export default function navReviewsView ({ progress, reviewsStatus }) {
  return h(Link, {
    href: '/app/reviews',
    activeClassName: 'active',
    title: `${progress.count.reviews} reviews are ready`,
    onClick (e) { review.reloadUnlessAlreadyDueToBeReloaded(reviewsStatus) }
  },
  reviewsIconView({ progress }),
  h('div', { class: 'label' }, h('span', {}, 'Reviews'))
  )
}

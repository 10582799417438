import * as _ from '../vendor/lodash'
import * as propUtil from './prop-util'
import render from './render'
import * as study from './study'

export function exitStudyMode () {
  const mode = study.modeFromPath(window.location.pathname)
  study.finish(mode, propUtil.merge())
}

export function changeFocus (predicate) {
  const nodes = document.querySelectorAll('[tabindex]')
  const index = _.findIndex(nodes, node => node === document.activeElement)
  const nextNode = predicate(nodes, index)
  if (nextNode) nextNode.focus()
}

export function nextItem () {
  changeFocus((nodes, index) =>
    nodes[index + 1] || nodes[0]
  )
}

export function previousItem () {
  changeFocus((nodes, index) =>
    nodes[index - 1] || nodes[nodes.length - 1]
  )
}

export function clickCardAction (selector) {
  return () =>
    document.querySelector('.card-actions ' + selector)?.click()
}

export function blurFocusAndDismissStuff () {
  if (document.activeElement) {
    document.activeElement.blur()
  }
  const dismissableElements = document.querySelectorAll('[click-on-escape]')
  _.each(dismissableElements, (el) => {
    const sel = el.attributes['click-on-escape'].value
    const clickTarget = el.querySelector(sel)
    if (clickTarget) {
      clickTarget.click()
    }
  })
}

export function showHelp () {
  render(propUtil.merge({
    infoTutorial: 'hotkeys',
    infoShouldBeDisplayed: !propUtil.get('infoShouldBeDisplayed')
  }))
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as pwa from '../lib/pwa'

export default function link (url, children, props = {}) {
  props = _.extend({ href: url }, props)
  if (/:\/\//.test(url)) {
    _.extend(props, {
      native: '',
      target: '_blank',
      rel: 'noopener',
      onClick: pwa.isStandalone() && function (e) {
        e.preventDefault()
        window.location.href = url
      }
    })
  }
  return h('a', props, children)
}

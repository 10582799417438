import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'
import * as study from '../lib/study'
import lessonListModeSelectView from './lesson-list-mode-select'

export default function lessonsFromWordsActionsView ({ wordsForLessons, user }) {
  return h('div', { class: 'content-actions' },
    h('div', { class: 'study-starter' },
      h('button',
        {
          onClick (e) {
            e.preventDefault()
            const el = e.target.closest('.available-lessons')
            const subMode = e.target.closest('.study-starter').querySelector('[name=lesson_list_mode]').value
            const selectedItems = _.filter(wordsForLessons, (item) =>
              el.querySelector(`input[data-item-id="${item.id}"]`).checked
            )
            study.start(e.target, 'lessons', { ids: _.map(selectedItems, 'id'), subMode })
          }
        },
        h('span', { class: 'emoji-inline tilty-hover' }, '👩‍🏫'),
        'Study'
      ),
      lessonListModeSelectView({ user, showInfoButton: false })
    ),
    h('div', { class: 'bulk-select' },
      h('span', {}, 'Select'),
      h('a', {
        class: 'select-all',
        onClick (e) {
          e.preventDefault()
          render(propUtil.merge({
            wordsForLessons: _.mapValues(wordsForLessons, (word) => {
              if (word.isExcluded) return word
              return _.extend({}, word, { isSelected: true })
            })
          }))
        }
      }, 'all'),
      _.size(wordsForLessons) > user.batchSizeForLessons && h('a', {
        class: 'select-batch',
        onClick (e) {
          e.preventDefault()
          let selectedCount = 0
          _.each(e.target.closest('.available-lessons')
            .querySelectorAll('input[type=checkbox][data-excluded=false]')
          , checkbox => {
            selectedCount++
            checkbox.checked = selectedCount <= user.batchSizeForLessons
          })
          render(propUtil.merge({
            wordsForLessons: _.mapValues(wordsForLessons, (word) => {
              if (word.isExcluded) return word
              const checkbox = e.target.closest('.available-lessons')
                .querySelector(`input[type="checkbox"][data-item-id="${word.id}"]`)
              return _.extend({}, word, {
                isSelected: checkbox.checked
              })
            })
          }))
        }
      }, user.batchSizeForLessons),
      h('a', {
        class: 'deselect-all',
        onClick (e) {
          e.preventDefault()
          render(propUtil.merge({
            wordsForLessons: _.mapValues(wordsForLessons, (word) => {
              return _.extend({}, word, { isSelected: false })
            })
          }))
        }
      }, 'none')
    )
  )
}

import * as _ from '../vendor/lodash'
import itemRecordingLinkView from '../view/item-recording-link'
import itemLinkView from '../view/item-link'
import * as net from './net'
import * as propUtil from './prop-util'
import render from './render'
import * as stringUtil from './string-util'

export function load (id) {
  net.get(`/api/items/${id}`).then(res => {
    res.item.loadedAt = new Date()
    render(propUtil.merge(res))
  })
}

// Arbitrarily declare items stale after a short time (so short b/c of one-off reviews)
export function isStale (item) {
  return new Date().getTime() - item.loadedAt.getTime() > 10000
}

export function percentOf (progress, type) {
  if (progress.attemptCount === 0) return 0
  return Math.round((progress[type] / progress.attemptCount) * 100)
}

const PARTS_OF_SPEECH = {
  no_adjective: 'の‑adjective',
  i_adjective: 'い‑adjective',
  na_adjective: 'な‑adjective',
  suru_verb: 'する‑verb'
}
export function stringifyPartsOfSpeech (item) {
  if (item.type === 'kanji') return 'Kanji'
  if (!item.partsOfSpeech) return 'N/A'
  return item.partsOfSpeech.map(part =>
    PARTS_OF_SPEECH[part] || _.capitalize(part.replace(/_/, ' '))
  ).join(', ')
}

export function stringifyItemReadingLinks (item, options = {}) {
  if ((item.readings.length === 1 && item.readings[0] === item.text) || item.usuallyWrittenUsingKanaAlone) {
    return itemRecordingLinkView({
      recordingUrl: item.recordings[item.readings[0]]
    })
  } else {
    return [
      '(read as ',
      stringUtil.joinWithConjunction(_.map(_.without(item.readings, item.text), (reading) => {
        return [
          itemLinkView(_.extend({}, item, { text: reading }), options),
          itemRecordingLinkView({
            recordingUrl: item.recordings[reading]
          })
        ]
      }), 'or'),
      ')'
    ]
  }
}

export function stringifyTranslationsForItemList (item, type, delimiter) {
  let totalLength = 0
  return _.takeWhile(item[type], (translation, i) => {
    totalLength += translation.length
    return i === 0 || totalLength < 16
  }).join(delimiter)
}

export function sizeClassFor (item) {
  if (item.text.length < 5) {
    return ''
  } else if (item.text.length < 7) {
    return 'medium'
  } else {
    return 'small'
  }
}

export function wellActuallyMeaning (card) {
  if (!card.mostRecentAttempt.alternateMatch) return ''
  return [
    'In fact, ', itemLinkView(_.extend({},
      card.mostRecentAttempt.alternateMatch,
      { text: card.mostRecentAttempt.text }
    )),
    ` means
    ${stringUtil.quoteList(card.mostRecentAttempt.alternateMatch.meanings)}.`
  ]
}

export function saveSpelling (item, spelling, cb) {
  if (!_.trim(spelling)) return
  net.post('/api/spellings', { itemId: item.id, text: spelling }).then((res) => {
    cb(res.spelling)
  })
}

export function deleteSpelling (spelling, item) {
  render(propUtil.merge({ item }))
  net.set(`/api/spellings/${spelling.id}`, {
    itemId: item.id
  }, 'DELETE')
}

export function saveDefinition (item, definition, cb) {
  if (!_.trim(definition)) return
  net.post('/api/definitions', { itemId: item.id, text: definition }).then((res) => {
    cb(res.definition)
  })
}

export function deleteDefinition (definition, item) {
  render(propUtil.merge({ item }))
  net.set(`/api/definitions/${definition.id}`, {
    itemId: item.id
  }, 'DELETE')
}

export function exclude (item, cb) {
  net.post('/api/item_exclusions', { itemId: item.id }).then(() => {
    if (cb) cb(null, true)
  })
}

export function include (item, cb) {
  net.set(`/api/item_exclusions/${item.id}`, {}, 'DELETE').then(() => {
    if (cb) cb(null, false)
  })
}

export function addToReviewsInstantly (item, cb) {
  net.post('/api/learnings/create_all', {
    itemId: item.id
  }).then(res => {
    if (res && res.action === 'skip') return
    cb(null, res)
  })
}

export function undoAddToReviewsInstantly (item, cb) {
  net.patch('/api/learnings/uncreate_all', {
    itemId: item.id
  }).then(res => {
    if (res && res.action === 'skip') return
    cb(null, res)
  })
}

export function filter (items, queryString) {
  if (!queryString) return items

  const queryStrings = stringUtil.expandQueryCharacterSets(queryString.trim().toLowerCase())

  return _.filter(items, item => {
    const texts = [].concat(item.allTexts).concat(item.meanings).concat(item.readings)
    return _.some(texts, text =>
      _.some(queryStrings, queryString =>
        text.toLowerCase().indexOf(queryString) !== -1
      )
    )
  })
}

export function updatePreferredChallengeText ({ learningId, preferredChallengeText }) {
  net.patch('/api/learnings/update_preferred_chalenge_text', {
    learningId,
    preferredChallengeText
  })

  const currentCard = _.find(propUtil.get('reviews'), card =>
    card.learningId === learningId
  )
  if (currentCard) {
    const allTexts = _.union([currentCard.text], currentCard.alternativeMeanings)
    const [text, ...alternativeMeanings] = _.sortBy(allTexts, t =>
      t === preferredChallengeText ? -1 : 1
    )

    propUtil.mergeAndSave({
      reviews: {
        [currentCard.randomUrlPath]: {
          text,
          alternativeMeanings
        }
      }
    })
  }
}

export function newPropsForItemUpdate (itemId, newItemProps, oldProps = propUtil.merge()) {
  const props = _.cloneDeep(oldProps)
  if (props.item?.id === itemId) {
    _.set(props, 'item', _.extend({}, props.item, newItemProps))
  }

  _.each(['reviews', 'lessons'], (mode) => {
    _.each(_.filter(props[mode], (card) => card.item.id === itemId), (card) => {
      _.set(props, `${mode}.${card.randomUrlPath}.item`, _.extend({}, card.item, newItemProps))
    })
  })
  return props
}

import { h } from 'preact'
import * as itemUtil from '../lib/item-util'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'

export default function excludeOrUnexcludeItemView ({ item }) {
  if (!item.isExcluded && (item.potentialLessonTypes.length > 0 || item.unlearnedLessonTypes.length > 0)) {
    return h('div', {},
      h('h2', {}, `Avoid this ${item.type}`),
      h('p', {}, `
        Don't want or need to learn this word? You can prevent it from
        appearing in your lessons by blocking it.
      `),
      h('a', {
        class: 'one-off-item-action',
        onClick (e) {
          e.preventDefault()
          itemUtil.exclude(item, (er, isExcluded) => {
            render(propUtil.merge({
              item: { isExcluded, loadedAt: new Date() }
            }))
          })
        }
      }, h('button', { tabindex: 0 },
        h('span', { class: 'emoji-inline' }, '🙅‍♂️'),
        ` Block this ${item.type}`
      ))
    )
  } else if (item.isExcluded) {
    return h('div', {},
      h('h2', {}, `Unblock this ${item.type}`),
      h('p', {}, `
        This item is currently being blocked from your lessons. You can
        allow it to appear in future lessons by including it.
      `),
      h('a', {
        class: 'one-off-item-action',
        onClick (e) {
          e.preventDefault()
          itemUtil.include(item, (er, isExcluded) => {
            render(propUtil.merge({
              item: { isExcluded, loadedAt: new Date() }
            }))
          })
        }
      }, h('button', { tabindex: 0 },
        h('span', { class: 'emoji-inline' }, '🙆‍♀️'),
        ` Include this ${item.type}`
      ))
    )
  }
}

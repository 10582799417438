import * as _ from '../vendor/lodash'

// Awful name given all the other util files we have now. Break this up
export function delayPromise (ms) {
  if (ms <= 0) {
    return Promise.resolve()
  } else {
    return new Promise((resolve) => {
      window.setTimeout(resolve, ms)
    })
  }
}

export function bucketForSrsStage (srsStage) {
  if (srsStage < 5) {
    return 'apprentice'
  } else if (srsStage < 7) {
    return 'guru'
  } else if (srsStage === 7) {
    return 'master'
  } else if (srsStage === 8) {
    return 'enlightened'
  } else {
    return 'burned'
  }
}

export function durationSince (startedAt) {
  if (typeof startedAt === 'string') startedAt = new Date(startedAt)

  return new Date().getTime() - startedAt.getTime()
}

export function durationBetweenLessInactivity (startedAt, endedAt, inactivityIntervals) {
  if (typeof startedAt === 'string') startedAt = new Date(startedAt)
  if (typeof endedAt === 'string') endedAt = new Date(endedAt)

  const startedAtTime = startedAt.getTime()
  const endedAtTime = endedAt ? endedAt.getTime() : new Date().getTime()
  const inactiveDuration = inactivityIntervals
    ? _.sumBy(_.filter(inactivityIntervals, interval => interval.from > startedAtTime), interval =>
        interval.to - interval.from
      )
    : 0

  return endedAtTime - startedAtTime - inactiveDuration
}

export function durationSinceDescription (duration) {
  const seconds = Number(duration) / 1000
  const d = Math.floor(seconds / 86400)
  const h = Math.floor(seconds % 86400 / 3600)
  const m = Math.floor(seconds % 86400 % 3600 / 60)
  const s = Math.floor(seconds % 86400 % 3600 % 60)

  return [
    d > 0 ? `${d}d ` : '',
    h > 0 || d > 0 ? `${h}h ` : '',
    d === 0 && (m > 0 || h > 0) ? `${m}m ` : '',
    d === 0 && h === 0 && (s > 0 || m > 0) ? `${s}s ` : ''
  ].join('')
}

export function percentOf (numerator, denominator) {
  let value = denominator === 0 ? 0 : Math.round((numerator / denominator) * 100)

  // Humans really hate 0% and 100% when it's not literally 0 or 100
  if (value === 100 && numerator < denominator) {
    value = 99
  } else if (value === 0 && numerator > 0) {
    value = 1
  }

  return `${value}%`
}

export function switchOn (value, rules) {
  return _.find(rules, (rule, name) => name === value) || rules.else
}

export function extendItemInItems (items, itemId, props) {
  return _.map(items, (item) => {
    if (item.id === itemId) {
      return _.extend({}, item, props)
    } else {
      return item
    }
  })
}

export const isKana = (s) =>
  /[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]/.test(s)

export const isHiragana = (s) =>
  /[\u3040-\u309F]/.test(s)

// https://stackoverflow.com/questions/15033196/using-javascript-to-check-whether-a-string-contains-japanese-characters-includi/15034560
export const isJapanese = (s) =>
  /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(s)

export const stripNonJapaneseCharacters = (s) =>
  s.replace(/[\u0000-\u0029]|[\u0040-\u2FFF]|[\u3100-\u33FF]|[\u4DC0-\u4DFF]|[\u9FB0-\uFEFF]|[\uFFF0-\uFFFF]/g, '') // eslint-disable-line

export const splay = (array) =>
  _.transform(array, (result, val) => {
    result[val.id] = val
  }, {})

export const looksLikeDate = (s) =>
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.test(s)

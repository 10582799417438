import { h } from 'preact'

// Remember, you wrote this!!
//  npm i -g html-to-preact-h
export default function shareButton () {
  return h('svg', {
    class: 'send-button',
    width: '40',
    height: '40',
    viewBox: '0 0 40 40',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg'
  },
  h('path', {
    'fill-rule': 'evenodd',
    'clip-rule': 'evenodd',
    d: 'M16 13H11.5C9.84315 13 8.5 14.3431 8.5 16V34C8.5 35.6569 9.84315 37 11.5 37H27.5C29.1569 37 30.5 35.6569 30.5 34V16C30.5 14.3431 29.1569 13 27.5 13H24V15H27.5C28.0523 15 28.5 15.4477 28.5 16V34C28.5 34.5523 28.0523 35 27.5 35H11.5C10.9477 35 10.5 34.5523 10.5 34V16C10.5 15.4477 10.9477 15 11.5 15H16V13Z',
    fill: '#4F4F4F'
  }),
  h('path', {
    d: 'M20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L12.9289 9.65685C12.5384 10.0474 12.5384 10.6805 12.9289 11.0711C13.3195 11.4616 13.9526 11.4616 14.3431 11.0711L20 5.41421L25.6569 11.0711C26.0474 11.4616 26.6805 11.4616 27.0711 11.0711C27.4616 10.6805 27.4616 10.0474 27.0711 9.65685L20.7071 3.29289ZM21 22V4H19V22H21Z',
    fill: '#4F4F4F'
  })
  )
}

import * as _ from '../vendor/lodash'
import * as net from './net'

export const dueToBeReloaded = (progress) =>
  !_.get(progress, 'fullProgressLoaded')

export function reloadUnlessAlreadyDueToBeReloaded (progress) {
  if (!dueToBeReloaded(progress)) {
    _.defer(() => load())
  }
}

export function load () {
  net.update('/api/home')
}

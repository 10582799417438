import { h } from 'preact'
import images from '../images'
import * as login from '../lib/login'
import formView from './form'

export default (props) =>
  h('div', { id: 'login' },
    h('img', { src: images['login-header.svg'], alt: 'KameSame title' }),
    h('div', { class: 'wrap' },
      formView({ onSubmit: login.attempt },
        h('h2', {}, 'Log in'),
        h('div', {},
          h('label', { for: 'email' }, 'E-mail'),
          h('input', { id: 'email', name: 'email', type: 'email', placeholder: 'E-mail', required: true, autofocus: true })
        ),
        h('div', {},
          h('label', { for: 'password' }, 'Password'),
          h('input', { id: 'password', name: 'password', type: 'password', placeholder: 'Password', required: true })
        ),
        h('div', { class: 'buttons' },
          h('button', { type: 'submit' }, 'Log in')
        )
      ),
      h('p', { class: 'center' },
        'In order to track your progress, KameSame requires you to log in.'
      ), h('p', { class: 'center' },
        'New here? ',
        h('a', { href: 'https://community.wanikani.com/t/introducing-kamesame-a-new-reverse-wanikani-web-app/31319' }, 'What is this'), '?',
        h('br'),
        'Ready to start? ',
        h('a', { href: '/app/sign_up' }, 'Sign up'), '!',
        h('br'),
        ' Forgot your password? ',
        h('a', { href: '/app/reset_password' }, 'Reset it'), '!'
      )
    )
  )

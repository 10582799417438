import { h } from 'preact'
import { Match } from 'preact-router/match'
import * as route from '../lib/route'
import navBarView from './nav-bar'

export default function nav ({ user, progress, reviewsStatus, lessonsStatus }) {
  if (!user) return
  return h(Match, {
    path: '/'
  }, ({ matches, path, url }) => {
    if (!route.matches(route.PATHS.navFree, path)) {
      return h('div', { id: 'nav_container' },
        navBarView({ user, progress, reviewsStatus, lessonsStatus, thisIsTheFakeOne: true }),
        navBarView({ user, progress, reviewsStatus, lessonsStatus, thisIsTheFakeOne: false })
      )
    }
  })
}

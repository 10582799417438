import { h } from 'preact'
import itemConjugationFactView from './item-conjugation-fact'

export default function itemConjugationsView ({ item }) {
  if (!item.conjugations) return

  return [h('h2', {}, 'Conjugated forms'),
    h('div', { class: 'facts' },
      itemConjugationFactView(item, 'present.plain', 'Present'),
      itemConjugationFactView(item, 'present.polite', '(Polite) Present'),
      itemConjugationFactView(item, 'negative.plain', 'Negative'),
      itemConjugationFactView(item, 'negative.polite', '(Polite) Negative'),
      itemConjugationFactView(item, 'past.plain', 'Past'),
      itemConjugationFactView(item, 'past.polite', '(Polite) Past'),
      itemConjugationFactView(item, 'negativePast.plain', 'Negative Past'),
      itemConjugationFactView(item, 'negativePast.polite', '(Polite) Negative Past'),
      itemConjugationFactView(item, 'volitional.plain', 'Volitional'),
      itemConjugationFactView(item, 'volitional.polite', '(Polite) Volitional'),
      itemConjugationFactView(item, 'te', 'Te-form'),
      itemConjugationFactView(item, 'imperative', 'Imperative'),
      itemConjugationFactView(item, 'passive', 'Passive'),
      itemConjugationFactView(item, 'causative', 'Causative'),
      itemConjugationFactView(item, 'hypothetical', 'Hypothetical'),
      itemConjugationFactView(item, 'potential', 'Potential')
    )
  ]
}

import { h } from 'preact'
import copyButton from '../icons/copy-button'
import shareButton from '../icons/share-button'

export default function itemActionsView ({ item, itemId }) {
  return h('div', { class: 'item-actions' },
    window.navigator.clipboard && h('a', {
      title: `Copy '${item.text}'`,
      onClick (e) {
        e.preventDefault()
        copyButton.clickHandler(e)
        window.navigator.clipboard.writeText(item.text)
      }
    }, copyButton()),
    window.navigator.share && h('a', {
      title: `Share '${item.text}'`,
      onClick (e) {
        e.preventDefault()
        window.navigator.share({
          title: item.text,
          text: `${item.text} - ${item.meanings[0]}`,
          url: `//${window.location.hostname}/app/items/${item.id}`
        })
      }
    }, shareButton())

  )
}

import * as _ from '../vendor/lodash'
import * as net from './net'
import render from './render'
import * as route from './route'
import * as propUtil from './prop-util'

export function handleSubmit (e) {
  e.preventDefault()
  const query = e.target.querySelector('input[name=search]').value
  route.redirect(`/app/search/${encodeURIComponent(query)}`)
}

export function handleSearch (e) {
  e.preventDefault()
  if (e.target.value === '') {
    route.redirect('/app/search')
  }
}

export function execute (query) {
  if (!query) return route.redirect('/app/search')

  // The loading screen just won't display otherwise, even with forceUpdate
  _.defer(() => render(propUtil.merge({ searches: { [query]: 'loading' } })))

  net.get(`/api/item_searches/${encodeURIComponent(query)}`).then(res => {
    render(propUtil.merge({
      searches: {
        [query]: res
      }
    }))
  })
}

import { h } from 'preact'

export default function japanFriendlyNumberInputView (name, defaultValue) {
  return h('input', {
    id: name,
    name: name,
    value: defaultValue,
    type: 'text',
    pattern: '[0-9]*',
    required: true,
    autocomplete: 'off',
    onChange (e) {
      const input = e.target
      input.value = input.value.replace(/[\uFF10-\uFF19]/g, c =>
        String.fromCharCode(c.charCodeAt(0) - 0xfee0)
      )
      input.setCustomValidity(/^\d+$/.test(input.value)
        ? ''
        : 'Please input a number'
      )
    }
  })
}

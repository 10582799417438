import { h } from 'preact'
import icons from '../icons'
import navLessonsEffectView from './nav-lessons-effect'

export default function lessonsIcon ({ progress }) {
  return h('div', {
    class: 'svg',
    ref (c) { if (c) navLessonsEffectView(c, progress) }
  }, icons.lessons)
}

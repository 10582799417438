import * as _ from '../vendor/lodash'
import * as propUtil from './prop-util'
import render from './render'

export const FORWARDING_RULES = [
  { node: document, event: 'keydown', test: e => e.keyCode === 13, delegate: '.handle-enter' }
]

export function initEventForwarding (rules) {
  _.each(rules, ({ node, event, test, delegate }) => {
    node.addEventListener(event, (e) => {
      if (e.target.classList.contains('override-global-events')) return
      _.each(document.querySelectorAll(delegate), recipient => {
        if (!test || test(e)) {
          recipient.dispatchEvent(new window.Event('Event'))
        }
      })
    })
  })
}

export function onClickMergeThis (props) {
  return (e) => {
    e.preventDefault()
    render(propUtil.merge(props))
  }
}

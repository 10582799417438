import * as humps from 'humps'
import { useState, useEffect } from 'preact/hooks'
import * as _ from '../vendor/lodash'
import * as monitor from './monitor'
import * as notify from './notify'
import * as propUtil from './prop-util'
import render from './render'
import * as route from './route'
import * as util from './util'
import * as version from './version'
import * as windowUtil from './window-util'

let repeatFailureCount = 0

export function fetch (path, props) {
  if (repeatFailureCount === 0) {
    return window.fetch(path, props)
  } else {
    return util.delayPromise(2 ** repeatFailureCount * 10).then(() =>
      window.fetch(path, props)
    )
  }
}

export function getRaw (path, props = {}) {
  return fetch(path, _.extend({
    credentials: 'same-origin'
  }, props))
}

export const get = (path, options = {}) =>
  set(path, {}, 'GET', options)

export const set = (path, params, method = 'POST', options = {}) =>
  getRaw(path, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: method === 'GET' ? null : JSON.stringify(humps.decamelizeKeys(params))
  })
    .then(res => {
      version.handleVersionDiscrepanciesInXhr(res)
      return res.text().then(text => {
        const body = text ? JSON.parse(text) : {}
        return processResponse(body, options)
      })
    })
    .catch(err => {
      handleError(err, options)
    })

export function handleError (err, options) {
  if (err.name === 'AbortError' || options.ignoreFailure) {
    return new NullPromise(() => {})
  } else {
    monitor.client().notifyException(err)
    return processResponse({
      fatal_error_message: 'Something went wrong! Contact howdy@kamesame.com for help.'
    })
  }
}

class NullPromise {
  then (cb) {}

  catch (cb) {}

  finally (cb) {}
}

export function processResponse (json, options = {}) {
  const error = extractErrors(json)
  if (!error) {
    repeatFailureCount = 0
    return options.doNotCamelize ? json : humps.camelizeKeys(json)
  } else {
    repeatFailureCount++
    return new NullPromise(() => {})
  }
}

export function extractErrors (json) {
  let message, bail
  if (json.fatal_error_message) {
    message = json.fatal_error_message
    bail = true
    delete json.fatal_error_message
  } else if (json.error_message) {
    message = json.error_message
    bail = false
    delete json.error_message
  }

  if (json.fatal_error_redirect) {
    route.redirect(json.fatal_error_redirect)
  }
  if (message) {
    render(notify.append(message))
    windowUtil.scrollToTop()
  }
  if (bail) {
    return new Error(message)
  }
}

export const post = (path, params) =>
  set(path, params, 'POST')

export const patch = (path, params) =>
  set(path, params, 'PATCH')

export const update = (path) =>
  get(path).then(res => {
    render(propUtil.merge(res))
  })

export function objectToQueryParams (object) {
  // TODO replace with URLSearchParams
  return _.map(object, (v, k) => {
    if (_.isArray(v)) {
      return _.map(v, el => `${k}[]=${encodeURIComponent(el)}`).join('&')
    } else {
      return `${k}=${encodeURIComponent(v)}`
    }
  }).join('&')
}

export function useFetch (path, { dependsOn = [] } = {}) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetcher = async () => {
      const response = await set(path, {}, 'GET', {})
      setData(response)
      setLoading(false)
    }

    fetcher()
  }, dependsOn)

  return { data, loading }
}

import * as _ from '../vendor/lodash'
import { h } from 'preact'
import notificationBarView from './notification-bar'

export default function notificationAlternateView ({ infoMessages, errorMessages }) {
  return h('div', {
    id: 'notification_alternative',
    ref (c) {
      _.defer(() => {
        if (c && document.querySelector('#notification')) {
          document.querySelector('#notification').remove()
        }
      })
    }
  },
  notificationBarView({
    infoMessages,
    errorMessages,
    isNotificationAlternate: true
  })
  )
}

import * as warez from 'warez'
import * as basicInfo from './basic-info'
import * as study from './study'
import * as propUtil from './prop-util'

const IDLE_TIME = 60 * 1000
let lastActiveAt = new Date().getTime()

export function listenForUserActivity () {
  [
    'mousedown', 'pointermove', 'mousemove', 'keypress', 'scroll', 'touchstart'
  ].forEach((name) =>
    document.addEventListener(name, () => {
      const newActiveAtTime = new Date().getTime()
      if (userWasInactive(newActiveAtTime)) {
        storeInactivity(newActiveAtTime)
        wakeup()
      }
      lastActiveAt = newActiveAtTime
    }, true)
  )

  document.addEventListener('pointerup', (e) => {
    if (e.pointerType === 'pen' && e.target.nodeName === 'A') {
      e.target.click()
    }
  })

  warez.start()
  warez.onDeviceChange(e => {
    document.querySelectorAll('[data-warez]').forEach(node => {
      node.setAttribute('data-warez', e.current)
    })
  })
}

function wakeup () {
  basicInfo.load()
  study.clearOutdatedCards()
}

function userWasInactive (newActiveAtTime) {
  return newActiveAtTime - lastActiveAt > IDLE_TIME
}

function storeInactivity (newActiveAtTime) {
  const intervals = propUtil.get('userSessionInactivityIntervals') || []
  intervals.push({ from: lastActiveAt, to: newActiveAtTime })
  propUtil.mergeAndSave({ userSessionInactivityIntervals: intervals })
}

import { h } from 'preact'
import { Link } from 'preact-router/match'
import * as home from '../lib/home'
import * as xp from '../lib/xp'
import progressIconView from './progress-icon'

export default function navHomeView ({ progress }) {
  return h(Link, {
    href: '/app',
    activeClassName: 'active',
    title: `Level ${progress.level} of ${xp.MAX_LEVEL}`,
    onClick (e) { home.reloadUnlessAlreadyDueToBeReloaded(progress) }
  },
  progressIconView({ progress }),
  h('div', { class: 'label' }, h('span', {}, 'Progress'))
  )
}

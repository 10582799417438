import * as _ from '../vendor/lodash'
import * as route from '../lib/route'

// Useful when a page gets refreshed at a URL that requires unpersisted state
// and it's transient enough that it can't be fetched and reconstructed from the
// server
export default function bailWithout (...things) {
  if (_.some(things, thing => thing == null)) {
    route.redirect('/app', true)
    return true
  }
}

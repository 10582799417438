import * as _ from '../vendor/lodash'
import * as auth from './auth'
import * as net from './net'
import * as propUtil from './prop-util'
import render from './render'
import * as store from './store'
import * as windowUtil from './window-util'

export function saveChanges (e) {
  e.preventDefault()
  const form = e.target.closest('#account')
  net.patch(`/api/users/${auth.getUserId()}`, {
    name: form.querySelector('input[name="name"]').value,
    email: form.querySelector('input[name="email"]').value,
    batchSizeForLessons: parseInt(form.querySelector('input#batch_size_for_lessons').value, 10),
    studyIncludedLessonTypes: _.map(
      form.querySelectorAll('input[name^=study_included_lesson_types]:checked'),
      'value'
    ).sort(),
    studyOneLessonTypeAtATime: form.querySelector('input#study_one_lesson_type_at_a_time').checked,
    hideSuperfluousEnglish: form.querySelector('input#hide_superfluous_english').checked,
    hideRewardNotifications: form.querySelector('input#hide_reward_notifications').checked,
    skipCorrectAnswers: form.querySelector('input#skip_correct_answers').checked,
    skipRecognitionLessonsForWanikaniItems: form.querySelector('input#skip_recognition_lessons_for_wanikani_items').checked,
    autoplayAudio: form.querySelector('input#autoplay_audio').checked,
    undoCreditLimit: form.querySelector('select#undo_credit_limit').value,
    deferDuplicateReviews: form.querySelector('input#defer_duplicate_reviews').checked
  }).then((res) => {
    render(propUtil.merge(_.extend({}, res, {
      oneTimeRenderCallback () {
        windowUtil.scrollToTop()
      }
    })))
  })
}

export function changePassword (e) {
  e.preventDefault()
  const form = e.target.closest('#account')
  const currentPassword = form.querySelector('input[name="current_password"]')
  const newPassword = form.querySelector('input[name="new_password"]')
  net.patch(`/api/users/${auth.getUserId()}/password`, {
    currentPassword: currentPassword.value,
    newPassword: newPassword.value
  }).then((res) => {
    render(propUtil.merge(res))
    windowUtil.scrollToTop()
    currentPassword.value = ''
    newPassword.value = ''
  })
}

export function updateWanikaniApiKey (user) {
  return (e) => {
    e.preventDefault()
    const form = e.target.closest('#account')
    const wkApiKeyInput = form.querySelector('input[name="wanikani_v2_api_key"]')
    const wkApiKey = wkApiKeyInput.value

    // Give users a chance to bail before deleting their WK account
    if (_.isEmpty(wkApiKey) && user.linkedWanikaniAccount && !window.confirm(`
      Are you sure you want to unlink your KameSame & WaniKani account? Once
      unlinked, you will no longer be able to create new lessons based on your
      WaniKani progress.
    `.replace(/\s+/g, ' '))) {
      wkApiKeyInput.value = user.wanikani.apiKey
      return
    }

    net.patch(`/api/users/${auth.getUserId()}/wanikani_api_key`, {
      wanikaniV2ApiKey: wkApiKey
    }).then((res) => {
      render(propUtil.merge(res))
      windowUtil.scrollToTop()
    })
  }
}

export function resyncWanikaniData (e) {
  e.preventDefault()
  if (window.confirm('To ease the burden KameSame places on WaniKani\'s API servers, your WaniKani data is refrefreshed every 24 hours. If you need to refresh your WaniKani data sooner, confirming this dialog will schedule it to be updated right away')) {
    net.patch(`/api/users/${auth.getUserId()}/resync_wanikani_data`).then((res) => {
      render(propUtil.merge(res))
    })
  }
}

export function clearLocalData (e) {
  e.preventDefault()
  if (window.confirm(`KameSame stores some data locally in your browser (for
    example, the items you have remaining in a review). If you're experiencing
    issues, confirming this dialog will reset all locally cached data.`.replace(/\s+/g, ' '))) {
    store.reset()
    window.location.reload()
  }
}

export function resetExcludedItems (e) {
  e.preventDefault()
  if (
    window.confirm('This will unblock any kanji & vocabulary that you\'ve previously blocked from your lessons. Proceed?')
  ) {
    net.set('/api/item_exclusions/all', {}, 'DELETE').then((res) => {
      render(propUtil.merge(res))
      windowUtil.scrollToTop()
    })
  }
}

export function deleteAllKameSameProgress (e) {
  e.preventDefault()
  if (
    window.confirm('Are you sure? This will unlearn every lesson and review you have ever completed in KameSame.') &&
    window.confirm('Are you REALLY sure? This action cannot be undone. You\'ll be starting over from scratch.')
  ) {
    store.reset()
    net.patch(`/api/users/${auth.getUserId()}/delete_all_kamesame_progress`).then((res) => {
      window.location.reload()
    })
  }
}

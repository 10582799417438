import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as stringUtil from '../lib/string-util'
import * as study from '../lib/study'
import bailWithoutView from './bail-without'
import lessonsByBucketView from './lessons-by-bucket'
import surveySummaryView from './survey-summary'
import placementSummaryView from './placement-summary'

export default function lessonsSummaryView ({ lessons, lessonsSubMode }) {
  if (bailWithoutView(lessons)) return
  if (lessonsSubMode === 'survey') return surveySummaryView({ lessons })
  if (lessonsSubMode === 'placement') return placementSummaryView({ lessons })

  const cards = _.sortBy(lessons, 'item.text')
  const completed = _.filter(cards, card => card.complete && !card.skipped)
  const incomplete = _.filter(cards, card => !card.complete)
  const skipped = _.filter(cards, card => card.complete && card.skipped)
  const completeCount = _.size(completed)
  const completeItemCount = _.size(_.uniqBy(completed, 'item.id'))
  const multipleLessonTypes = _.uniqBy(cards, (card) => study.questionType(card)).length > 1

  return h('div', { id: 'summary' },
    h('h2', {}, 'Lesson Summary'),
    h(Helmet, { title: 'Lesson Summary' }),
    h('p', {},
      completed.length > 0
        ? `Congratulations, you completed ${completeCount}
          ${stringUtil.plural(completeCount, 'lesson')}${completeCount !== completeItemCount ? ` for ${completeItemCount} ${stringUtil.plural(completeItemCount, 'item')}` : ''}!
          ${completeCount > 1 ? 'They' : 'It'} will appear in your review queue
          soon!`
        : _.size(cards) > 0
          ? `You didn't complete any lessons this time, but there's always next
          time! 🤷‍♂️`
          : 'You don\'t have any items available to study at that rank! 🙈'
    ),
    completed.length > 0
      ? [
          h('h3', {}, 'Completed'),
          lessonsByBucketView(completed, multipleLessonTypes)
        ]
      : null,
    incomplete.length > 0
      ? [
          h('h3', {}, 'Incomplete'),
          lessonsByBucketView(incomplete, multipleLessonTypes)
        ]
      : null,
    skipped.length > 0
      ? [
          h('h3', {}, 'Skipped'),
          lessonsByBucketView(skipped, multipleLessonTypes)
        ]
      : null
  )
}

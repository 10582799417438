import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as route from '../lib/route'
import itemListFancyView from './item-list-fancy'
import lessonsFromWordsActionsView from './lessons-from-words-actions'

export default function lessonsFromWordsView ({ wordsForLessons, user, ...props }) {
  if (_.isEmpty(wordsForLessons)) {
    return route.fallback('/app/lessons', `
      No available lessons found in your content 🤷‍♂️
    `, 'info')
  }

  return h('div', { id: 'lessonsFromWords' },
    h(Helmet, { title: 'Lessons' }),
    h('h2', {}, 'Lessons found in content'),
    h('p', {}, `
      ${_.size(wordsForLessons)} words found. ☑️  the ones you want to study. To
      avoid an item in the
      future, you can block it from lessons with 🙅‍♂️ and re-include it
      with 🙆‍♀️
    `),
    h('div', { class: 'available-lessons' },
      lessonsFromWordsActionsView({ wordsForLessons, user }),
      itemListFancyView({
        items: _.sortBy(_.values(wordsForLessons), 'indexInContent'),
        baseUrl: '/app/lessons/from-words',
        disableFilter: true,
        enableListTypes: ['potential-lessons'],
        disableSortOptions: ['type'],
        ...props
      }),
      lessonsFromWordsActionsView({ wordsForLessons, user })
    )
  )
}

import { h } from 'preact'
import * as review from '../lib/review'

export default function pauseAndUnpauseReviewsView ({ user, unpauseOnly }) {
  if (!user.reviewsPausedAt && unpauseOnly) return

  return h('div', { class: 'pause-reviews', tabindex: 0 },
    !user.reviewsPausedAt
      ? [
          h('h2', {}, 'Take a break'),
          h('p', {}, `
            Going on vacation? Taking a prolonged break from your studies? If coming
            back to a review queue of hundreds of items overwhelms you to
            the point of discouragement, then you may want to consider pausing your
            reviews.
          `),
          h('button', {
            class: 'l-margin-top',
            onClick: review.pauseReviews
          },
          h('span', { class: 'emoji-inline tilty-hover' }, '🏖'),
          ' Pause reviews'
          ),
          h('p', {}, `
            Once paused, no items will appear in your reviews. When
            unpaused, your reviews will appear in the same order as and schedule
            as they otherwise would have.
          `)
        ]
      : [
          h('h2', {}, 'Get back to work'),
          h('p', {}, `
            Back from vacation? Ready to buckle down and return to your studies?
            Unpause your reviews and get ready to 頑張って！
          `),
          h('button', {
            class: 'l-margin-top',
            onClick: review.unpauseReviews
          },
          h('span', { class: 'emoji-inline tilty-hover' }, '📚'),
          ' Unpause reviews'
          ),
          h('p', {}, `
            Once unpaused, any items that were ready for review at the time you
            paused will immediately appear in your review queue. Other items
            will become ready for review at the same pace they normally would.
          `)
        ]
  )
}

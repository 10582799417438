import { h } from 'preact'
import * as xp from '../lib/xp'

export default function levelBar ({ level, priorXp, xpEarned, totalXp }) {
  if (level === xp.MAX_LEVEL) return
  if (priorXp == null) { // then we're not animating, so just == it out
    priorXp = totalXp
    xpEarned = 0
  }

  const progress = xp.progressToNextLevel(level)
  const xpEarnedInThisBar = xp.xpEarnedTowardsNextLevel(progress, priorXp, xpEarned)

  return h('div', { class: 'level-bar' },
    h('div', { class: 'stupid-inner-wrap' },
      h('div',
        {
          class: 'bar',
          title: `${Math.max(0, progress.xpForNextLevel - totalXp)} XP until Level ${progress.nextLevel}`
        },
        h('div', {
          class: 'progress prior',
          style: `width: ${xp.levelCompletionPercent(level, priorXp)}%`,
          title: `${Math.max(priorXp - progress.xpForLevel, 0)} XP earned prior to this review`
        }),
        h('div', {
          class: 'progress earned',
          style: `width: ${xp.progressMadeToNextLevelPercent(progress, xpEarnedInThisBar)}%`,
          title: `${xpEarnedInThisBar} XP earned toward level ${progress.nextLevel} in this review`
        })
      ),
      h('div', { class: 'labels' },
        h('span', { title: `Level ${level}: ${progress.xpForLevel} XP` }, progress.level),
        h('span', { title: `Level ${progress.nextLevel}: ${progress.xpForNextLevel} XP` }, progress.nextLevel)
      )
    )
  )
}

import * as _ from '../vendor/lodash'
import XP from '../xp.json'

export const LESSONS_PER_STAR = 50
export const MAX_LEVEL = _.max(_.map(Object.keys(XP.levels), Number))

export const levelFor = (xp) =>
  Number(_.maxBy(Object.entries(XP.levels), ([level, requirement]) =>
    xp >= requirement ? Number(level) : -1
  )[0])

export const xpFor = (level) =>
  XP.levels[level] || 0

export const levelAfter = (level) =>
  level >= MAX_LEVEL ? MAX_LEVEL : level + 1

export function progressToNextLevel (level) {
  return _.tap({
    level,
    nextLevel: levelAfter(level),
    xpForLevel: xpFor(level),
    xpForNextLevel: xpFor(levelAfter(level))
  }, o => {
    o.xpBetweenLevels = o.xpForNextLevel - o.xpForLevel
  })
}

export function xpEarnedTowardsNextLevel (progress, priorXp, xpEarned) {
  if (progress.level >= MAX_LEVEL) return 0

  if (priorXp > progress.xpForNextLevel) {
    // bar was already filled without newly earned xp
    return 0
  } else if (priorXp + xpEarned < progress.xpForLevel) {
    // total xp doesn't even start filling this bar
    return 0
  } else {
    // earned xp will fill some amount of the current bar
    const earnedXpAlreadyAllocatedToPreviousLevels = Math.max(progress.xpForLevel - priorXp, 0)
    const earnedXpAvailableToBeAllocatedToThisLevel = Math.max(xpEarned - earnedXpAlreadyAllocatedToPreviousLevels, 0)

    const previouslyEarnedXpInLevel = Math.max(priorXp - progress.xpForLevel, 0)
    const xpNeededToLevelUp = progress.xpBetweenLevels - previouslyEarnedXpInLevel

    return Math.min(earnedXpAvailableToBeAllocatedToThisLevel, xpNeededToLevelUp)
  }
}

export function levelCompletionPercent (level, xp) {
  if (level >= MAX_LEVEL) return 0
  const progress = progressToNextLevel(level)
  const xpEarnedSinceLevel = xp - progress.xpForLevel

  if (xp <= progress.xpForLevel) {
    return 0
  } else if (_.inRange(xp, progress.xpForLevel, progress.xpForNextLevel)) {
    return 100 * (xpEarnedSinceLevel / progress.xpBetweenLevels)
  } else if (xp >= progress.xpForNextLevel) {
    return 100
  }
}

export function progressMadeToNextLevelPercent (progress, xpEarnedInThisBar) {
  if (progress.level >= MAX_LEVEL) return 0
  return 100 * xpEarnedInThisBar / progress.xpBetweenLevels
}

import * as _ from '../vendor/lodash'
import hotkeys from 'hotkeys-js'
import * as hotkeyActions from './hotkey-actions'
import * as route from './route'
import * as shame from './shame'

export const setScope = hotkeys.setScope

export const bindings = _.memoize(() => {
  const modifier = shame.isApple() ? 'ctrl' : 'alt'
  const command = shame.isApple() ? 'command' : 'ctrl'
  return [
    {
      name: 'Shortcuts help',
      scope: 'all',
      filter: true,
      bind: '/',
      action: hotkeyActions.showHelp
    },
    {
      name: 'Blur focus',
      scope: 'all',
      filter: false,
      bind: 'escape',
      action: hotkeyActions.blurFocusAndDismissStuff
    },
    {
      name: 'Submit form',
      scope: 'all',
      filter: false,
      bind: `${command}+enter`,
      action () {
        const form = document.activeElement?.closest('form')
        if (!form) return

        // This is because calling submit() on a form skips onSubmit which we use everywhere
        const button = form.ownerDocument.createElement('input')
        button.style.display = 'none'
        button.type = 'submit'
        form.appendChild(button).click()
        form.removeChild(button)
      }
    },
    {
      name: 'Progress',
      scope: 'menus',
      filter: true,
      bind: 'p',
      action () { route.redirect('/app') }
    },
    {
      name: 'Lessons',
      scope: 'menus',
      filter: true,
      bind: 'l',
      action () { route.redirect('/app/lessons') }
    },
    {
      name: 'Reviews',
      scope: 'menus',
      filter: true,
      bind: 'r',
      action () { route.redirect('/app/reviews') }
    },
    {
      name: 'Search',
      scope: 'menus',
      filter: true,
      bind: 's',
      action () { route.redirect('/app/search') }
    },
    {
      name: 'Account',
      scope: 'menus',
      filter: true,
      bind: 'a',
      action () { route.redirect('/app/account') }
    },
    {
      name: 'Next selection',
      scope: 'menus',
      filter: true,
      bind: 'j',
      action: hotkeyActions.nextItem
    },
    {
      name: 'Previous selection',
      scope: 'menus',
      filter: true,
      bind: 'k',
      action: hotkeyActions.previousItem
    },
    {
      name: 'Fail item',
      scope: 'study',
      filter: false,
      bind: `${modifier}+f`,
      action: hotkeyActions.clickCardAction('.fail')
    },
    {
      name: 'Undo review',
      scope: 'study',
      filter: false,
      bind: `${modifier}+z`,
      action: hotkeyActions.clickCardAction('.undo-review')
    },
    {
      name: 'Skip item',
      scope: 'study',
      filter: false,
      bind: `${modifier}+s`,
      action: hotkeyActions.clickCardAction('.skip')
    },
    {
      name: 'Learn item',
      scope: 'study',
      filter: false,
      bind: `${modifier}+l`,
      action: hotkeyActions.clickCardAction('.learn')
    },
    {
      name: 'Burn item',
      scope: 'study',
      filter: false,
      bind: `${modifier}+b`,
      action: hotkeyActions.clickCardAction('.burn')
    },
    {
      name: 'Unlearn/Unburn item',
      scope: 'study',
      filter: false,
      bind: `${modifier}+u`,
      action: hotkeyActions.clickCardAction('.unlearn')
    },
    {
      name: 'Block item',
      scope: 'study',
      filter: false,
      bind: `${modifier}+k`,
      action: hotkeyActions.clickCardAction('.block')
    },
    {
      name: 'Copy item [Result]',
      scope: 'study',
      filter: false,
      bind: `${modifier}+c`,
      action: () => document.querySelector('#copyButton')?.click()
    },
    {
      name: 'Play reading audio',
      scope: 'study',
      filter: false,
      bind: `${modifier}+p`,
      action: () => {
        const recording = _.minBy(document.querySelectorAll('a.recording'), r =>
          r.attributes['data-playcount'] || 0
        )

        if (recording) {
          recording.click()
          const plays = Number(recording.attributes['data-playcount'] || 0) + 1
          recording.attributes['data-playcount'] = plays
        }
      }
    },
    {
      name: 'Deobfuscate item',
      scope: 'study',
      filter: false,
      bind: `${modifier}+o`,
      action: () =>
        document.querySelector('a.obfuscated')?.click()

    },
    {
      name: 'Go back',
      scope: 'study',
      filter: false,
      bind: shame.isApple() ? 'command+left' : 'alt+left',
      action () {
        window.history.back()
      }
    },
    {
      name: 'Go forward',
      scope: 'study',
      filter: false,
      bind: shame.isApple() ? 'command+right' : 'alt+right',
      action () {
        window.history.forward()
      }
    },
    {
      name: 'Exit study mode',
      scope: 'study',
      filter: false,
      bind: `${modifier}+q`,
      action: hotkeyActions.exitStudyMode
    }
  ]
})

export function init () {
  const binds = bindings()

  const ogHotkeysFilter = hotkeys.filter
  hotkeys.filter = (e) => true

  _.each(_.groupBy(binds, 'scope'), (binds, scope) => {
    hotkeys(_.map(binds, 'bind').join(', '), { scope }, (e, handler) => {
      const match = _.find(binds, config => config.bind === handler.key)
      if (!match || (!ogHotkeysFilter(e) && match.filter)) return
      match.action()
      return false
    })
  })
}

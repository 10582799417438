import { h } from 'preact'
import * as eventUtil from '../lib/event-util'
import listProgressAttrsView from './list-progress-attrs'
import listProgressRatioView from './list-progress-ratio'

const NULL_LIST_PROGRESS = {
  listId: -1,
  listCode: 'loading',
  totalItems: 0,
  itemsStarted: 0,
  totalLessons: 0,
  startedLessons: 0,
  unstartedLessons: 0,
  excludedLessons: 0,
  apprentice: 0,
  guru: 0,
  master: 0,
  enlightened: 0,
  burned: 0
}

export default function listProgress (listProgress, { showListDetails }) {
  const isLoading = !listProgress
  listProgress = isLoading ? NULL_LIST_PROGRESS : listProgress

  return h('div', { class: 'list-progress' },
    h('div', { class: `list-progress-bar ${isLoading ? 'loading' : ''}` },
      !isLoading && h('div', { class: 'progress-wrap' },
        h('div', listProgressAttrsView(listProgress, 'excludedLessons')),
        h('div', listProgressAttrsView(listProgress, 'apprentice')),
        h('div', listProgressAttrsView(listProgress, 'guru')),
        h('div', listProgressAttrsView(listProgress, 'master')),
        h('div', listProgressAttrsView(listProgress, 'enlightened')),
        h('div', listProgressAttrsView(listProgress, 'burned')),
        h('div', listProgressAttrsView(listProgress, 'unstartedLessons'))
      )
    ),
    h('div', { class: 'details' },
      showListDetails
        ? h('div', { class: 'progress-description' },
            h('div', { class: 'statistic-wrap' },
              listProgressRatioView(listProgress, 'apprentice'),
              listProgressRatioView(listProgress, 'guru'),
              listProgressRatioView(listProgress, 'master'),
              listProgressRatioView(listProgress, 'enlightened'),
              listProgressRatioView(listProgress, 'burned')
            ),
            h('div', { class: 'narrative' },
              h('h4', {}, `Studying ${listProgress.startedLessons} of ${listProgress.totalLessons} lessons `),
              h('h5', {}, `(Including ${listProgress.itemsStarted} of ${listProgress.totalItems} items)`),
              listProgress.excludedLessons > 0
                ? h(
                    'h6', {},
                  `(And ${listProgress.excludedLessons} `,
                  h('a', { href: '/app/blocked_items' }, 'blocked'),
                  ' lessons)'
                  )
                : null,
              h('h7', {},
                h('a', {
                  onClick: eventUtil.onClickMergeThis({
                    showListDetails: false
                  })
                }, 'Hide details')
              )
            )
          )
        : h('div', { class: 'actions' },
          isLoading
            ? h('span', { class: 'loading' }, 'Loading details…')
            : h('a', {
              onClick: eventUtil.onClickMergeThis({
                showListDetails: true
              })
            }, 'Show details')
        )
    )
  )
}

import * as xp from '../lib/xp'
import dynamicOpacityForView from './dynamic-opacity-for'

export default function navLessonsEffect (node, progress) {
  const weeklyStarEarned = progress.lessonsCompletedThisWeek >=
    xp.LESSONS_PER_STAR

  dynamicOpacityForView(
    node.querySelectorAll('#starpoints path'),
    progress.lessonsCompletedThisWeek,
    xp.LESSONS_PER_STAR,
    weeklyStarEarned
  )

  node.querySelector('#star-center')
    .setAttribute('fill-opacity', weeklyStarEarned ? 1 : 0)
}

import { h } from 'preact'
import * as util from '../lib/util'

export default function homeStats (progress) {
  return h('div', { class: 'stats' },
    h('div', {},
      h('h4', {}, 'Statistics'),
      h('table', {},
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Current level'),
          h('td', { class: 'value' }, progress.level)
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Total XP earned'),
          h('td', { class: 'value' }, progress.totalXp)
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'XP to next level'),
          h('td', { class: 'value' }, progress.xpToNextLevel)
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Velocity'),
          h('td', { class: 'value' }, `${progress.xpPerDay} XP / day`)
        ),
        h('tr', { class: 'hr' },
          h('td', { colspan: 2 })
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Review attempts'),
          h('td', { class: 'value' }, progress.reviewAttempts)
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Exactly correct'),
          h('td', { class: 'value' }, util.percentOf(progress.exactlyCorrectAttempts, progress.reviewAttempts))
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Reading correct'),
          h('td', { class: 'value' }, util.percentOf(progress.readingCorrectAttempts, progress.reviewAttempts))
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Alternate matches'),
          h('td', { class: 'value' }, util.percentOf(progress.alternateMatchAttempts, progress.reviewAttempts))
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Incorrect'),
          h('td', { class: 'value' }, util.percentOf(progress.incorrectAttempts, progress.reviewAttempts))
        )
      )
    )
  )
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'
import * as review from '../lib/review'
import timeAndDayView from './time-and-day'

const NULL_REVIEW_STATUS = {
  isStale: true,
  lastInboxZero: null,
  lastReviewAt: null,
  lessonTypes: { kanjiProduction: 0, kanjiRecognition: 0, vocabularyProduction: 0, vocabularyRecognition: 0 },
  loaded: false,
  nextDay: '?',
  nextHour: '?',
  nextReviewIn: '?',
  readyForReview: 0
}

export default function reviewsStatusView ({ user, reviewsStatus, reviewLessonTypes }) {
  if (!reviewsStatus) reviewsStatus = NULL_REVIEW_STATUS
  if (review.dueToBeReloaded(reviewsStatus)) review.load()
  reviewLessonTypes = reviewLessonTypes || {
    kanjiProduction: true,
    kanjiRecognition: true,
    vocabularyProduction: true,
    vocabularyRecognition: true
  }
  const toBeReviewed = countReviews(reviewsStatus.readyForReview, reviewLessonTypes, reviewsStatus.lessonTypes)
  return h('div', { id: 'reviewsStatus', class: 'section' },
    h('h2', {}, 'Review status'),
    h('div', { class: 'reviews-progress' },
      h('div', {},
        h('div', { class: 'datum timestamp' }, timeAndDayView(reviewsStatus.lastInboxZero) || 'N/A'),
        h('div', { class: 'label' }, 'Last inbox 0')),
      h('div', {},
        h('div', { class: 'datum timestamp' }, timeAndDayView(reviewsStatus.lastReviewAt) || 'N/A'),
        h('div', { class: 'label' }, 'Last review')),
      toBeReviewed > 0
        ? h('a', { onClick: review.start, tabindex: 0, href: '/app/reviews' },
            h('div', { },
              h('div', { class: 'datum primary item-count' }, toBeReviewed),
              h('div', { class: 'label' }, 'Start review')
            )
          )
        : h('div', { class: 'faux-link', disabled: true },
          reviewsStatus.readyForReview === 0
            ? [h('div', { class: 'datum timestamp' },
                reviewsStatus.nextReviewIn
              ), h('div', { class: 'label' }, 'Next review in')]
            : [h('div', { class: 'datum primary item-count' }, toBeReviewed),
                h('div', { class: 'label' }, 'Select Reviews!')
              ]

        ),
      h('div', {},
        h('div', { class: 'datum item-count' }, reviewsStatus.nextHour),
        h('div', { class: 'label' }, 'Next hour')),
      h('div', {},
        h('div', { class: 'datum item-count' }, reviewsStatus.nextDay),
        h('div', { class: 'label' }, 'Next day'))
    ),
    h('div', { class: 'lesson-type-selection' },
      h('h3', {}, 'Include types:'),
      h('div', { class: 'lesson-types' },
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'kanji_production',
            type: 'checkbox',
            value: 'kanji_production',
            disabled: _.get(reviewsStatus, 'lessonTypes.kanjiProduction') === 0,
            checked: reviewLessonTypes.kanjiProduction && _.get(reviewsStatus, 'lessonTypes.kanjiProduction') > 0,
            onChange (e) {
              reviewLessonTypes.kanjiProduction = e.currentTarget.checked
              render(propUtil.merge({ reviewLessonTypes }))
            }
          }),
          h('label', { for: 'kanji_production' }, `Kanji production (${_.get(reviewsStatus, 'lessonTypes.kanjiProduction')})`)
        ),
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'kanji_recognition',
            type: 'checkbox',
            value: 'kanji_recognition',
            disabled: _.get(reviewsStatus, 'lessonTypes.kanjiRecognition') === 0,
            checked: reviewLessonTypes.kanjiRecognition && _.get(reviewsStatus, 'lessonTypes.kanjiRecognition') > 0,
            onChange (e) {
              reviewLessonTypes.kanjiRecognition = e.currentTarget.checked
              render(propUtil.merge({ reviewLessonTypes }))
            }
          }),
          h('label', { for: 'kanji_recognition' }, `Kanji recognition (${_.get(reviewsStatus, 'lessonTypes.kanjiRecognition')})`)
        ),
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'vocabulary_production',
            type: 'checkbox',
            value: 'vocabulary_production',
            disabled: _.get(reviewsStatus, 'lessonTypes.vocabularyProduction') === 0,
            checked: reviewLessonTypes.vocabularyProduction && _.get(reviewsStatus, 'lessonTypes.vocabularyProduction') > 0,
            onChange (e) {
              reviewLessonTypes.vocabularyProduction = e.currentTarget.checked
              render(propUtil.merge({ reviewLessonTypes }))
            }
          }),
          h('label', { for: 'vocabulary_production' }, `Vocabulary production (${_.get(reviewsStatus, 'lessonTypes.vocabularyProduction')})`)
        ),
        h('div', { class: 'checkbox' },
          h('input', {
            id: 'vocabulary_recognition',
            type: 'checkbox',
            value: 'vocabulary_recognition',
            disabled: _.get(reviewsStatus, 'lessonTypes.vocabularyRecognition') === 0,
            checked: reviewLessonTypes.vocabularyRecognition && _.get(reviewsStatus, 'lessonTypes.vocabularyRecognition') > 0,
            onChange (e) {
              reviewLessonTypes.vocabularyRecognition = e.currentTarget.checked
              render(propUtil.merge({ reviewLessonTypes }))
            }
          }),
          h('label', { for: 'vocabulary_recognition' }, `Vocabulary recognition (${_.get(reviewsStatus, 'lessonTypes.vocabularyRecognition')})`)
        )

      )
    )
  )
}

function countReviews (readyForReview, reviewLessonTypes, lessonTypeCounts) {
  const totalAcrossLessonTypes = _.reduce(lessonTypeCounts, (memo, count, type) => {
    return memo + (reviewLessonTypes[type] ? count : 0)
  }, 0)

  return readyForReview < totalAcrossLessonTypes ? readyForReview : totalAcrossLessonTypes
}

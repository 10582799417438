import * as home from './home'
import * as net from './net'
import * as propUtil from './prop-util'
import * as route from './route'

export const getAuthenticityToken = () =>
  document.querySelector('meta[name="csrf-token"]').getAttribute('content')

export const getUserId = () =>
  document.querySelector('meta[name="user-id"]').getAttribute('content')

export const getPasswordResetToken = () =>
  document.querySelector('meta[name="password-reset-token"]').getAttribute('content')

export const isLoggedIn = () =>
  !!getUserId()

export function redirectIfNotAuthorized () {
  if (getUserId()) return // <- logged in
  if (route.matches(route.PATHS.unauthorized)) return // <- ok not to be logged in
  route.redirect('/app/login', true)
}

export function pollAccountStatus () {
  const intervalId = setInterval(() => {
    net.getRaw('/app/account_ready_status').then(res => {
      if (res.status === 204) {
        clearInterval(intervalId)
        route.redirect('/app', true)
        home.load()
      } else if (res.status === 401) {
        route.andRerender('/app/login', propUtil.merge({
          errorMessages: ['Something whent wrong with your registration. Try logging in again.']
        }))
      }
    })
  }, 1000)
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as propUtil from '../lib/prop-util'
import * as lessonLists from '../lib/lesson-lists'
import render from '../lib/render'
import infoButtonView from './info-button'
import optionView from './option'

export default function lessonListModeSelectView ({ user, showInfoButton = true }) {
  return h('div', { class: 'lesson-list-mode-selector' },
    h('select', {
      id: 'lesson_list_mode',
      name: 'lesson_list_mode',
      onChange (e) {
        e.preventDefault()
        render(propUtil.merge({
          user: {
            lessonListMode: e.target.value
          }
        }))
      }
    },
    _.map(lessonLists.LIST_MODES, listMode =>
      optionView(listMode.name, listMode.id, listMode.id === user.lessonListMode)
    )),
    showInfoButton && infoButtonView({
      tutorial: 'listModes'
    })
  )
}

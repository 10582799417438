export function invalidAnswer (node, message) {
  node.classList.add('shake')
  window.setTimeout(() => {
    node.classList.remove('shake')
  }, 300)

  // lol
  if (message && !node.parentNode.querySelector('.answer-error')) {
    const answerError = document.createElement('div')
    answerError.classList.add('answer-error')
    answerError.textContent = message
    node.parentNode.appendChild(answerError)

    window.setTimeout(() => {
      answerError.classList.add('fade-out')
      window.setTimeout(() => {
        answerError.remove()
      }, 500)
    }, 2500)
  }
}

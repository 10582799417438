import { h } from 'preact'
import * as anim from '../lib/anim'

export default function copyButtonView (text, cls) {
  if (!window.navigator.clipboard) return // Old browsers don't support copy

  return h('a', {
    id: 'copyButton',
    class: `emoji ${cls}`,
    title: `Copy "${text}"`,
    onClick (e) {
      e.preventDefault()
      window.navigator.clipboard.writeText(text)
      anim.invalidAnswer(e.target, `Copied 「${text}」!`)
    }
  }, '📋')
}

import { h } from 'preact'

export default h('svg', {
  viewBox: '0 0 500 500',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg'
}, h('g', { id: 'progress-icon' },
  h('g', { id: 'xp-sections' },
    h('path', {
      id: '1',
      d: 'M262.889 110.608C291.564 113.28 317.725 124.614 338.728 141.968L262.889 217.806V110.608Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    }),
    h('path', {
      id: '2',
      d: 'M357.082 160.226C374.778 181.413 386.317 207.921 388.949 237H280.308L357.082 160.226Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    }),
    h('path', {
      id: '3',
      d: 'M388.916 262.889C386.232 291.694 374.807 317.962 357.32 339.013L281.195 262.889H388.916Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    }),
    h('path', {
      id: '4',
      d: 'M339.013 357.32C317.962 374.807 291.694 386.232 262.889 388.916V281.195L339.013 357.32Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    }),
    h('path', {
      id: '5',
      d: 'M237 388.949C207.921 386.317 181.413 374.778 160.226 357.082L237 280.308V388.949Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    }),
    h('path', {
      id: '6',
      d: 'M141.968 338.728C124.614 317.725 113.28 291.564 110.608 262.889H217.806L141.968 338.728Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    }),
    h('path', {
      id: '7',
      d: 'M110.575 237C113.195 208.052 124.643 181.651 142.204 160.511L218.694 237H110.575Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    }),
    h('path', {
      id: '9',
      d: 'M237 110.575V218.694L160.511 142.204C181.651 124.643 208.052 113.195 237 110.575Z',
      fill: '#726DA8',
      'fill-opacity': '0.01',
      stroke: '#726DA8',
      'stroke-width': '10'
    })
  ),
  h('g', { id: 'level-sections' },
    h('path', {
      id: '1_2',
      d: 'M365.687 115.009L406.142 74.5535C367.652 40.2127 317.78 18.3594 262.889 15.3558V72.4782C302.038 75.3383 337.667 90.8782 365.687 115.009Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: '2_2',
      d: 'M427.052 237H484.356C481.373 181.683 459.247 131.455 424.486 92.8215L384.029 133.279C408.504 161.471 424.248 197.448 427.052 237Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: '3_2',
      d: 'M384.261 365.955L424.818 406.511C459.349 367.971 481.332 317.956 484.346 262.889H427.024C424.155 302.163 408.524 337.895 384.261 365.955Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: '4_2',
      d: 'M262.889 427.024V484.346C317.956 481.332 367.971 459.349 406.511 424.818L365.955 384.261C337.895 408.524 302.163 424.155 262.889 427.024Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: '5_2',
      d: 'M133.279 384.029L92.8215 424.486C131.455 459.247 181.683 481.373 237 484.356V427.052C197.448 424.248 161.471 408.504 133.279 384.029Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: '6_2',
      d: 'M72.4782 262.889H15.3558C18.3595 317.78 40.2127 367.652 74.5536 406.142L115.009 365.686C90.8782 337.667 75.3384 302.038 72.4782 262.889Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: '7_2',
      d: 'M115.241 133.547L74.8839 93.1902C40.3137 131.774 18.3192 181.859 15.3456 237H72.4503C75.2449 197.573 90.898 161.699 115.241 133.547Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: '8',
      d: 'M93.1902 74.8839C131.774 40.3137 181.859 18.3192 237 15.3456V72.4503C197.573 75.2449 161.699 90.898 133.547 115.241L93.1902 74.8839Z',
      fill: '#4F4F4F',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    })
  ),
  h('circle', {
    id: 'max-level',
    cx: '250',
    cy: '250',
    r: '13',
    fill: '#E63462',
    'fill-opacity': '0.01'
  })
))

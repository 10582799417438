import { h } from 'preact'
import * as lesson from '../lib/lesson'
import formView from './form'

export default function lessonsFromContentView () {
  return formView({ onSubmit: lesson.startLessonFromWordDump },
    h('div', { class: 'section', tabindex: 0 },
      h('h2', { id: 'study-content' }, 'Study words found in content'),
      h('p', {}, `
        Type or paste some text: it could be a sentence, an article, a text
        message, or transcript. We'll scan the content for vocabulary and
        start a lesson for whatever words we find!
      `),
      h('textarea', { class: 'word-dump' }),
      h('div', { class: 'buttons' },
        h('button', {}, 'Scan for vocabulary')
      )
    )
  )
}

import * as _ from '../vendor/lodash'

export function isLightRgb (color) {
  const LIGHTNESS_THRESHOLD = 100 * 3

  if (color === 'transparent') return true
  if (color.indexOf('rgb') !== 0) return

  const colors = color.match(/^rgba?\(\s*(\d+),\s*(\d+),\s*(\d+)/)
  return Number(colors[1]) + Number(colors[2]) + Number(colors[3]) > LIGHTNESS_THRESHOLD
}

export function overridePageBackgroundColor () {
  if (!window.getComputedStyle) return

  _.defer(() => {
    const overridesBackgroundColorNode = document.querySelector('.overrides-background-color')
    const referenceNode = document.querySelector('#app')

    if (overridesBackgroundColorNode || referenceNode) {
      const backgroundColor = window.getComputedStyle(
        overridesBackgroundColorNode || referenceNode
      )['background-color']

      document.body.style.backgroundColor = backgroundColor
      const notch = document.querySelector('#notch')
      if (notch) {
        notch.classList.toggle('light', isLightRgb(backgroundColor))
        notch.style.backgroundColor = backgroundColor
      }
    }
  })
}

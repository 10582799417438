import { h } from 'preact'
import { Match } from 'preact-router/match'
import * as shame from '../lib/shame'

export default function notch () {
  return h(Match, {
    path: '/'
  }, ({ matches, path, url }) => {
    return h('div', { id: 'notch' },
      shame.isIos() && shame.hasNotch()
        ? [h('div', { class: 'left' }), h('div', { class: 'right' })]
        : null
    )
  })
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import images from '../images'
import formView from './form'

export default (props) =>
  h('div', { id: 'resetPassword' },
    h('img', { src: images['login-header.svg'], alt: 'KameSame title' }),
    h('div', { class: 'wrap' },
      formView(_.extend({ action: '/app/reset_password' }, props),
        h('h2', {}, 'Reset password'),
        h('div', {},
          h('label', { for: 'email' }, 'E-mail'),
          h('input', { id: 'email', name: 'email', type: 'email', placeholder: 'E-mail', required: true })
        ),
        h('div', { class: 'buttons' },
          h('button', { type: 'submit' }, 'Reset password')
        )
      ),
      h('p', { class: 'center' },
        'Remember your password? ',
        h('a', { href: '/app/login' }, 'Login'), '!',
        h('br'),
        ' Don\'t have an account after all? ',
        h('a', { href: '/app/sign_up' }, 'Sign up'), '!'
      )
    )
  )

import * as _ from '../vendor/lodash'

export default function dynamicOpacityForView (sections, userVal, maxVal, isMaxedOut) {
  const sectionCount = sections.length
  const extraUnits = maxVal % sectionCount
  const unitsPerSection = (maxVal + extraUnits) / sectionCount

  _.each(sections, (section, sectionIndex) => {
    const sectionNumber = sectionIndex + 1
    let opacity = 0
    if (isMaxedOut || userVal > sectionNumber * unitsPerSection) {
      opacity = 1
    } else if (userVal < sectionIndex * unitsPerSection) {
      opacity = 0
    } else if (sectionNumber === sectionCount) {
      // The last section is an edge case because it may be smaller
      const sectionUnits = userVal - sectionIndex * unitsPerSection
      opacity = 1.0 * sectionUnits / (unitsPerSection - extraUnits)
    } else {
      const sectionUnits = userVal - sectionIndex * unitsPerSection
      opacity = 1.0 * sectionUnits / unitsPerSection
    }

    section.setAttribute('fill-opacity', opacity)
  })
}

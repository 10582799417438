import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as study from '../lib/study'
import levelBarsView from './level-bars'
import reviewStatsView from './review-stats'
import reviewsByBucketView from './reviews-by-bucket'

export default function reviewsSummaryView ({ cards, reviewsStatistics, progress, userSessionInactivityIntervals }) {
  if (!cards) return
  const answered = _.filter(cards, study.cardWasAnswered)
  const [correct, incorrect] = _.partition(answered, study.cardWasCorrect)
  const multipleLessonTypes = _.uniqBy(answered, (card) => study.questionType(card)).length > 1

  return h('div', { id: 'reviewsSummary', class: 'section' },
    h('h2', {}, 'Review results'),
    levelBarsView({ cards: answered, progress }),
    reviewStatsView({ cards: answered, reviewsStatistics, answered, correct, incorrect, progress, userSessionInactivityIntervals }),
    h('div', { class: `section reviews-by-bucket incorrect ${incorrect.length > 0 ? '' : 'hidden'}` },
      h('h3', {}, `${incorrect.length} Incorrect`),
      reviewsByBucketView(incorrect, multipleLessonTypes)
    ),
    h('div', { class: `section reviews-by-bucket correct ${correct.length > 0 ? '' : 'hidden'}` },
      h('h3', {}, `${correct.length} Correct`),
      reviewsByBucketView(correct, multipleLessonTypes)
    )
  )
}

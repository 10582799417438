import { h } from 'preact'
import * as study from '../lib/study'

export default function studyCorrectionView ({ mode, subMode, card, props }) {
  const feedback = study.feedback(card)
  return [
    h('h3', {},
      h('span', { class: 'emoji-inline' }, feedback.flair),
      ` ${feedback.zinger} `,
      h('span', { class: 'emoji-inline' }, feedback.flair)
    ),
    h('p', {},
      feedback.prose
    )
  ]
}

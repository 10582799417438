import * as _ from '../vendor/lodash'

export function scrollToTop () {
  if (!_.isFunction(window.scrollTo)) return
  try {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (e) { /* some browsers just can't deal with this */ }
}

export function scrollTo (selector) {
  const el = document.querySelector(selector)
  if (el) {
    el.scrollIntoView()
  }
}

export function isBrowserUnsupported () {
  return typeof window.document.body.classList !== 'object' ||
    typeof window.document.body.closest !== 'function' ||
    typeof window.document.body.className !== 'string' ||
    typeof window.document.body.className.indexOf !== 'function'
}

import { h } from 'preact'

export default h('svg', {
  viewBox: '0 0 500 500',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg'
}, h('g', { id: 'lessons-icon' },
  h('g', { id: 'starpoints' },
    h('path', {
      id: 'starpoint-1',
      d: 'M314.554 181.321L249.834 41L184 181.321H314.554Z',
      fill: '#F4B718',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: 'starpoint-2',
      d: 'M468 199.516L314.554 181.321L354.554 304.434L468 199.516Z',
      fill: '#F4B718',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: 'starpoint-3',
      d: 'M384.668 456L354.554 304.434L249.834 380.521L384.668 456Z',
      fill: '#F4B718',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: 'starpoint-4',
      d: 'M115 456L249.834 380.521L144 304.434L115 456Z',
      fill: '#F4B718',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    }),
    h('path', {
      id: 'starpoint-5',
      d: 'M31 199.326L144.856 305L185 181L31 199.326Z',
      fill: '#F4B718',
      'fill-opacity': '0.01',
      stroke: '#4F4F4F',
      'stroke-width': '10'
    })
  ),
  h('path', {
    id: 'star-center',
    d: 'M249.5 41L314.25 181.379L467.767 199.581L354.268 304.541L384.397 456.169L249.5 380.66L114.603 456.169L144.732 304.541L31.2325 199.581L184.75 181.379L249.5 41Z',
    fill: '#F4B718',
    'fill-opacity': '0.01',
    stroke: '#4F4F4F',
    'stroke-width': '10'
  })
))

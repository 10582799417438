import { h } from 'preact'
import * as itemUtil from '../lib/item-util'
import * as shame from '../lib/shame'
import * as study from '../lib/study'
import svgTextView from './svg-text'

export default function meaningView ({ card }) {
  const sizeClass = study.meaningSizeClassFor(card.text)

  return h('div', {
    class: `meaning ${shame.shouldChokeUp() ? 'choke-up' : ''}`
  },
  card.text.length < 40
    ? svgTextView(card.text)
    : h('div', { class: `text ${sizeClass}` }, card.text),
  h('div', { class: 'parts-of-speech' },
    itemUtil.stringifyPartsOfSpeech(card.item))
  )
}

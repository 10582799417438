import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as search from '../lib/search'
import * as util from '../lib/util'
import itemListFancyView from './item-list-fancy'

export default function searchResultsView ({ searches, query, ...props }) {
  if (!query) return
  if (searches == null || searches[query] == null) {
    search.execute(query)
  } else if (searches[query] === 'loading') {
    return h('div', { class: 'results message' },
      h(Helmet, { title: `Searching for "${query}"` }),
      '🕵️‍♀️ Searching for ',
      h('em', {}, query),
      '… 🕵️‍♂️'
    )
  } else if (_.isEmpty(searches[query].results)) {
    const targetLanguageCode = util.isJapanese(query) ? 'en' : 'ja'

    return h('div', { class: 'results message' },
      h(Helmet, { title: `Searching for "${query}` }),
      '😥 No kanji or vocabulary found for ',
      h('em', {}, query),
      ' 😥',
      h('br', {})
      ,
      'Try ',
      h('a', {
        href: `https://translate.google.com?op=translate&sl=auto&tl=${targetLanguageCode}&text=${encodeURIComponent(query)}`
      }, 'Google Translate'),
      ', maybe?'
    )
  } else {
    return [
      h(Helmet, { title: `Search results for "${query}"` }),
      itemListFancyView({
        items: searches[query].results,
        baseUrl: `/app/search/${query}`,
        disableFilter: true,
        englishIsEssential: true,
        enableListTypes: ['search-results'],
        query,
        ...props
      })
    ]
  }
}

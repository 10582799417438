import * as shame from './shame'

const AudioContext = window.AudioContext || window.webkitAudioContext

export function play (url) {
  if (shame.isIos() && AudioContext) {
    playIos(url)
  } else {
    const audio = new window.Audio(url)
    audio.play()
  }
}

function playIos (url) {
  const audioContext = new AudioContext()
  const gainNode = audioContext.createGain()
  gainNode.gain.value = 10
  const source = audioContext.createBufferSource()
  const request = new window.XMLHttpRequest()
  request.open('GET', url, true)
  request.responseType = 'arraybuffer'
  request.addEventListener('load', function (e) {
    audioContext.decodeAudioData(request.response).then((buffer) => {
      source.buffer = buffer
      source.connect(gainNode).connect(audioContext.destination)
      source.start(0)
    })
  }, false)
  request.send()
}

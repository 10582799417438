import { h } from 'preact'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'

export default function infoLink ({ tutorial, cssClass }, ...children) {
  return h('a', {
    class: cssClass,
    tabindex: 0,
    href: '/app',
    onClick (e) {
      e.preventDefault()
      e.stopPropagation()
      render(propUtil.merge({
        infoTutorial: tutorial,
        infoShouldBeDisplayed: true
      }))
    }
  }, ...children)
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as lesson from '../lib/lesson'
import * as lessonLists from '../lib/lesson-lists'
import * as propUtil from '../lib/prop-util'
import render from '../lib/render'
import formView from './form'
import lessonListView from './lesson-list'
import lessonListModeSelectView from './lesson-list-mode-select'
import optionView from './option'

export default function lessonsFromLists ({ user, lessonsStatus, showListDetails }) {
  if (lesson.dueToBeReloaded(lessonsStatus)) {
    lesson.load()
  }
  const list = _.find(lessonLists.LISTS, list =>
    list.code === user.lessonListMostRecentCode
  ) || lessonLists.LISTS[0]
  const listStatus = _.get(lessonsStatus, `lists.${list.code}`) || {}

  return h('div', { id: 'lessonsFromLists', class: 'section' },
    formView({ onSubmit: lesson.startLessonFromList, tabindex: 0 },
      h('h2', { id: 'study-lists' }, 'Study from a list'),
      h('input', { type: 'hidden', name: 'list_code', value: list.code }),

      h('div', {},
        h('label', {
          for: 'lesson_list_most_recent_code',
          class: 'hidden',
          'aria-hidden': false
        }, 'Selected list'),
        h('select', {
          id: 'lesson_list_most_recent_code',
          name: 'lesson_list_most_recent_code',
          required: true,
          onChange (e) {
            e.preventDefault()
            render(propUtil.merge({
              user: {
                lessonListMostRecentCode: e.target.value
              }
            }))
          }
        },
        _.map(lessonLists.LISTS, list =>
          optionView(list.name, list.code, list.code === user.lessonListMostRecentCode)
        ))
      ),
      lessonListView({
        lessonsStatus,
        showListDetails,
        listCode: list.code,
        description: list.description
      }),
      lessonListModeSelectView({ user }),
      h('div', { class: 'buttons' },
        h('button', {
          disabled: listStatus.unstartedLessons === 0,
          title: listStatus.unstartedLessons === 0
            ? 'You\'ve learned all the lessons in this list. Yay! 🌟'
            : 'Start a lesson from this list'
        }, ['Start ', h('span', { class: 'big' }, ' studying')]),
        h('div', { class: 'flex' },
          h('div', { class: 'checkbox' },
            h('label', { for: 'lesson_list_shuffle' }, 'Shuffle'),
            h('input', {
              id: 'lesson_list_shuffle',
              name: 'lesson_list_shuffle',
              type: 'checkbox',
              checked: user.lessonListShuffle
            })
          )
        )
      )
    ),

    h('div', { class: 'protip' },
      h('strong', {}, 'Remember: '),
      'you can ',
      h('a', { href: '/app/account' }, 'configure how many items appear '),
      ' in each session.'
    )
  )
}

import { h } from 'preact'
import * as itemUtil from '../lib/item-util'

export default function itemProgressBarView ({ progress, type }) {
  const percent = itemUtil.percentOf(progress, type)
  return h('div', { class: `${type} bar` },
    h('div', { class: 'bar-container' },
      h('div', {
        class: 'fill',
        style: `width: ${percent}%`,
        'data-count': percent !== 0 && percent !== 100 ? progress[type] : null
      })
    ),
    h('div', { class: 'counts' },
      h('div', {}, 0),
      h('div', {}, progress.attemptCount)
    )
  )
}

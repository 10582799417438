import * as _ from '../vendor/lodash'

export default function listProgressAttrs (listProgress, prop) {
  const ratio = listProgress[prop] / listProgress.totalLessons
  const percentage = ratio * 100
  return {
    class: `progress ${prop}`,
    title: `${listProgress[prop]} ${_.lowerCase(prop)}`,
    style: `width: ${percentage}%`
  }
}

import { h } from 'preact'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as itemUtil from '../lib/item-util'
import * as pwa from '../lib/pwa'
import loadingView from './loading'
import itemReadingsAndRecordingLinksView from './item-readings-and-recording-links'
import itemActionsView from './item-actions'
import itemConjugationsView from './item-conjugations'
import itemOneOffLessonView from './item-one-off-lesson'
import itemProgressView from './item-progress'
import excludeOrUnexcludeItemView from './exclude-or-unexclude-item'
import referencesView from './references'
import relativesView from './relatives'
import SpellingsView from './spellings'
import DefinitionsView from './definitions'

export default function itemView ({ item, itemId }) {
  if (item && String(item.id) === itemId) {
    if (itemUtil.isStale(item)) itemUtil.load(itemId)
    const vocab = item.type === 'vocabulary'
    const kanji = item.type === 'kanji'

    return h('div', { id: 'item' },
      h(Helmet, { title: item.text }),
      h('div', { class: 'item-header' },
        h('div', { class: `name ${item.type}` }, item.text),
        itemActionsView({ item })
      ),
      h('h2', {}, `${_.capitalize(item.type)} summary`),
      h('div', { class: 'facts' },
        h('div', { class: 'fact' },
          h('div', { class: 'key' }, 'Readings'),
          h('div', { class: 'value', lang: 'ja' },
            itemReadingsAndRecordingLinksView({ item })
          )
        ),
        kanji && [
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Onyomi'),
            h('div', { class: 'value', lang: 'ja' }, item.kanjiOnyomi.join('、') || 'N/A')
          ),
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Kunyomi'),
            h('div', { class: 'value', lang: 'ja' }, item.kanjiKunyomi.join('、') || 'N/A')
          )
        ],
        h('div', { class: 'fact' },
          h('div', { class: 'key' }, 'Meanings'),
          h('div', { class: 'value' }, item.meanings.join(', '))
        ),
        h('div', { class: 'fact' },
          h('div', { class: 'key' }, 'WaniKani Level'),
          h('div', { class: 'value' }, item.wanikaniLevel || 'N/A')
        ),
        kanji && [
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Stroke count'),
            h('div', { class: 'value' }, item.kanjiStrokeCount || 'N/A')
          ),
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Top 2500'),
            h('div', { class: 'value' }, item.kanjiFrequency ? `#${item.kanjiFrequency}` : 'N/A')
          ),
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Grade Level'),
            h('div', { class: 'value' }, (item.gradeLevel && item.gradeLevel < 9) ? item.gradeLevel : 'N/A')
          ),
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'JLPT/NLPT Level'),
            h('div', { class: 'value' }, massageJlpt(item.jlptLevel) || 'N/A')
          )
        ],
        vocab && [
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Variations'),
            h('div', { class: 'value', lang: 'ja' }, item.altTexts.join('、') || 'N/A')
          ),
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Parts of Speech'),
            h('div', { class: 'value' }, itemUtil.stringifyPartsOfSpeech(item))
          ),
          h('div', { class: 'fact' },
            h('div', { class: 'key' }, 'Tags'),
            h('div', { class: 'value' }, item.tags.length === 0
              ? 'N/A'
              : item.tags.map(tag => h('span', { class: 'item-tag' }, tag))
            )
          )
        ]
      ),
      _.map(item.learningProgresses, progress =>
        itemProgressView({ item, progress })
      ),
      h('div', { class: 'one-off-actions' },
        itemOneOffLessonView({ item }),
        excludeOrUnexcludeItemView({ item })
      ),
      h(SpellingsView, { props: { item } }),
      h(DefinitionsView, { props: { item } }),
      referencesView({ item }),
      itemConjugationsView({ item }),
      relativesView({ item }),
      pwa.isStandalone()
        ? h('a', {
            class: 'hovery bottom left emoji',
            onClick () { pwa.goBack() }
          }, '👈')
        : null
    )
  } else {
    itemUtil.load(itemId)
    return loadingView()
  }
}

function massageJlpt (jlptLevel) {
  if (!jlptLevel) return
  if (jlptLevel === 2) {
    return '2~3'
  } else if (jlptLevel === 4) {
    return '5'
  } else {
    return String(jlptLevel)
  }
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import listProgressView from './list-progress'

export default function lessonList ({ lessonsStatus, listCode, description, buttonLabel, showListDetails }) {
  const listProgress = _.get(lessonsStatus, `lists.${listCode}`)
  return h('div', {},
    h('p', {}, description),
    listProgressView(listProgress, { showListDetails })
  )
}

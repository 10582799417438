import { h } from 'preact'
import { Router } from 'preact-router'
import Helmet from 'preact-helmet'
import * as _ from '../vendor/lodash'
import * as pwa from '../lib/pwa'
import * as route from '../lib/route'
import * as user from '../lib/user'
import chooseNewPasswordView from './choose-new-password'
import homeView from './home'
import infoView from './info'
import itemExclusionsView from './item-exclusions'
import itemView from './item'
import accountView from './account'
import accountSetupInProgressView from './account-setup-in-progress'
import footerView from './footer'
import learningsView from './learnings'
import lessonsFromWordsView from './lessons-from-words'
import lessonsView from './lessons'
import lessonsSummaryView from './lessons-summary'
import loadingView from './loading'
import loginView from './login'
import navView from './nav'
import notchView from './notch'
import notFoundView from './not-found'
import notificationBarView from './notification-bar'
import resetPasswordView from './reset-password'
import reviewsView from './reviews'
import routeView from './route'
import searchView from './search'
import signUpView from './sign-up'
import studyView from './study'

const OPTIONAL_ITEM_LIST_PATH_PARAMS = ':itemListType?/:itemListSort?/:itemListFilter?'
export default function main (props) {
  return h('div', { id: 'app', class: `kamesame ${user.hideSuperfluousEnglishClass(props.user)}` },
    h(Helmet, {
      titleTemplate: '%s | KameSame',
      defaultTitle: 'KameSame'
    }),
    pwa.isStandalone() ? notchView(props) : navView(props),
    notificationBarView(props),
    infoView(props),
    h(Router, _.extend({
      id: 'content',
      ref (c) { if (c) route.setPrimaryRouterInstanceEwwGross(c) },
      onChange: route.onChange
    }, props),
    routeView('', homeView, props),
    routeView(`learnings/:rank/${OPTIONAL_ITEM_LIST_PATH_PARAMS}`, learningsView, props),
    routeView('login', loginView, props),
    routeView('sign_up', signUpView, props),
    routeView('reset_password', resetPasswordView, props),
    routeView('choose_new_password', chooseNewPasswordView, props),
    routeView('loading', loadingView, props),
    routeView('account_setup_in_progress', accountSetupInProgressView, props),
    routeView('lessons', lessonsView, props),
    routeView('lessons/summary', lessonsSummaryView, props),
    routeView(`lessons/from-words/${OPTIONAL_ITEM_LIST_PATH_PARAMS}`, lessonsFromWordsView, props),
    routeView('lessons/study/:cardId', studyView, props),
    routeView('lessons/study/:cardId/result', studyView, props),
    routeView(`blocked_items/${OPTIONAL_ITEM_LIST_PATH_PARAMS}`, itemExclusionsView, props),
    routeView('reviews', reviewsView, props),
    routeView('reviews/summary', reviewsView, _.extend({ reviewsSummary: true }, props)),
    routeView('reviews/study/:cardId', studyView, props),
    routeView('reviews/study/:cardId/result', studyView, props),
    routeView(`search/:query?/${OPTIONAL_ITEM_LIST_PATH_PARAMS}`, searchView, props),
    routeView('items/:itemId', itemView, props),
    routeView('account', accountView, props),
    h(notFoundView, { default: true })
    ),
    footerView(),
    pwa.isStandalone() ? navView(props) : null
  )
}

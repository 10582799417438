import { h } from 'preact'
import * as _ from '../vendor/lodash'

export default function synonymsView ({ card }) {
  if (_.isEmpty(card.alternativeMeanings)) return null
  return h('div', { class: 'synonyms' },
    h('h4', {}, 'Alternatives:'),
    h('div', { class: 'items' }, card.alternativeMeanings.join(' • '))
  )
}

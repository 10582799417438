import { h } from 'preact'
import * as itemUtil from '../lib/item-util'
import * as util from '../lib/util'

export default (item, { sortDescription, englishIsEssential } = {}) =>
  h('a', { class: `item ${item.type}`, href: `/app/items/${item.id}`, tabindex: 0 },
    h('div', { class: 'row' },
      h('div', { class: `kanji ${itemUtil.sizeClassFor(item)}`, lang: 'ja' }, item.text),
      h('div', { class: `english ${englishIsEssential ? '' : 'superfluous'}` },
        itemUtil.stringifyTranslationsForItemList(item, 'meanings', ', '))
    ),
    h('div', { class: 'row' },
      h('div', { class: 'kana', lang: 'ja' }, itemUtil.stringifyTranslationsForItemList(item, item.usuallyWrittenUsingKanaAlone ? 'altTexts' : 'readings', '、')),
      sortDescription && item.sortValue
        ? h('div', { class: 'sort-value' },
            (util.looksLikeDate(item.sortValue)
              ? util.durationSinceDescription(util.durationSince(item.sortValue))
              : `${Math.round(100 * item.sortValue)}%`) + ' ' + sortDescription
          )
        : h('div')
    )
  )

import { h } from 'preact'
import * as shame from '../lib/shame'
import linkView from './link'

export default function appLinkView ({ url, imgUrl, title, appStoreUrl }) {
  if (!url) return
  if (appStoreUrl && !shame.isIos()) return
  return h('div', { class: 'app' },
    linkView(url, [
      h('img', { src: imgUrl, alt: title })
    ], { tabindex: 0 }),

    appStoreUrl
      ? linkView(appStoreUrl,
          title, { class: 'title' }
        )
      : h('div', { class: 'title' }, title)
  )
}

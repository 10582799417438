import { h } from 'preact'
import * as _ from '../vendor/lodash'
import itemListView from './item-list'

export default function relativesView ({ item }) {
  if (_.isEmpty(item.relatives)) return

  return h('div', { class: 'relatives' },
    h('h2', {}, item.type === 'kanji' ? 'Used in vocabulary:' : 'Contains kanji:'),
    itemListView(item.relatives)
  )
}

import { h } from 'preact'
import Helmet from 'preact-helmet'
import bailWithoutView from './bail-without'
import notificationAlternateView from './notification-alternate'
import pauseAndUnpauseReviewsView from './pause-and-unpause-reviews'
import pauseWarningView from './pause-warning'
import reviewsStatusView from './reviews-status'
import reviewsSummaryView from './reviews-summary'
import reviewWipView from './review-wip'

export default function reviewsView ({ user, progress, reviews, reviewsStatus, reviewLessonTypes, reviewsStatistics, reviewsSummary, userSessionInactivityIntervals, ...props }) {
  if (bailWithoutView(user, progress)) return

  return h('div', { id: 'reviews' },
    h(Helmet, { title: 'Reviews' }),
    pauseWarningView({ user, mode: 'reviews' }),
    reviewWipView(reviews, user),
    notificationAlternateView(props),
    reviewsStatusView({ user, reviewsStatus, reviewLessonTypes }),
    pauseAndUnpauseReviewsView({ user, unpauseOnly: true }),
    reviewsSummaryView({ cards: reviews, reviewsStatistics, progress, userSessionInactivityIntervals })
  )
}

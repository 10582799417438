import { h } from 'preact'
import studyRewardNotificationXpView from './study-reward-notification-xp'
import studyRewardNotificationBucketView from './study-reward-notification-bucket'
import studyRewardNotificationLevelView from './study-reward-notification-level'
import studyRewardNotificationStarView from './study-reward-notification-star'
import studyRewardNotificationInboxZeroView from './study-reward-notification-inbox-zero'

export default function studyRewardNotificationView (card, user) {
  if (!user || user.hideRewardNotifications || !card || !card.complete) return

  return h('div', { class: 'reward-notifications' },
    studyRewardNotificationXpView(card),
    studyRewardNotificationBucketView(card),
    studyRewardNotificationLevelView(card),
    studyRewardNotificationStarView(card),
    studyRewardNotificationInboxZeroView(card)
  )
}

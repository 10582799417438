import { h } from 'preact'
import referencesView from './references'
import relativesView from './relatives'
import DefinitionsView from './definitions'
import SpellingsView from './spellings'
import studyCorrectionView from './study-correction'

export default function studyOutcomeView ({ mode, subMode, card, isResultLoaded, wasUndone, props }) {
  return h('div', { class: `outcome ${isResultLoaded ? '' : 'pending'}` },
    studyCorrectionView({ mode, subMode, card, props }),
    isResultLoaded && !wasUndone && [
      referencesView({ item: card.item }),
      relativesView({ item: card.item }),
      h(SpellingsView, {
        props: { item: card.item }
      }),
      h(DefinitionsView, {
        props: { item: card.item }
      })
    ]
  )
}

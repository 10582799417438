import * as _ from '../vendor/lodash'
import * as propUtil from './prop-util'
import render from './render'
import * as study from './study'
import autoplayAudio from './autoplay-audio'

export default {
  fail: {
    label: 'Fail',
    cssClass: 'fail',
    title: 'Mark this item as incorrect',
    dontNavigateToNextCard: true,
    resolve ({ mode, subMode, card, props, isResult }) {
      return {
        shouldRender: mode === 'lessons' || !isResult, // e.g. not on review results
        shouldHide: isResult,
        shouldDisable: false,
        onClickDo () {
          const fakeAnswer = '¯\\_(ツ)_/¯'

          study.renderPendingResult(mode, card, fakeAnswer)
          study.submitAnswer(mode, subMode, card, fakeAnswer, props[mode])
          autoplayAudio(mode, subMode, card, props.user, fakeAnswer)
        },
        afterActionNewCardProps: null
      }
    }
  },
  markWrongAfterTheFact: {
    label: 'Fail',
    cssClass: 'fail',
    title: 'On second thought, mark this wrong',
    disabledTitle: 'This review was already failed',
    resolve ({ mode, subMode, card, props, isResult }) {
      return {
        shouldRender: mode === 'reviews' && isResult,
        shouldHide: !card.undoable,
        shouldDisable: card.failedReview,
        onClickDo () {
          study.undoAndFailReview(card, (er, res) => {
            if (er) return
            render(propUtil.mergeAndSave({
              [mode]: {
                [card.randomUrlPath]: res.result
              },
              user: {
                undoCredits: res.undoCredits
              }
            }))
          })
        },
        afterActionNewCardProps: {}
      }
    }
  },
  undoReview: {
    label: 'Undo',
    cssClass: 'undo-review',
    resolve ({ mode, subMode, card, user, props, isResult }) {
      let labelDingus
      if (!card.failedReview || user.undoCreditLimit === 0) {
        labelDingus = ''
      } else if (user.undoCreditLimit < 0) {
        labelDingus = ' ∞'
      } else if (user.undoCredits <= 0) {
        labelDingus = ' 🄌'
      } else if (user.undoCredits <= 1) {
        labelDingus = ' ❶'
      } else if (user.undoCredits <= 2) {
        labelDingus = ' ❷'
      } else if (user.undoCredits <= 3) {
        labelDingus = ' ❸'
      } else if (user.undoCredits <= 4) {
        labelDingus = ' ❹'
      } else if (user.undoCredits <= 5) {
        labelDingus = ' ❺'
      } else if (user.undoCredits <= 6) {
        labelDingus = ' ❻'
      } else if (user.undoCredits <= 7) {
        labelDingus = ' ❼'
      } else if (user.undoCredits <= 8) {
        labelDingus = ' ❽'
      } else if (user.undoCredits <= 9) {
        labelDingus = ' ❾'
      } else {
        labelDingus = ` ${user.undoCredits}`
      }
      const unlimitedUndos = user.undoCreditLimit === -1
      const outOfCredits = card.failedReview &&
        user.undoCredits === 0 &&
        !unlimitedUndos

      return {
        label: `Undo${labelDingus}`,
        shouldRender: mode === 'reviews' && isResult,
        shouldHide: false,
        shouldDisable: !card.undoable || outOfCredits,
        title: `Undo this answer ${card.failedReview
          ? `(${unlimitedUndos ? 'unlimited' : user.undoCredits} undo credits remaining)`
          : ''
        }`,
        disabledTitle: outOfCredits
          ? user.undoCreditLimit === 0
              ? 'You have disabled undo, so you can\'t undo!'
              : 'You don\'t have any undo credits remaining. Do more reviews!'
          : 'This answer can\'t be undone',
        onClickDo () {
          study.undoReview(card, (er, res) => {
            if (er) return
            render(propUtil.replaceAndSave(
              `${mode}.${card.randomUrlPath}`,
              _.extend({}, res.card, {
                // This identifier is created by the client and needs to be same
                randomUrlPath: card.randomUrlPath,

                // These are both computed up front expensively so preserve them
                text: card.text,
                alternativeMeanings: card.alternativeMeanings,

                // This is necessary to retain for the back-button to work
                mostRecentAttempt: study.createUndoneAttempt(
                  card.mostRecentAttempt.text
                )
              })
            ))
          })
        },
        afterActionNewCardProps: {
          undoable: false
        }
      }
    }
  },
  learn: {
    label: 'Learn',
    cssClass: 'learn',
    title: 'Start studying this card in your reviews, just as if you\'d completed a regular lesson',
    resolve ({ mode, subMode, card, props, isResult }) {
      const isALearnableLessonCard = mode === 'lessons' &&
        ((subMode === 'survey' || subMode === 'placement') ||
          study.cardWasCorrect(card)) &&
        isResult &&
        !card.learningId

      return {
        shouldRender: isALearnableLessonCard,
        shouldHide: false,
        shouldDisable: false,
        onClickDo () {
          study.deleteReview(card, () => {
            study.createLearningDuringStudy(mode, card, props, false)
          })
        },
        afterActionNewCardProps: { complete: true }
      }
    }
  },
  unlearn: {
    label: 'Unlearn',
    cssClass: 'unlearn',
    title: 'Remove this item from your learnings',
    resolve ({ mode, subMode, card, props, isResult }) {
      return {
        label: study.isBurned(card) ? 'Unburn' : 'Unlearn',
        shouldRender: mode === 'lessons' && isResult && card.learningId,
        shouldHide: false,
        shouldDisable: false,
        onClickDo () {
          study.deleteReview(card)
        },
        afterActionNewCardProps: {
          learningId: null,
          skipped: true,
          srsStage: 0
        }
      }
    }
  },
  burn: {
    label: 'Burn',
    cssClass: 'burn',
    title: 'Mark this item as learned (burned)',
    resolve ({ mode, subMode, card, props, isResult }) {
      const isABurnpitunity = mode === 'lessons' &&
        (subMode === 'survey' || subMode === 'placement') &&
        isResult &&
        study.cardWasCorrect(card) &&
        !study.isBurned(card)

      return {
        shouldRender: isABurnpitunity,
        shouldHide: false,
        shouldDisable: false,
        onClickDo () {
          study.deleteReview(card, () => {
            study.createLearningDuringStudy(mode, card, props, true)
          })
        },
        afterActionNewCardProps: { complete: true }
      }
    }
  },
  skip: {
    label: 'Skip',
    cssClass: 'skip',
    title: 'Skip this item and do it some other time',
    disabledTitle: 'This item is already complete',
    resolve ({ mode, subMode, card, props, isResult }) {
      return {
        shouldRender: mode === 'lessons' && (!isResult || !card.complete),
        shouldHide: false,
        shouldDisable: card.complete,
        onClickDo () {
          // TODO
          // if (mode === 'reviews') {
          //   app.reviews.skipCard(card)
          // }
        },
        afterActionNewCardProps: { complete: true, skipped: true }
      }
    }
  },
  block: {
    label: 'Block',
    cssClass: 'block',
    title: 'Prevent this item from appearing in your lessons',
    disabledTitle: 'This item is already blocked',
    resolve ({ mode, subMode, card, props, isResult }) {
      return {
        shouldRender: mode === 'lessons',
        shouldHide: false,
        shouldDisable: card.isExcluded,
        onClickDo () {
          if (isResult) study.deleteReview(card)
          study.excludeItemFromLessons(mode, card, props)
        },
        afterActionNewCardProps: {
          learningId: null,
          complete: true,
          skipped: true,
          isExcluded: true
        }
      }
    }
  }/*,
  unblock: {
    label: 'Unblock',
    cssClass: 'block',
    title: 'Re-allow this item to appear in your lessons',
    disabledTitle: 'This item is already unblocked',
    shouldRender ({ mode, subMode, card, props, isResult }) {
      if (mode === 'lessons' && card.isExcluded) {
        return true
      }
    },
    shouldHide ({ mode, subMode, card, props, isResult }) {
      return card.learningId
    },
    shouldDisable ({ mode, subMode, card, props, isResult }) {
      return !card.isExcluded
    },
    onClickDo ({ mode, subMode, card, props, isResult }) {
      // TODO need an analogue for includeItemFromLessons or a rethink
      study.excludeItemFromLessons(mode, card, props)
    },
    afterActionNewCardProps (mode, subMode, card) {
      return {
        learningId: null,
        complete: true,
        skipped: true,
        isExcluded: true
      }
    }
  } */
}

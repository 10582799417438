import { h } from 'preact'
import * as notify from '../lib/notify'

export default function notification ({ message, type }) {
  if (!message) return null

  return h('div', { class: `notification ${type}` },
    h('span', { class: 'text' }, message),
    h('a', {
      class: 'dismiss',
      ref (node) {
        if (node) {
          setTimeout(() => {
            notify.dismiss(message, type, node.closest('.notification'))
          }, notify.TIME_TO_LIVE)
        }
      },
      onClick (e) { e.preventDefault(); notify.dismiss(message, type, e.target.closest('.notification')) }
    }, '✖︎')
  )
}

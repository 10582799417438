import { h } from 'preact'

// Remember, you wrote this!!
//  npm i -g html-to-preact-h
export default function copyButton () {
  return h('svg', {
    class: 'copy-button',
    width: '40',
    height: '40',
    viewBox: '0 0 40 40',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg'
  },
  h('path', {
    class: 'background',
    'fill-rule': 'evenodd',
    'clip-rule': 'evenodd',
    d: 'M15 14H7V25V30V36H25V25H15V14Z',
    fill: '#4F4F4F',
    'fill-opacity': '0.01'
  }),
  h('path', {
    class: 'foreground',
    d: 'M16 4H33V25H16V4Z',
    fill: '#727272',
    'fill-opacity': '0.01'
  }),
  h('path', {
    class: 'outline',
    d: 'M22.7997 37.9979H8.80131C8.0511 38.0314 7.32374 37.6629 6.79295 36.9805C6.26215 36.298 5.97557 35.3629 6.00164 34.3983V16.4004C5.97557 15.4359 6.26215 14.5007 6.79295 13.8182C7.32374 13.1358 8.0511 12.7673 8.80131 12.8008H14.4007V5.60168C14.3746 4.63713 14.6612 3.70196 15.192 3.0195C15.7228 2.33705 16.4501 1.96858 17.2003 2.0021H31.1987C31.9489 1.96858 32.6763 2.33705 33.2071 3.0195C33.7378 3.70196 34.0244 4.63713 33.9984 5.60168V23.5996C34.024 24.564 33.7373 25.4988 33.2066 26.1812C32.6759 26.8635 31.9488 27.2321 31.1987 27.1992H25.5993V34.3983C25.625 35.3627 25.3383 36.2976 24.8076 36.9799C24.2769 37.6622 23.5498 38.0309 22.7997 37.9979ZM7.80131 15.4004V35.3983H23.7997V27.1992H17.2003C16.4502 27.2321 15.7231 26.8635 15.1924 26.1812C14.6617 25.4988 14.375 24.564 14.4007 23.5996V15.4004H7.80131ZM16.2003 4.60168V24.5996H32.1987V4.60168H16.2003Z',
    fill: '#4F4F4F'
  }))
}

copyButton.clickHandler = (e) => {
  const classList = e.currentTarget.querySelector('.copy-button').classList
  classList.add('clicked')
  setTimeout(() => { classList.remove('clicked') }, 800)
}

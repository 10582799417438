import ListyDoodadView from './listy-doodad'

export default class Definitions extends ListyDoodadView {
  constructor ({ props }) {
    super({
      props,
      thingName: 'definition',
      listTitle: 'Alternate English definitions'
    })
  }
}

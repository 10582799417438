import { h } from 'preact'
import { Link } from 'preact-router/match'
import * as lesson from '../lib/lesson'
import * as xp from '../lib/xp'
import lessonsIconView from './lessons-icon'

export default function navLessonsView ({ progress, lessonsStatus }) {
  return h(Link, {
    href: '/app/lessons',
    activeClassName: 'active',
    title: progress.lessonsCompletedThisWeek < xp.LESSONS_PER_STAR
      ? `${progress.lessonsCompletedThisWeek}/${xp.LESSONS_PER_STAR} lessons completed towards this week's star`
      : `Nice star! You've completed ${progress.lessonsCompletedThisWeek} this week`,
    onClick (e) { lesson.reloadUnlessAlreadyDueToBeReloaded(lessonsStatus) }
  },
  lessonsIconView({ progress }),
  h('div', { class: 'label' }, h('span', {}, 'Lessons'))
  )
}

import * as _ from '../vendor/lodash'

export default function navReviewsEffect (node, progress) {
  const bars = node.querySelectorAll('#review-bars rect')

  _.each(bars, e => e.setAttribute('fill-opacity', 0))

  if (progress.count.reviews >= 1) {
    bars[0].setAttribute('fill-opacity', 1)
  }
  if (progress.count.reviews >= 25) {
    bars[1].setAttribute('fill-opacity', 1)
  }
  if (progress.count.reviews >= 50) {
    bars[2].setAttribute('fill-opacity', 1)
  }
  if (progress.count.reviews >= 100) {
    bars[3].setAttribute('fill-opacity', 1)
  }
  if (progress.count.reviews >= 200) {
    bars[4].setAttribute('fill-opacity', 1)
  }
  if (progress.count.reviews >= 300) {
    bars[5].setAttribute('fill-opacity', 1)
  }
}

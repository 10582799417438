import { h } from 'preact'
import { Link } from 'preact-router/match'
import images from '../images'
import navHomeView from './nav-home'
import navLessonsView from './nav-lessons'
import navReviewsView from './nav-reviews'

export default function navBar ({ user, progress, reviewsStatus, lessonsStatus, thisIsTheFakeOne }) {
  return h('nav', { class: thisIsTheFakeOne ? 'fake' : 'real' },
    h('ul', {},
      h('li', {},
        navHomeView({ progress })
      ),
      h('li', {},
        navLessonsView({ progress, lessonsStatus })
      ),
      h('li', {},
        navReviewsView({ progress, reviewsStatus })
      ),
      h('li', {},
        h(Link, { href: '/app/search', activeClassName: 'active' },
          h('div', { class: 'icon' }, h('div', {}, '🔍')),
          h('div', { class: 'label' }, h('span', {}, 'Search'))
        )),
      h('li', { class: 'push' },
        h(Link, { href: '/app/account', activeClassName: 'active' },
          h('div', { class: 'image' },
            h('img', { src: images['account-icon.png'], alt: 'Account icon' })),
          h('div', { class: 'label' }, h('span', {}, 'Account'))
        ))
    )
  )
}

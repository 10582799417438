import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as study from '../lib/study'
import * as util from '../lib/util'

export default function reviewStatsView ({
  cards, reviewsStatistics, answered, correct, incorrect, progress,
  userSessionInactivityIntervals
}) {
  const activeDuration = util.durationBetweenLessInactivity(
    _.get(reviewsStatistics, 'startedAt'),
    _.get(reviewsStatistics, 'lastActionAt'),
    userSessionInactivityIntervals
  )

  return h('div', { class: 'stats' },
    h('div', {},
      h('h4', {}, 'Statistics'),
      h('table', {},
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Answered'),
          h('td', { class: 'value' }, `${_.size(answered)} items`)
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Accuracy'),
          h('td', { class: 'value' }, `${study.percentCorrect(cards)}`)
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Experience'),
          h('td', { class: 'value' }, `${_.sumBy(correct, c => c.xpEarned || 0)} XP`)
        ),
        h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Time elapsed'),
          h('td', { class: 'value' }, util.durationSinceDescription(activeDuration))
        ),
        _.size(answered) > 0 && h('tr', { class: 'stat' },
          h('td', { class: 'category' }, 'Time per item'),
          h('td', { class: 'value' },
            `${util.durationSinceDescription(activeDuration / _.size(answered))}`
          )
        )
      )
    )
  )
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as hotkeys from '../lib/hotkeys'
import * as route from '../lib/route'
import * as shame from '../lib/shame'
import * as study from '../lib/study'
import * as warez from 'warez'
import copyButtonView from './copy-button'
import notificationAlternateView from './notification-alternate'
import meaningView from './meaning'
import priorAlternateMatchesView from './prior-alternate-matches'
import studyActionsView from './study-actions'
import studyHeaderView from './study-header'
import studyOutcomeView from './study-outcome'
import studyRewardNotificationView from './study-reward-notification'
import synonymsView from './synonyms'

export default function studyView (props) {
  hotkeys.setScope('study')

  const { mode, isResult, isQuestion } = study.detectMode(props.path)
  const user = props.user
  const cards = props[mode]
  const subMode = props[`${mode}SubMode`]
  const card = _.get(cards, props.cardId)
  const currentAttempt = study.currentAttempt(isResult, card)
  if (!user || !card || !currentAttempt) return route.redirectOnNextTick(`/app/${mode}`)
  const isResultLoaded = isResult && currentAttempt.outcome !== 'pending'
  const wasUndone = isResult && currentAttempt.outcome === 'undone'

  return h('div', {
    id: 'study',
    class: `overrides-background-color ${mode} ${card.item.type} ${isResult ? 'result' : ''}`
  },
  studyHeaderView(cards, mode, props),
  notificationAlternateView(props),
  meaningView({ card }),
  h('div',
    {
      class: `input-area ${currentAttempt.outcome}
        ${isResultLoaded ? 'handle-enter' : ''}`,
      onEvent () { study.nextPage(mode, cards, props) },
      'data-warez': warez.currentDevice()

    },
    studyRewardNotificationView(isResult ? card : props.previousCard, user),
    isResultLoaded && !wasUndone ? copyButtonView(card.item.text, 'left') : null,
    h('input', {
      ref (node) {
        if (!node) return
        if (isResultLoaded) {
          node.blur()
        } else if (isQuestion && !props.infoShouldBeDisplayed) {
          shame.focusKeyboardWithoutPushingPage(node)
        }
      },
      name: 'answer',
      type: 'text',
      placeholder: study.inputPlaceholder(card),
      class: `${isResult ? 'answered' : 'handle-enter'} ${currentAttempt.outcome} ${study.questionType(card)}`,
      disabled: isResultLoaded || (isResult && !user.skipCorrectAnswers && shame.isIos()),
      readonly: isResult,
      [isResult ? 'value' : 'not-the-real-value']: currentAttempt.text,
      autocomplete: 'off',
      autocorrect: false,
      autocapitalize: 'none',
      spellcheck: false,
      lang: study.questionType(card) === 'production' ? 'ja' : 'en',
      tabindex: 0,
      autofocus: true,
      onEvent (e) { study.handleAnswer(card, e.target, mode, subMode, cards, props) },
      onTouchstart: e => {
        const node = e.target
        if (isQuestion && !node.classList.contains('already-been-touched')) {
          shame.focusKeyboardWithoutPushingPage(node)
          node.classList.add('already-been-touched')
        } else if (isResult && shame.isIos()) {
          e.preventDefault()
          node.removeAttribute('disabled')
          node.removeAttribute('readonly')
          node.focus()
          node.setSelectionRange(0, 9999)
          node.setAttribute('disabled', '')
          node.setAttribute('readonly', '')
        }
      }
    }),
    isQuestion || isResultLoaded
      ? h('a', {
          class: 'emoji right',
          onClick (e) {
            if (isQuestion) {
              study.handleAnswer(card, e.target.parentNode.querySelector('input'), mode, subMode, cards, props)
            } else {
              study.nextPage(mode, cards, props)
            }
          }
        }, '👉')
      : null
  ),
  studyActionsView({ mode, subMode, card, user, isQuestion, isResult, isResultLoaded, props }),
  isQuestion
    ? [
        synonymsView({ card }),
        priorAlternateMatchesView({ card })
      ]
    : [
        studyOutcomeView({ mode, subMode, card, isResultLoaded, wasUndone, props }),
        shame.userHasTouchedTheirScreen() && isResultLoaded
          ? h('a', {
              class: `hovery bottom right emoji hide-when-typing
          ${shame.needsVerticalLiftBecauseIphoneXEnterButtonIsTooHigh()}`,
              onClick () { study.nextPage(mode, cards, props) }
            }, '👉')
          : null
      ]
  )
}

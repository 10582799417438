import { h } from 'preact'
import * as _ from '../vendor/lodash'

export default function progressBar ({ progress }) {
  return h('div', { class: 'progress-bar' },
    _.map(['apprentice', 'guru', 'master', 'enlightened', 'burned'], bucket =>
      h('a', { class: `rank ${bucket}`, href: `/app/learnings/${bucket}`, tabindex: 0 },
        h('div', { class: 'datum' }, progress.count[bucket]),
        h('div', { class: 'label' }, _.capitalize(bucket))
      )
    )
  )
}

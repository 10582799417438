import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as hotkeys from '../lib/hotkeys'

export default function () {
  const bindings = hotkeys.bindings()
  const scopeLabels = {
    menus: 'Navigating Menus',
    study: 'During Lessons & Reviews',
    all: 'Everywhere'
  }

  return h('div', { class: 'hotkeys' },
    h('h1', {}, 'Keyboard shortcuts'),
    _.map(_.groupBy(bindings, 'scope'), (bindings, scope) =>
      [
        h('h2', {}, scopeLabels[scope] || _.capitalize(scope)),
        h('div', { class: 'binding-table' },
          _.map(bindings, (binding) =>
            h('div', { class: 'binding' },
              h('span', { class: 'description' }, binding.name),
              h('span', { class: 'hotkey' }, binding.bind)
            )
          )
        )
      ]
    )
  )
}

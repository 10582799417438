import * as _ from '../vendor/lodash'
import linkView from './link'

export default function itemLink (item, options) {
  options = _.defaults(options, { obfuscated: false, children: [] })
  return linkView(
    `/app/items/${item.id}`,
    [item.text, ...options.children],
    {
      class: `item ${item.type} ${options.obfuscated ? 'obfuscated' : ''}`,
      tabindex: 0,
      lang: 'ja',
      onClick (e) {
        if (e.target.classList.contains('obfuscated')) {
          e.stopPropagation()
          e.preventDefault()
          e.target.classList.remove('obfuscated')
        }
      },
      title: item.readings ? item.readings[0] : null
    }
  )
}

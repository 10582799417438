import * as _ from '../vendor/lodash'
import * as arrayUtil from './array-util'
import * as propUtil from './prop-util'
import * as store from './store'
import render from './render'

export const TIME_TO_LIVE = 5000

export function absorbStoredMessages (type) {
  const meta = document.querySelector(`meta[name="${type}-messages"]`)

  let storedMessages = []
  if (meta && meta.getAttribute('content')) {
    storedMessages = storedMessages.concat(arrayUtil.as(JSON.parse(meta.getAttribute('content'))))
    meta.removeAttribute('content')
  }

  const queuedMessage = popQueuedMessage(type)
  if (queuedMessage) {
    storedMessages = storedMessages.concat(queuedMessage)
  }

  return storedMessages
}

export function setQueuedMessage (message, type = 'info') {
  const key = type === 'info' ? 'ONE_TIME_INFO_MESSAGE' : 'ONE_TIME_ERROR_MESSAGE'
  store.set(key, message)
}

export function popQueuedMessage (type) {
  const key = type === 'info' ? 'ONE_TIME_INFO_MESSAGE' : 'ONE_TIME_ERROR_MESSAGE'
  return store.pop(key)
}

export function dismiss (message, type, node) {
  node.classList.add('fade-out')

  // Clean up and remove the element after it's been animated away
  setTimeout(() => {
    render(propUtil.replace(`${type}Messages`, messages =>
      _.without(messages, message)
    ))
  }, 325)
}

export function append (message, type = 'error') {
  return propUtil.replace(`${type}Messages`, val =>
    val ? _.uniq(val.concat(message)) : [message]
  )
}

import * as xp from '../lib/xp'
import dynamicOpacityForView from './dynamic-opacity-for'

export default function navHomeXpEffectView (node, progress) {
  dynamicOpacityForView(
    node.querySelectorAll('#xp-sections path'),
    xp.levelCompletionPercent(progress.level, progress.totalXp),
    100,
    progress.level >= xp.MAX_LEVEL
  )
}

import accountIcon from './account-icon.png'
import addToReviews from './add-to-reviews.svg'
import alreadyInReviews from './already-in-reviews.svg'
import appsBing from './apps/bing.png'
import appsGoogleNews from './apps/google-news.png'
import appsGooJisho from './apps/goo-jisho.png'
import appsJapanese from './apps/japanese.png'
import appsJisho from './apps/jisho.png'
import appsMidori from './apps/midori.png'
import appsTatoeba from './apps/tatoeba.png'
import appsWanikani from './apps/wanikani.png'
import appsYouglish from './apps/youglish.png'
import infoButton from './info-button.svg'
import infoClose from './info-close.svg'
import loginHeader from './login-header.svg'
import productionBadge from './production-badge.svg'
import rankDown from './rank-down.svg'
import rankUp from './rank-up.svg'
import recognitionBadge from './recognition-badge.svg'
import searchCancel from './search-cancel.svg'
import undoAddToReviews from './undo-add-to-reviews.svg'

export default {
  'account-icon.png': accountIcon,
  'add-to-reviews.svg': addToReviews,
  'already-in-reviews.svg': alreadyInReviews,
  'apps/bing.png': appsBing,
  'apps/google-news.png': appsGoogleNews,
  'apps/goo-jisho.png': appsGooJisho,
  'apps/japanese.png': appsJapanese,
  'apps/jisho.png': appsJisho,
  'apps/midori.png': appsMidori,
  'apps/tatoeba.png': appsTatoeba,
  'apps/wanikani.png': appsWanikani,
  'apps/youglish.png': appsYouglish,
  'info-button.svg': infoButton,
  'info-close.svg': infoClose,
  'login-header.svg': loginHeader,
  'production-badge.svg': productionBadge,
  'rank-down.svg': rankDown,
  'rank-up.svg': rankUp,
  'recognition-badge.svg': recognitionBadge,
  'search-cancel.svg': searchCancel,
  'undo-add-to-reviews.svg': undoAddToReviews
}

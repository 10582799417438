import { route } from 'preact-router'
import * as hotkeys from './hotkeys'
import * as _ from '../vendor/lodash'
import * as auth from './auth'
import * as css from './css'
import * as notify from './notify'
import * as pwa from './pwa'
import render from './render'
import * as version from './version'
import * as windowUtil from './window-util'

export const PATHS = {
  unauthorized: [
    '/app/login',
    '/app/sign_up',
    '/app/reset_password',
    '/app/choose_new_password'
  ],
  navFree: [
    /\/app\/lessons\/study\//,
    /\/app\/reviews\/study\//,
    '/app/login',
    '/app/sign_up',
    '/app/login',
    '/app/sign_up',
    '/app/reset_password',
    '/app/choose_new_password',
    '/app/account_setup_in_progress'
  ],
  clearFromHistory: [
    '/app/loading',
    '/app/account_ready_status'
  ]
}

// This is a state leak that necessitated being pulled in here to get all the files extracted (package tangling)
let routerInstance
export function setPrimaryRouterInstanceEwwGross (r) {
  routerInstance = r
}

export function matches (paths, testPath = window.location.pathname) {
  return _.some(paths, path => {
    if (_.isRegExp(path)) {
      return path.test(testPath)
    } else {
      return testPath === path
    }
  })
}

export function isDifferentPage (a) {
  const b = window.location.pathname
  const pagePattern = /([^/]+\/)/g
  return _.startsWith(a, '/app') &&
    _.startsWith(b, '/app') &&
    !_.isEqual(a.match(pagePattern), b.match(pagePattern))
}

export function redirect (path, replaceHistory = false) {
  route(path,
    _.includes(PATHS.clearFromHistory, window.location.pathname) ||
    replaceHistory
  )
}

export function redirectOnNextTick (path, replaceHistory) {
  _.defer(() => redirect(path, replaceHistory))
}

export function andRerender (path, props) {
  if (routerInstance) {
    routerInstance.setState({ url: path })
  } else {
    route(path)
  }
  if (_.includes(PATHS.clearFromHistory, window.location.pathname)) {
    window.history.replaceState(null, null, path)
  } else {
    window.history.pushState(null, null, path)
  }

  render(props)
}

export function fallback (path, message, type = 'error') {
  _.defer(() => {
    andRerender(path, notify.append(message, type))
  })
}

export function onChange ({ router, url, previous }) {
  hotkeys.setScope('menus')
  auth.redirectIfNotAuthorized()
  version.reloadPageIfOutdated(url)
  pwa.appendUrlToPersistedHistory(url)
  css.overridePageBackgroundColor()
  if (isDifferentPage(previous)) {
    windowUtil.scrollToTop()
  }
}

export function onHomePage () {
  return !/\/app\/+/.test(window.location.pathname)
}

export const currentPathContains = (pathSnippet) =>
  _.includes(window.location.pathname, pathSnippet)

export function join (...pathSegments) {
  return _.reduce(pathSegments, (memo, segment, i) => {
    if (i === 0) {
      return segment
    } else if (!segment && i === pathSegments.length - 1) {
      return memo
    } else {
      return `${memo}/${segment}`
    }
  }, '')
}

export function redirectToUnsupportedBrowserPage () {
  window.location = '/unsupported_browser'
}

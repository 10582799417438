import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as propUtil from '../lib/prop-util'
import * as study from '../lib/study'
import svgTextView from '../view/svg-text'

export default function studyActionsView ({ mode, subMode, card, user, isQuestion, isResult, isResultLoaded, props }) {
  const isPending = isResult && !isResultLoaded
  if (isPending) return h('div', { class: 'card-actions' })

  const actions = study.resolveCardActions({ mode, subMode, card, user, props, isResult })

  return h('div', { class: 'card-actions' },
    _.map(actions, (action) => {
      if (!action.shouldRender) return

      return h('a', {
        class: `action ${action.cssClass} ${action.shouldHide ? 'invisible' : ''}`,
        disabled: action.shouldDisable,
        title: action.shouldDisable
          ? (action.disabledTitle || 'This action doesn\'t apply in this case')
          : action.title,
        onClick (e) {
          e.preventDefault()
          if (action.shouldDisable) return

          action.onClickDo()
          let nextProps
          if (action.afterActionNewCardProps) {
            nextProps = propUtil.mergeAndSave({
              [mode]: {
                [card.randomUrlPath]: _.extend({}, card,
                  action.afterActionNewCardProps
                )
              }
            })
          } else {
            nextProps = props
          }
          if (!action.dontNavigateToNextCard) {
            study.nextPage(mode, nextProps[mode], nextProps)
          }
        }
      },
      h('span', { class: 'label' }, svgTextView(action.label)))
    })
  )
}

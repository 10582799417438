import * as _ from './vendor/lodash'
import * as auth from './lib/auth'
import * as beat from './lib/beat'
import * as basicInfo from './lib/basic-info'
import * as eventUtil from './lib/event-util'
import * as form from './lib/form'
import * as hotkeys from './lib/hotkeys'
import * as home from './lib/home'
import * as monitor from './lib/monitor'
import * as propUtil from './lib/prop-util'
import * as pwa from './lib/pwa'
import render from './lib/render'
import * as route from './lib/route'
import * as shame from './lib/shame'
import * as store from './lib/store'
import * as version from './lib/version'
import * as windowUtil from './lib/window-util'
import './lib/development-debugger'

export default function init () {
  if (windowUtil.isBrowserUnsupported()) return route.redirectToUnsupportedBrowserPage()
  // Set up monitoring, assurances, and long-running hooks
  const monitorUser = monitor.start()
  version.enforceCompatibleStorage()
  pwa.initialize()
  eventUtil.initEventForwarding(eventUtil.FORWARDING_RULES)
  shame.waitForFirstTouch()
  version.recordAssetVersions()
  hotkeys.init()

  const userId = auth.getUserId()
  const props = propUtil.init(_.extend(store.load(), {
    oneTimeRerenderParams: form.getRerenderParams()
  }))
  if (userId) {
    monitorUser(userId)
    route.onHomePage() ? home.load() : basicInfo.load()
  } else {
    auth.redirectIfNotAuthorized()
    render(props)
  }
  beat.listenForUserActivity()
}

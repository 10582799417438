import { h } from 'preact'
import * as _ from '../vendor/lodash'

export default function priorAlternateMatchesView ({ card }) {
  if (_.isEmpty(card.priorAlternateMatches)) return null
  return h('div', { class: 'synonyms' },
    h('h4', {}, "Hint: it's not..."),
    h('div', { class: 'items' }, card.priorAlternateMatches.join(' • '))
  )
}

import { h } from 'preact'

export const LISTS = [
  {
    code: 'n5vocab',
    name: 'JLPT N5 Vocabulary',
    description: [
      'An unofficial vocabulary list for the introductory N5 level ',
      h('a', { href: 'https://www.jlpt.jp/e/' }, 'JLPT exam '),
      ' (',
      h('a', { href: 'http://www.tanos.co.uk/jlpt/jlpt5/vocab/' }, 'source'),
      ')'
    ]
  },
  {
    code: 'n4vocab',
    name: 'JLPT N4 Vocabulary',
    description: [
      'An unofficial vocabulary list for the intermediate N4 level ',
      h('a', { href: 'https://www.jlpt.jp/e/' }, 'JLPT exam '),
      ' (',
      h('a', { href: 'http://www.tanos.co.uk/jlpt/jlpt4/vocab/' }, 'source'),
      ')'
    ]
  },
  {
    code: 'n3vocab',
    name: 'JLPT N3 Vocabulary',
    description: [
      'An unofficial vocabulary list for the intermediate N3 level ',
      h('a', { href: 'https://www.jlpt.jp/e/' }, 'JLPT exam '),
      ' (',
      h('a', { href: 'http://www.tanos.co.uk/jlpt/jlpt3/vocab/' }, 'source'),
      ')'
    ]
  },
  {
    code: 'n2vocab',
    name: 'JLPT N2 Vocabulary',
    description: [
      'An unofficial vocabulary list for the intermediate N2 level ',
      h('a', { href: 'https://www.jlpt.jp/e/' }, 'JLPT exam '),
      ' (',
      h('a', { href: 'http://www.tanos.co.uk/jlpt/jlpt2/vocab/' }, 'source'),
      ')'
    ]
  },
  {
    code: 'n1vocab',
    name: 'JLPT N1 Vocabulary',
    description: [
      'An unofficial vocabulary list for the advanced N1 level ',
      h('a', { href: 'https://www.jlpt.jp/e/' }, 'JLPT exam '),
      ' (',
      h('a', { href: 'http://www.tanos.co.uk/jlpt/jlpt1/vocab/' }, 'source'),
      ')'
    ]
  },
  {
    code: 'bccwj',
    name: 'Top 10,000 Words',
    description: [
      `The most frequent words in contemporary written Japanese, derived
        from the Balanced Corpus of Contemporary Written Japanese compiled by
        NINJAL and described `,
      h('a', { href: 'https://pj.ninjal.ac.jp/corpus_center/bccwj/en/freq-list.html' }, 'here'),
      '.'
    ]
  },
  {
    code: 'wkkanji10',
    name: 'WaniKani Kanji (1-10)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 1-10 kanji.'
    ]
  },
  {
    code: 'wkkanji20',
    name: 'WaniKani Kanji (11-20)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 11-20 kanji.'
    ]
  },
  {
    code: 'wkkanji30',
    name: 'WaniKani Kanji (21-30)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 21-30 kanji.'
    ]
  },
  {
    code: 'wkkanji40',
    name: 'WaniKani Kanji (31-40)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 31-40 kanji.'
    ]
  },
  {
    code: 'wkkanji50',
    name: 'WaniKani Kanji (41-50)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 41-50 kanji.'
    ]
  },
  {
    code: 'wkkanji60',
    name: 'WaniKani Kanji (51-60)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 51-60 kanji.'
    ]
  },
  {
    code: 'wkvocabulary10',
    name: 'WaniKani Vocab (1-10)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 1-10 vocabulary.'
    ]
  },
  {
    code: 'wkvocabulary20',
    name: 'WaniKani Vocab (11-20)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 11-20 vocabulary.'
    ]
  },
  {
    code: 'wkvocabulary30',
    name: 'WaniKani Vocab (21-30)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 21-30 vocabulary.'
    ]
  },
  {
    code: 'wkvocabulary40',
    name: 'WaniKani Vocab (31-40)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 31-40 vocabulary.'
    ]
  },
  {
    code: 'wkvocabulary50',
    name: 'WaniKani Vocab (41-50)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 41-50 vocabulary.'
    ]
  },
  {
    code: 'wkvocabulary60',
    name: 'WaniKani Vocab (51-60)',
    description: [
      h('a', { href: 'https://wanikani.com' }, 'WaniKani'),
      '\'s level 51-60 vocabulary.'
    ]
  }
]

export const LIST_MODES = [
  {
    id: 'lessons',
    name: 'Regular Lessons'
  },
  {
    id: 'placement',
    name: 'Placement Test'
  },
  {
    id: 'survey',
    name: 'Survey Content'
  }
]

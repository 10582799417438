import * as kuromoji from 'kuromoji'

let __tokenizer
export function instance (cb) {
  if (__tokenizer) {
    cb(null, __tokenizer)
  } else {
    kuromoji.builder({ dicPath: '/kuromoji-dictionaries' }).build(function (err, tokenizer) {
      __tokenizer = tokenizer
      cb(err, tokenizer)
    })
  }
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import images from '../images'
import appLinkView from './app-link'

export default function referencesView ({ item }) {
  if (!item) return
  return h('div', { class: 'references' },
    h('h2', {}, 'Open in:'),
    h('div', { class: 'apps' },
      _.map([
        {
          title: 'WaniKani',
          url: item.wanikaniAppUrl,
          imgUrl: images['apps/wanikani.png']
        },
        {
          title: 'Midori',
          url: `midori://search?text=${item.text}`,
          imgUrl: images['apps/midori.png'],
          appStoreUrl: 'https://itunes.apple.com/us/app/midori-japanese-dictionary/id385231773?mt=8'
        },
        {
          title: 'Japanese',
          url: `japanese://entry/${item.text}`,
          imgUrl: images['apps/japanese.png'],
          appStoreUrl: 'https://itunes.apple.com/us/app/japanese/id290664053?mt=8'
        },
        {
          title: 'Jisho',
          url: `https://jisho.org/search/${item.text}${item.type === 'kanji' ? '%20%23kanji' : ''}`,
          imgUrl: images['apps/jisho.png']
        },
        {
          title: 'Goo 辞書',
          url: `https://dictionary.goo.ne.jp/srch/all/${item.text}/m0u/`,
          imgUrl: images['apps/goo-jisho.png']
        },
        {
          title: 'Tatoeba',
          url: `https://tatoeba.org/eng/sentences/search?query=%22${item.text}%22&from=jpn&to=eng&orphans=no&unapproved=no&trans_filter=limit&trans_to=eng&sort=random`,
          imgUrl: images['apps/tatoeba.png']
        },
        {
          title: 'Bing Images',
          url: `http://www.bing.com/images/search?q=${item.text}`,
          imgUrl: images['apps/bing.png']
        },
        {
          title: 'Google News',
          url: `https://www.google.com/search?lr=lang_ja&cr=countryJP&tbm=nws&q=${item.text}`,
          imgUrl: images['apps/google-news.png']
        },
        {
          title: 'YouGlish',
          url: `https://youglish.com/pronounce/${item.text}/japanese?`,
          imgUrl: images['apps/youglish.png']
        }
      ], appLinkView)
    )
  )
}

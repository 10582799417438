import { render, h, Component } from 'preact'
import * as propUtil from './prop-util'
import mainView from '../view/main'

let appViewSingleton
class App extends Component {
  constructor ({ props }) {
    super(props)
    appViewSingleton = this
    this.state = { props }
  }

  render () {
    return h(mainView, this.state.props) // eslint-disable-line
  }
}

export default (props) => {
  if (!appViewSingleton) {
    render(
      h(App, {
        props: propUtil.withAbsorbedStoredProps(props)
      }),
      document.body
    )
    if (props.oneTimeRenderCallback) props.oneTimeRenderCallback(props)
  } else {
    appViewSingleton.setState({ props }, () => {
      if (props.oneTimeRenderCallback) props.oneTimeRenderCallback(props)
    })
    appViewSingleton.forceUpdate()
  }
  propUtil.eraseOneTimeProps()
}

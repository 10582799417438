import { h } from 'preact'
import * as audio from '../lib/audio'

export default function itemRecordingLink ({ recordingUrl }) {
  if (!recordingUrl) return
  return h('a', {
    class: 'recording',
    href: recordingUrl,
    onClick (e) {
      e.preventDefault()
      e.stopPropagation()
      audio.play(recordingUrl)
    }
  }, ' ⏯')
}

import * as humps from 'humps'
import * as sockem from 'sockem'
import * as _ from '../vendor/lodash'

import itemLinkView from '../view/item-link'

import * as anim from './anim'
import autoplayAudio from './autoplay-audio'
import * as css from './css'
import * as itemUtil from './item-util'
import * as net from './net'
import * as notify from './notify'
import * as propUtil from './prop-util'
import render from './render'
import rerender from './rerender'
import * as route from './route'
import * as store from './store'
import * as stringUtil from './string-util'
import studyActions from './study-actions'
import * as util from './util'
import * as windowUtil from './window-util'
import * as version from './version'

const LESSON_TYPES = {
  kanjiRecognition: 'kanji_recognition',
  kanjiProduction: 'kanji_production',
  vocabularyRecognition: 'vocabulary_recognition',
  vocabularyProduction: 'vocabulary_production'
}
let wipCardIds = []

export function rankForSrsStage (stage) {
  if (stage <= 0) {
    return 'Initiate'
  } else if (stage <= 4) {
    return 'Apprentice'
  } else if (stage <= 6) {
    return 'Guru'
  } else if (stage <= 7) {
    return 'Master'
  } else if (stage <= 8) {
    return 'Enlightened'
  } else if (stage <= 9) {
    return 'Burned'
  }
}

export function modeFromPath (path) {
  if (_.startsWith(path, '/app/lessons')) {
    return 'lessons'
  } else {
    return 'reviews'
  }
}

export function detectMode (path) {
  const isResult = _.endsWith(path, 'result')

  return {
    mode: modeFromPath(path),
    isResult,
    isQuestion: !isResult
  }
}

export const currentAttempt = (isResult, card) =>
  card && isResult
    ? card.mostRecentAttempt
    : { outcome: '', text: '' }

export const generateRandomCardUrlPath = () =>
  window.crypto
    .getRandomValues(new Uint32Array(2))
    .reduce((s, i) => s + i.toString(36), '')

export function start (form, mode = 'lessons', options = {}) {
  route.redirect('/app/loading')
  net.post(`/api/${mode}`,
    paramsFor(form, mode, options)
  ).then((res) => {
    begin(mode, options.subMode, res)
  })
}

export function begin (mode, subMode, res) {
  // 0. Overwrite res[mode] by plucking the IDs out to make it an object.
  //    TODO: stop needing to do this.
  res[mode] = _.reduce(res[mode], (result, card) => {
    const randomUrlPath = generateRandomCardUrlPath()
    result[randomUrlPath] = _.extend(card, { randomUrlPath })
    return result
  }, {})

  // 1. Wipe the current lessons/reviews
  reset(mode)

  // 2. Merge in the new lessons/reviews and save them
  const props = propUtil.mergeAndSave(_.extend({}, res, {
    [`${mode}Statistics`]: {
      startedAt: new Date(),
      lastActionAt: new Date()
    },
    [`${mode}SubMode`]: subMode,
    reviewsStatus: {
      isStale: true
    }
  }))

  // 3. Connect to the actioncable to save the user a ~200ms startup
  sockem.subscribe()

  // 4. Render:
  nextPage(mode, res[mode], props)
}

export function reset (mode) {
  wipCardIds = []
  propUtil.remove(mode, `${mode}SubMode`, `${mode}Statistics`)
}

export function paramsFor (form, mode, options = {}) {
  if (options.ids) {
    return { items: options.ids, subMode: options.subMode }
  } else if (mode === 'lessons') {
    return {
      subMode: options.subMode,
      min_srs_stage_for_lessons: parseInt(
        form.querySelector('select#min_srs_stage_for_lessons').value, 10),
      lesson_strategy: form.querySelector('select#lesson_strategy').value,
      wanikani_lessons_include_recognition: form.querySelector('#wanikani_lessons_include_recognition').checked
    }
  } else {
    return options
  }
}

export function percentCorrect (cards) {
  const correctCount = _.filter(cards, card =>
    card.complete && !card.failedReview
  ).length
  const attemptedCount = _.filter(cards, card =>
    card.complete || card.failedReview
  ).length

  return util.percentOf(correctCount, attemptedCount)
}

export function createPendingAttempt (answer) {
  return {
    text: answer,
    outcome: 'pending',
    alternateMatch: null
  }
}

export function createUndoneAttempt (answer) {
  return {
    text: answer,
    outcome: 'undone',
    alternateMatch: null
  }
}

export function handleAnswer (card, node, mode, subMode, cards, props) {
  const answer = node ? node.value.trim() : ''
  const type = questionType(card)

  if (_.isEmpty(answer)) {
    return anim.invalidAnswer(node)
  } else if (type === 'production' && /[A-Za-z]/.test(answer)) {
    return anim.invalidAnswer(node, 'Oops! We only accept 漢字 and かな!')
  } else if (type === 'production' && card.item.type === 'kanji' && (answer.length > 1 || util.isKana(answer))) {
    return anim.invalidAnswer(node, 'We\'re expecting a kanji!')
  } else if (type === 'recognition' && util.isJapanese(answer)) {
    return anim.invalidAnswer(node, 'We\'re expecting English!')
  } else {
    const sanitizedAnswer = type === 'production'
      ? util.stripNonJapaneseCharacters(answer)
      : answer

    renderPendingResult(mode, card, sanitizedAnswer)
    submitAnswer(mode, subMode, card, sanitizedAnswer, cards)
    autoplayAudio(mode, subMode, card, props.user, sanitizedAnswer)
  }
}

export function renderPendingResult (mode, card, answer) {
  route.andRerender(
    cardResultPath(mode, card),
    propUtil.merge({
      [mode]: {
        [card.randomUrlPath]: {
          mostRecentAttempt: createPendingAttempt(answer)
        }
      },
      reviewsStatus: {
        isStale: true
      }
    })
  )
}

export function submitAnswer (mode, subMode, card, answer, cards) {
  let start = null
  if (window.profile === true) {
    start = new Date().getTime()
    console.log('Start answer request', mode, card.id, card.lessonType, answer)
  }
  sockem.request({
    id: card.id,
    lesson_type: card.lessonType,
    answer,
    mode,
    sub_mode: subMode
  }, (res) => {
    if (window.profile === true) {
      console.log('Finish answer request', `${new Date().getTime() - start}ms`, mode, card.id, card.lessonType, answer)
    }
    handleAnswerResult({
      randomUrlPath: card.randomUrlPath,
      mode,
      subMode,
      res
    })
  })
}

export function handleAnswerResult ({ randomUrlPath, mode, subMode, res }) {
  version.handleVersionDiscrepanciesInWs(res)

  if (res.error_message) {
    route.andRerender(`/app/${mode}`, notify.append(res.error_message, 'error'))
  } else {
    const props = updatePropsFromAnswerResult(randomUrlPath, mode, res)
    const card = props[mode][randomUrlPath]

    renderAfterAnswerResult(card, mode, subMode, props)
  }
}

export function updatePropsFromAnswerResult (randomUrlPath, mode, res) {
  return _.flow(
    props => flagAlternativeMatches(mode, randomUrlPath, res, props),
    props => markAlternateMatchCompletionsCorrect(mode, res, props),
    props => store.save(props)
  )(mergeInPropsFromAnswerResult(randomUrlPath, mode, res))
}

export const mergeInPropsFromAnswerResult = (randomUrlPath, mode, res) =>
  propUtil.merge({
    [mode]: {
      [randomUrlPath]: humps.camelizeKeys(res.result)
    },
    progress: humps.camelizeKeys(res.progress),
    user: humps.camelizeKeys(res.user)
  })

export function clearOutdatedCards () {
  const activeReviewIds = _.map(_.reject(propUtil.get('reviews'), 'complete'), 'id')
  const activeLessonIds = _.map(_.reject(propUtil.get('lessons'), 'complete'), 'id')

  if (!_.isEmpty(activeReviewIds) || !_.isEmpty(activeLessonIds)) {
    net.get(`/api/studies/outdated?${net.objectToQueryParams({
      reviews: activeReviewIds,
      lessons: activeLessonIds
    })}`, {
      ignoreFailure: true
    }).then(res => {
      if (!res || (_.isEmpty(res.outdatedReviews) && _.isEmpty(res.outdatedLessons))) return

      cullOutdatedCardsFromProps('lessons', res.outdatedLessons)
      cullOutdatedCardsFromProps('reviews', res.outdatedReviews)

      rerender()
    })
  }
}

export function cullOutdatedCardsFromProps (mode, outdated) {
  if (_.isEmpty(outdated)) return
  const cards = propUtil.get(mode)

  propUtil.replaceAndSave(mode, _.transform(cards, (o, card, randomUrlPath) => {
    o[randomUrlPath] = _.includes(outdated, card.id)
      ? _.extend({}, card, { complete: true, skipped: true })
      : card
  }, {}))
}

export function flagAlternativeMatches (mode, randomUrlPath, res, props) {
  const result = _.get(res, 'result.most_recent_attempt.outcome')
  const text = _.get(res, 'result.most_recent_attempt.text')

  if (_.startsWith(result, 'alternate_match') && text) {
    const priorAlternateMatches = _.get(props, `${mode}.${randomUrlPath}.priorAlternateMatches`, [])

    props[mode][randomUrlPath].priorAlternateMatches = _.uniq(priorAlternateMatches.concat(text))
  }

  return props
}

export function markAlternateMatchCompletionsCorrect (mode, res, props) {
  const result = _.get(res, 'result.most_recent_attempt.outcome')
  if (result !== 'alternate_match_completion') return props
  const alternateMatch = _.get(res, 'result.most_recent_attempt.alternate_match')
  const altCompletion = _.get(res, 'result.alternate_match_review_completion')
  const unfinishedReviewForMatch = _.find(props[mode], card =>
    alternateMatch.id === card.item.id &&
      altCompletion.lesson_type === card.lessonType &&
      !card.complete
  )
  if (!unfinishedReviewForMatch) return props
  props[mode][unfinishedReviewForMatch.randomUrlPath] = _.extend({}, unfinishedReviewForMatch, {
    complete: true,
    learningId: altCompletion.learning_id,
    lessonType: altCompletion.lesson_type,
    srsStage: altCompletion.srs_stage,
    bucketChanged: altCompletion.bucket_changed,
    bucketChangeDirection: altCompletion.bucket_change_direction,
    mostRecentAttempt: {
      text: altCompletion.answer,
      outcome: altCompletion.outcome
    }
  })
  return props
}

export const randomUrlPathFor = (id, cards) =>
  _.get(_.find(cards, { id }), 'randomUrlPath')

export function renderAfterAnswerResult (card, mode, subMode, props) {
  if (skipResultsPage(card, mode, subMode, props.user) && mode !== 'survey') {
    nextPage(mode, props[mode], _.extend({
      previousCard: card
    }, props))
  } else {
    render(props)
  }
}

export const skipResultsPage = (card, mode, subMode, user) =>
  card.skipped || (
    user.skipCorrectAnswers &&
    card.mostRecentAttempt.outcome === 'exactly_correct' &&
    !(mode === 'lessons' && subMode === 'survey')
  )

export function allValidTexts (item) {
  return _.compact(item.meanings.concat(_.map(item.definitions, 'text')))
}

const BASE_FEEDBACK = {
  pending: {
    zingers: ['Just a moment!', 'Loading…', 'Reticulating splines…', 'Hold up a minute!'],
    flair: '⏱',
    prose: (card) => `
      Our 🐢 and 🦈 are racing to figure out if this was the right answer. If
      you can read this whole sentence, your connectivity to KameSame's servers
      might be poor. If you can still read this, check your connection and
      consider reloading the page and restart your review session. You'll only
      lose the result for this most recent answer, we promise! 🙇‍♂️
    `
  },
  undone: {
    zingers: [' You undid it!', 'REDACTED', 'Nothing to see here!'],
    flair: '😶',
    prose: (card) => [`
      You hit undo, so we don't know if "${card.mostRecentAttempt.text}" was a
      right or wrong answer to "${card.text}". You'll just have to try it again!
    `]
  }
}

const PRODUCTION_FEEDBACK = _.extend({}, BASE_FEEDBACK, {
  exactly_correct: {
    zingers: ['Exactly right!', 'Nailed it!', 'Perfect!'],
    flair: '💚',
    prose: (card, answer) => [`
      Indeed, `, itemLinkView(answer), itemUtil.stringifyItemReadingLinks(card.item),
      ` does mean ${stringUtil.quoteList(card.item.meanings, 'or')}. Great job!`
    ]
  },
  reading_correct: {
    zingers: ['Close enough!', 'We\'ll count it!'],
    flair: '💛',
    prose: (card, answer) => [
      'Good! ', itemLinkView(answer), ` is a valid reading of
      "${card.text}" so we'll mark it correct, but we were looking for`,
      _.map(card.item.allTexts, (text, i) => [
        stringUtil.seriesPrefixFor(card.item.allTexts, i, 'or'),
        itemLinkView(_.extend({}, card.item, { text }))
      ])
    ]
  },
  alternate_match: {
    zingers: ['Kind of!', 'Not this time!'],
    flair: '🧡',
    prose: (card, answer) => [
      'Although ', itemLinkView(answer), ` does mean
      ${stringUtil.quoteList(allValidTexts(card.mostRecentAttempt.alternateMatch), 'or')},
      we were actually looking for `, itemLinkView(card.item, { obfuscated: !card.complete }), itemUtil.stringifyItemReadingLinks(card.item, { obfuscated: true }),
      '. ', card.complete || `This answer won't be scored as incorrect, but the review
      won't be marked complete until answered correctly.`
    ]
  },
  alternate_match_completion: {
    zingers: ['Not the one you\'d think', 'No, but you still win!', 'Sort of exactly right!'],
    flair: '💛',
    prose: (card, answer) => [
      'Truth be told, we meant for you to answer ',
      itemLinkView(card.item, { obfuscated: true }),
      itemUtil.stringifyItemReadingLinks(card.item, { obfuscated: true }),
      '. But as luck would have it, you also had a review for ',
      itemLinkView(answer),
      ` that was due to be studied, and since you just demonstrated that you
      know it, we'll go ahead and mark that one correct. Good job!`
    ]
  },
  incorrect: {
    zingers: ['Sorry!', 'Whoops!', 'Better luck next time!'],
    flair: '💔',
    prose: (card) => [`
      Sadly, 「${card.mostRecentAttempt.text}」 does not mean
      "${card.text}". We were looking for `, itemLinkView(card.item), itemUtil.stringifyItemReadingLinks(card.item), ' instead.'
    ].concat(itemUtil.wellActuallyMeaning(card))
  }
})

const RECOGNITION_FEEDBACK = _.extend({}, BASE_FEEDBACK, {
  exactly_correct: {
    zingers: ['Exactly right!', 'Nailed it!', 'Perfect!'],
    flair: '💚',
    prose: (card, answer) => [
      'That\'s right! ', itemLinkView(card.item), itemUtil.stringifyItemReadingLinks(card.item), ` does mean "${card.mostRecentAttempt.text}".`,
      card.item.meanings.length > 0 ? ` We'd have accepted ${stringUtil.quoteList(allValidTexts(card.item), 'or')}` : ''
    ]
  },
  incorrect: {
    zingers: ['Sorry!', 'Whoops!', 'Oh, well!', 'Drat!'],
    flair: '💔',
    prose: (card) => [`
      Sadly, `, itemLinkView(card.item), itemUtil.stringifyItemReadingLinks(card.item), ` does not mean
      "${card.mostRecentAttempt.text}". Instead, we were looking for ${stringUtil.quoteList(allValidTexts(card.item), 'or')}`
    ].concat(itemUtil.wellActuallyMeaning(card))
  }
})

export function feedback (card) {
  const feedbackTypes = questionType(card) === 'production' ? PRODUCTION_FEEDBACK : RECOGNITION_FEEDBACK
  const feedback = feedbackTypes[card.mostRecentAttempt.outcome]
  return {
    zinger: _.sample(feedback.zingers),
    flair: feedback.flair,
    prose: feedback.prose(card, _.extend({}, card.item,
      card.mostRecentAttempt.alternateMatch,
      { text: card.mostRecentAttempt.text }
    ))
  }
}

export function nextPage (mode, cards, props) {
  props = propUtil.mergeAndSave({
    [`${mode}Statistics`]: {
      lastActionAt: new Date()
    }
  })

  if (_.every(cards, 'complete')) {
    finish(mode, props)
  } else {
    nextCard(mode, cards, props)
  }
}

export function finish (mode, props) {
  if (document.activeElement) document.activeElement.blur()
  route.andRerender(`/app/${mode}/summary`, _.extend(props, {
    oneTimeRenderCallback () {
      if (mode === 'reviews') {
        windowUtil.scrollTo('#reviewsSummary')
      }
    }
  }))
  net.update('/api/basic_info')
}

export function nextCard (mode, cards, props) {
  route.andRerender(
    nextCardPath(mode, cards, props.user),
    props
  )
}

export const cardPath = (mode, card) =>
  `/app/${mode}/study/${card.randomUrlPath}`

export const cardResultPath = (mode, card) =>
  `${cardPath(mode, card)}/result`

export function nextCardPath (mode, cards, user) {
  const nextCard = chooseNextCard(cards, mode, user)
  return cardPath(mode, nextCard)
}

export function chooseNextCard (cards, mode, user) {
  const lessonTypesStarted = _.uniq(_.compact(_.map(cards, card =>
    card.mostRecentAttempt ? card.lessonType : null
  )))
  cards = _.reject(cards, 'complete')
  const wipLimit = mode === 'reviews' ? 20 : 6
  wipCardIds = _.intersection(wipCardIds, _.map(cards, 'id'))

  if (wipCardIds.length >= wipLimit) {
    return pickWipCard(cards)
  } else if (wipCardIds.length > 0 && cards.length > 0) {
    // If WIP limit is 20, 12 cards are WIP and 13 cards are left,
    // we don't want it to be lower probability of drawing a WIP card than
    // a new one:
    const probabilityOfWipCard = Math.max(
      wipCardIds.length / cards.length,
      wipCardIds.length / wipLimit
    )
    return Math.random() > probabilityOfWipCard
      ? pickNewCard(cards, lessonTypesStarted, user.studyOneLessonTypeAtATime)
      : pickWipCard(cards)
  } else {
    return pickWipCard(cards) || pickNewCard(cards, lessonTypesStarted, user.studyOneLessonTypeAtATime)
  }
}

export function pickWipCard (cards) {
  return _.find(cards, { id: _.sample(wipCardIds) })
}

export function pickNewCard (cards, lessonTypesStarted, studyOneLessonTypeAtATime) {
  const newCards = _.reject(cards, card =>
    _.includes(wipCardIds, card.id))
  let nextCard
  if (studyOneLessonTypeAtATime) {
    const lessonTypeOrder = _.union(lessonTypesStarted, _.shuffle(_.values(LESSON_TYPES)))
    const lessonType = _.find(lessonTypeOrder, lessonType =>
      _.some(cards, card => card.lessonType === lessonType)
    )
    nextCard = _.sample(_.filter(newCards, { lessonType })) || pickWipCard(cards)
  } else {
    nextCard = _.sample(newCards)
  }
  wipCardIds = _.union(wipCardIds, [nextCard.id])
  return nextCard
}

export const byBucketDescending = (cards) =>
  _.groupBy(_.reverse(_.sortBy(cards, 'srsStage')), card => util.bucketForSrsStage(card.srsStage))

export function abandon (e, mode, cards) {
  const wipBox = e.target.closest('.wip')
  const abandonedCards = _.mapValues(cards, (card) =>
    card.failedReview && !card.complete ? _.extend({}, card, { complete: true }) : card
  )
  wipBox.classList.add('remove')
  window.setTimeout(() => {
    render(propUtil.replaceAndSave(mode, _.pickBy(abandonedCards, 'complete')))
    css.overridePageBackgroundColor()
  }, 350) // <-- mirrors CSS animation timing
}

export function excludeItemFromLessons (mode, card, props) {
  itemUtil.exclude(card.item)

  // Exclude all lessons in the current study session
  props = propUtil.replaceAndSave(mode, _.transform(props[mode], (o, c, k) => {
    // If ALREADY complete and a different card, that lesson is LEARNED, not skipped:
    o[k] = c.item.id === card.item.id && (!c.complete || c.randomUrlPath === card.randomUrlPath)
      ? _.extend({}, c, { complete: true, skipped: true })
      : c
  }, {}))
}

export function deleteReview (card, cb) {
  if (card.learningId) {
    net.set(`/api/reviews/${card.learningId}`, {}, 'DELETE').then(() => {
      if (cb) cb()
    })
    card.learningId = null
  } else if (cb) {
    cb()
  }
}

export function undoReview (card, cb) {
  net.set('/api/reviews/undo_answer', {
    id: card.learningId
  }, 'PATCH').then(res => cb(null, res))
}

export function undoAndFailReview (card, cb) {
  net.set('/api/reviews/undo_and_fail_answer', {
    id: card.learningId
  }, 'PATCH').then(res => cb(null, res))
}

export function questionType (cardOrLessonType) {
  const lessonType = Object.hasOwnProperty.call(cardOrLessonType, 'lessonType') ? cardOrLessonType.lessonType : cardOrLessonType
  return lessonType.split('_')[1]
}

export function lessonTypeDocumentation (lessonType) {
  if (lessonType === 'production') {
    return `The production skill challenges you to provide Japanese text in
      response to an English-language prompt. By practicing production of a word,
      you'll be better able to recall it when you need it later.`
  } else if (lessonType === 'recognition') {
    return `The recognition skill challenges you to provide an English language
      meaning for a Japanese word. By practicing recognition, you'll be more
      likely to remember a word when you come across it in the future.`
  } else {
    return 'Unknown lesson type.'
  }
}

export function inputPlaceholder (card) {
  if (questionType(card) === 'production') {
    return `${card.item.type === 'kanji' ? '漢字' : '単語'}を入力して下さい`
  } else {
    return `What does this ${card.item.type === 'kanji' ? 'kanji' : 'word'} mean?`
  }
}

export function cardWasAnswered (card) {
  return (card.complete || card.failedReview) && !card.skipped
}

export function cardWasCorrect (card) {
  return card.complete && !card.failedReview
}

export function cardWasNotCorrect (card) {
  return card.failedReview || !_.includes(_.get(card, 'mostRecentAttempt.outcome'), 'correct')
}

export function isBurned (card) {
  return card.learningId && card.srsStage === 9
}

export function meaningSizeClassFor (text) {
  if (!text || text.length < 40) return

  if (text.length >= 300) {
    return 'massive'
  } else if (text.length >= 200) {
    return 'enormous'
  } else if (text.length >= 100) {
    return 'giant'
  } else if (text.length >= 75) {
    return 'huge'
  } else if (text.length >= 40) {
    return 'long'
  }
}

export function createLearningDuringStudy (mode, card, props, burn) {
  net.post('/api/learnings', {
    itemId: card.item.id,
    lessonType: card.lessonType,
    burn
  }).then(res => {
    render(propUtil.mergeAndSave({
      [mode]: {
        [card.randomUrlPath]: {
          learningId: res.learningId,
          srsStage: res.srsStage
        }
      }
    }))
  })
}

export function resolveCardActions ({ mode, subMode, card, user, props, isResult }) {
  return _.map(studyActions, (action) => {
    return _.extend({}, action,
      action.resolve({ mode, subMode, card, user, props, isResult })
    )
  })
}

import { h } from 'preact'
import * as _ from '../vendor/lodash'
import * as stringUtil from '../lib/string-util'
import * as study from '../lib/study'

export default function itemOneOffLessonView ({ item }) {
  let contents
  if (item.isExcluded) {
    // Do nothing! Just hide this section
  } else if (item.unlearnedLessonTypes.length > 0) {
    const questionTypes = _.map(item.unlearnedLessonTypes, study.questionType)
    contents = h('div', {},
      h('p', {}, `
        You haven't started practicing your ${stringUtil.quoteList(questionTypes, 'or', 'none')}
        ${stringUtil.plural(questionTypes, 'skill')} of this ${item.type} in
        KameSame yet. Want to learn it? Study it now and add it to your review queue!
      `),
      h('a', {
        class: 'one-off-item-action',
        onClick (e) { study.start(e.target, 'lessons', { ids: [item.id] }) }
      }, h('button', { tabindex: 0 },
        h('span', { class: 'emoji-inline' }, '👩‍🏫'),
        ` Learn this ${item.type}`
      ))
    )
  } else if (item.potentialLessonTypes.length > 0) {
    contents = h('p', {}, `
      There is an available lesson for this ${item.type}, but it has been
      excluded from your study settings on the `,
    h('a', { href: '/app/account' }, 'account tab'),
    '.')
  }

  return contents && h('div', {},
    h('h2', {}, `Learn this ${item.type}`),
    contents
  )
}
